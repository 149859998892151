export const periodDisplayLabels = {
  CYTD: 'CYTD',
  FYTD: 'FYTD',
  '1YR AACR': '1 Year',
  '3YR AACR': '3 Years',
  '5YR AACR': '5 Years',
  '7YR AACR': '7 Years',
  '10YR AACR': '10 Years',
  '20YR AACR': '20 Years',
  ITD: 'ITD'
};
