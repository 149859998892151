import React from 'react';
import PropTypes from 'prop-types';

import {graphqlLoadingHandler} from '../../utils'; /* eslint-disable-line import/no-cycle */

const GraphqlLoadingHandler = ({message}) => (
  <section data-testid="graphql-loading-handler">{graphqlLoadingHandler(message)}</section>
);

GraphqlLoadingHandler.propTypes = {
  message: PropTypes.string
};

export default GraphqlLoadingHandler;
