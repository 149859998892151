import {curry} from 'ramda';

const myEnv = process?.env || {};
const myGlobals = window.globals || {};

// We may want to use proxies. IE may not work so do this for now.
const getConfig = curry((env, global, prop, fallbackValue) => {
  return env[prop] ?? global[prop] ?? fallbackValue;
});

const getValueFromEnvOrGlobals = getConfig(myEnv, myGlobals);

const oidcConfig = {
  stsAuthority: getValueFromEnvOrGlobals('REACT_APP_OIDC_STSAUTHORITY', 'http://127.0.0.1:3001/'),
  clientId: getValueFromEnvOrGlobals('REACT_APP_OIDC_CLIENTID', 'auth-code-client'),
  clientRoot: getValueFromEnvOrGlobals('REACT_APP_BASE_URL', 'http://localhost:3000/'),
  clientScope: getValueFromEnvOrGlobals('REACT_APP_OIDC_SCOPE', 'openid profile email api'),
  loadUserInfo: getValueFromEnvOrGlobals('REACT_APP_OIDC_LOAD_USER_INFO', false),
  audience: getValueFromEnvOrGlobals('REACT_APP_OIDC_AUDIENCE', 'http://localhost:3000/')
};

const graphqlConfig = {
  url: getValueFromEnvOrGlobals('REACT_APP_GRAPHQL_URL', 'http://localhost:7071/graphql/')
};

export {oidcConfig, graphqlConfig};
