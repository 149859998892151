import React from 'react';

// eslint-disable-next-line import/no-cycle
import {
  formatPercent,
  formatDollar,
  formatMoc,
  formatPercentNoColorWithSign,
  formatDollarsInMillions
} from '.';
import {NA} from './errorHelpers'; /* eslint-disable-line import/no-cycle */
import {periodDisplayLabels} from './periodLabels';

export const formatSummaryTitle = (componentTitle, activePeriod, _record, handleDropDownClick) => {
  return (
    <div className="summary-title">
      <div
        tabIndex="0"
        role="button"
        onClick={() => handleDropDownClick()}
        onKeyDown={() => {}}
        className="summary-title__title"
      >
        {`${componentTitle}`}
      </div>
      <h3 className="summary-title__value">
        {`${formatDollar(_record.marketValue)} (${periodDisplayLabels[activePeriod]})`}
      </h3>
    </div>
  );
};

export const formatSummaryDollarPercent = (title, percentValue, dollarValue, sign) => {
  const _percentValue = percentValue ?? NA;
  const _dollarValue = dollarValue ?? NA;
  return (
    <div className="summary-item gains-gains-percent">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item-flex">
        <div className="summary-item__value body-medium">{formatPercent(_percentValue, sign)}</div>
        <div className="summary-item__value body-medium">{formatDollar(_dollarValue, sign)}</div>
      </div>
    </div>
  );
};

export const formatSummaryDollarPercentNAValue = (title, value) => {
  return (
    <div className="summary-item gains-gains-percent">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item-flex">
        <div className="summary-item__NA-value body-medium">{value}</div>
      </div>
    </div>
  );
};

export const formatSummaryDollar = (title, value, sign) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item__value body-medium">{formatDollar(value, sign)}</div>
    </div>
  );
};

export const formatSummaryPercent = (title, value) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item__value body-medium">{formatPercent(value)} of Portfolio</div>
    </div>
  );
};

export const formatSummaryMOC = (title, value) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item__value body-medium">{formatMoc(value)}</div>
    </div>
  );
};

export const formatSummaryIRR = (title, value, sign) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item__value body-medium">
        {formatPercentNoColorWithSign(value, sign)}
      </div>
    </div>
  );
};

export const formatSummaryTotalDistributions = (title, distributionsValue) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item-flex">
        <div className="summary-item__value body-medium">{formatDollar(distributionsValue)}</div>
      </div>
    </div>
  );
};

export const formatSummaryTotalFunded = (
  title,
  capitalPercentValue,
  totalPaidCapitalValue,
  totalCommittedCapitalValue
) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title label-medium">{title}</div>
      <div className="summary-item-flex">
        <div className="summary-item__value body-medium">
          {formatPercent(capitalPercentValue)} <span>&#8226;</span>{' '}
        </div>
        <div className="summary-item__value body-medium">
          {formatDollarsInMillions(totalPaidCapitalValue)} /
        </div>
        <div className="summary-item__value body-medium">
          {formatDollarsInMillions(totalCommittedCapitalValue)}
        </div>
      </div>
      <div className="total-funded-bar" />
    </div>
  );
};
