import React, {useContext} from 'react';
import * as R from 'ramda';
import {useHistory} from 'react-router-dom';
import {Table} from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  formatDollar,
  formatPercent,
  formatMoc,
  formatDistCCGraphXAxisAssetCard,
  abbrevPeriodLabels
} from '../../../../../utils';
import {AppContext} from '../../../../../context/AppContext';
import COLORS from '../../../../../theme/colors';
import useWindowDimensions from '../../../../../hooks/useWindowSize';
import SparklinesGraph from '../../../../../components/SparklinesGraph';
import DistCCAssetListGraph from '../../../../../components/DistCCGraph/DistCCAssetListGraph';
import {assetClassLocations} from '../assetClassLocations';
import {NA, setRenderError} from '../../../../../utils/errorHelpers';
import {formatDateWithLastDayOfMonth} from '../../../../../utils/formatter'; /* eslint-disable-line */
import logger from '../../../../../logger';
import {assetClassProperties} from '../../../../../utils/assetClassProperties';

const ClassList = ({
  listData,
  summaryTotalPortfolioData /* eslint-disable-line */,
  isFYTDClient,
  testingWidth,
  testingInception
}) => {
  const {state, dispatch} = useContext(AppContext); /* eslint-disable-line */
  const {activePeriod} = state;
  const dimensions = useWindowDimensions();
  const history = useHistory();
  logger.trace({
    method: 'assetClassList',
    listData
  });

  const activeEntity = state.selectedEntityId;
  const activePortfolio = R.find(R.propEq('entity', activeEntity))(
    state.clientInfo?.portfolios || []
  );
  const clientInceptionDate = testingInception || activePortfolio?.quickSummary?.inceptionDate;

  const displayMarketValue = true;

  const page = 'portfolioPage';
  if (R.isNil(listData)) setRenderError(dispatch, `[${page}:ClassList] No listData`);
  /**
   * Monthly Data sources
   *  marketValue:          (PageHeader) portfolioPage/performanceTotalMarketValue
   *  period change in MV:  (HeroGraph) - portfolioPage/performanceReportData
   *  allocation:           100 (all asset classes)
   *  gains:                (PageHeader) portfolioPage/performanceSummaryGains
   *  gainsPercent:         (PageHeader) portfolioPage/performanceSummaryGainsPercent
   *  ***NOTE*** following data is available in the period.summary.gainsPercent value
   *  and is displayed on the PageHeader component...
   *  and is extracted into portfolioPage/periodGainsPercentByAssetClass
   *  FYTD/CYTD, 1YR AACR, 3YR AACR, 5YR AACR
   */
  const headerAndListData = listData;
  const monthlyRow = summaryTotalPortfolioData;
  if (headerAndListData[0]?.key !== 'totalPortfolio' && monthlyRow !== undefined)
    headerAndListData.unshift(monthlyRow);

  const StyledAllocationColumn = styled.div`
    display: inline-block;
    vertical-align: middle;
    & .allocation-column_gainloss {
    }
    & .allocation-column_gainloss-text {
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    & .allocation-column_irr_moc {
      width: 120px;
      & .allocation-column_irr_moc-labels {
        display: flex;
        justify-content: space-between;
      }
      & .allocation-column_irr_moc-amount {
        display: flex;
        justify-content: space-between;
      }
      & .monthly-column {
        /* width: 36px !important; */
      }
    }
  `;

  const StyledTable = styled(Table)`
    padding-bottom: 20px;
    thead > tr > th {
      background-color: ${COLORS['@neutrals-2']};
      border: none;
      font-size: 13px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
    tbody {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
    tr.data-totalPortfolio > td,
    tr.data-totalPortfolio:hover > td {
      background-color: #dec489;
      cursor: auto;
      border: none;
    }
    tbody > tr > td:last-child {
      border: none;
    }
    tbody > tr > td {
      color: ${COLORS['@body-text']};
      border-color: ${COLORS['@neutrals-4']};
      & .sparkline-container {
        width: 120px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      },
    }
    tr: last-of-type > td {
      border: none;
    }
    & .pointer {
      cursor: pointer;
    }
  `;

  const formatAllocation = (text, record) => {
    return (
      <StyledAllocationColumn>
        <div className="body-medium-bold allocation-column_gainloss">
          <div className="label-small allocation-column_gainloss-text">Gain/Loss</div>
          <div>
            {formatPercent(record?.gainsPercent, true)} {formatDollar(record?.gains, true)}
          </div>
        </div>
      </StyledAllocationColumn>
    );
  };

  const formatAllocationForIRRMOC = (text, record) => {
    return (
      <StyledAllocationColumn>
        <div className="allocation-column_irr_moc" key={record.assetClass}>
          <div className="allocation-column_irr_moc-labels label-small">
            <div className="allocation-column_irr_moc-irr">Irr</div>
            <div className="allocation-column_irr_moc-moc">Moc</div>
          </div>
          <div className="allocation-column_irr_moc-amount">
            <div className="allocation-column_irr_moc-irr-amount body-medium-bold">
              {formatPercent(record?.irr, true)}
            </div>
            <div className="allocation-column_irr_moc-moc-amount body-medium">
              {formatMoc(record?.moc)}
            </div>
          </div>
        </div>
      </StyledAllocationColumn>
    );
  };

  const formatPeriodGainsPercents = percent => {
    if (percent === null || percent === undefined) return 'N/A';
    return <div className="percents-rendered">{formatPercent(percent)}</div>;
  };

  const formatSparkline = report => {
    if (R.isNil(report?.data) || R.isEmpty(report?.data)) return NA;
    if (report?.reportType === 'DateDistributionPair') {
      return (
        <div className="sparkline-container">
          <DistCCAssetListGraph
            reportData={formatDistCCGraphXAxisAssetCard(
              report?.data,
              activePeriod,
              clientInceptionDate,
              displayMarketValue
            )}
            displayMarketValue={displayMarketValue}
            testingWidth={testingWidth}
          />
        </div>
      );
    }
    return (
      <div className="sparkline-container">
        <SparklinesGraph reportData={report?.data} />
      </div>
    );
  };

  const columns = [
    {
      ellipsis: true,
      width: '12px',
      render(record) {
        const assetProperties = assetClassProperties(record?.key);
        const assetColor = assetProperties?.colorCode || null;
        return {
          props: {
            style: {background: assetColor},
            'data-testid': 'asset-color-background'
          },
          children: <div />
        };
      }
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      className: 'asset-class-class-name-column asset-class-link body-large-bold width-class',
      align: 'left',
      width: '15%'
    },
    {
      title: 'Market Value',
      dataIndex: 'marketValue',
      key: 'marketValue',
      align: 'left',
      className: 'body-medium',
      width: '12.5%',
      render: number => formatDollar(number)
    },
    {
      title: `${abbrevPeriodLabels(activePeriod)} Change in MV`,
      key: 'report',
      dataIndex: 'report',
      width: 'auto',
      render: report => formatSparkline(report)
    },
    {
      title: `Allocation`,
      dataIndex: 'allocation',
      key: 'allocation',
      className: 'body-medium',
      width: '9%',
      render: allocation => formatPercent(allocation)
    },
    {
      title: `${activePeriod === 'ITD' ? 'Inception to Date' : activePeriod} `,
      align: 'left',
      dataIndex: 'gainsPercent',
      key: 'gainsPercent',
      className: 'body-medium',
      width: 'auto',
      render: (text, record) =>
        record?.assetClass === 'hybrid' ||
        record?.assetClass === 'realAssets' ||
        record?.assetClass === 'privateEquities'
          ? formatAllocationForIRRMOC(text, record)
          : formatAllocation(text, record)
    },
    {
      title: `${formatDateWithLastDayOfMonth(state.selectedAsOfDate * 1000).slice(0, 3)} `,
      align: 'center',
      dataIndex: 'monthlyGainsPercent',
      className: 'body-medium monthly-column',
      width: '7.5%',
      render: monthlyGainsPercent => formatPercent(monthlyGainsPercent)
    },
    {
      title: isFYTDClient === -1 ? 'CYTD' : 'FYTD',
      align: 'center',
      dataIndex: isFYTDClient === -1 ? 'CYTD' : 'FYTD',
      key: isFYTDClient === -1 ? 'CYTD' : 'FYTD',
      className: 'body-medium',
      width: '7.5%',
      render: percent => formatPeriodGainsPercents(percent)
    },
    {
      title: '1YR AACR',
      align: 'center',
      dataIndex: 'YRAACR1',
      key: 'YRAACR1',
      className: 'body-medium',
      width: '7.5%',
      render: percent => formatPeriodGainsPercents(percent)
    },
    {
      title: '3YR AACR',
      align: 'center',
      dataIndex: 'YRAACR3',
      key: 'YRAACR3',
      className: 'body-medium',
      width: '7.5%',
      render: percent => formatPeriodGainsPercents(percent)
    },
    {
      title: '5YR AACR',
      align: 'center',
      dataIndex: 'YRAACR5',
      key: 'YRAACR5',
      className: 'body-medium',
      width: '7.5%',
      render: percent => formatPeriodGainsPercents(percent)
    },
    {ellipsis: true, width: '12px'}
  ];

  return (
    <div data-testid="asset-class-list-view" className="asset-class-list-view">
      <StyledTable
        dataSource={listData}
        columns={columns}
        onRow={record => {
          return {
            className: `data-${record?.assetClass} `,
            onClick: () => {
              if (assetClassLocations[record?.assetClass]) {
                history.push(assetClassLocations[record?.assetClass]);
              }
            }
          };
        }}
        onHeaderRow={(_columns, _index) => {
          return (
            <>
              header: {_columns} {_index}
            </>
          );
        }}
        rowClassName="pointer asset-class-row"
        pagination={false}
        scroll={dimensions.width < 1117 ? {x: 1000} : ''}
      />
    </div>
  );
};

ClassList.propTypes = {
  listData: PropTypes.array,
  isFYTDClient: PropTypes.number,
  testingWidth: PropTypes.number,
  testingInception: PropTypes.number
};

export default ClassList;
