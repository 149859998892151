import React, {useContext, useState} from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';
import {Menu, Dropdown} from 'antd';
import {CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import {useHistory} from 'react-router-dom';

import {formatDateWithLastDayOfMonth} from '../../utils/formatter';
import * as COLORS from '../../theme/colors';
import {AppContext, appActionTypes} from '../../context/AppContext';
import {client} from '../../graphql/queries/client';
import logger from '../../logger';

const StyledAsOfMenuContainer = styled.div`
  display: flex;
  width: 160px;
`;
const StyledOutsideClickHandler = styled.div`
  display: inline-block;
  width: 150px;
  marginleft: 4px;
`;
const StyledAsOfDatesContainer = styled.div`
  display: inline-block;
  padding: 0;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  color: ${COLORS['@header-text']};
  & .ant-menu,
  .ant-dropdown-menu {
    max-height: 25vh;
    overflow: auto;
    padding: 0;
    border-radius: 0;
  }
  .ant-menu-vertical,
  .ant-dropdown-menu-vertical {
    /* remove antD's little border! */
    border: 0px;
  }
  #multi-period-menu-container {
    /* align the select menu below the base item */
    margin-left: -3px !important;
    min-width: 80px !important;
  }
  & .ant-menu-title-content,
  .ant-dropdown-menu-title-content {
    color: white;
    font-weight: bold;
    background-color: ${COLORS['@primary-navy']} !important;
    padding: 2px 0;
    font-size: 13px;
    transition: color 0.05s;
  }
  & .ant-menu-title-content:hover,
  .ant-dropdown-menu-title-content:hover,
  .ant-dropdown-menu-item-active {
    color: ${COLORS['@primary-color']} !important;
  }
  & .ant-menu-item.multi-period-select__item,
  .ant-dropdown-menu-item.multi-period-select__item {
    background-color: ${COLORS['@primary-navy']} !important;
    padding: 2px;
  }
`;

const StyledAsOfDateSeparator = styled.div`
  display: inline-block;
  color: ${COLORS['@primary-color']};
  padding: 0px;
  padding-left: 14px;
  /* vertical-align: text-bottom; */
`;

const StyledAsOfText = styled.div`
  display: inline-block;
  margin-left: 4px;
  /* The following line is part of the ui-misalignment workaround.
  * Even though the asOfDate text is off-kilter in local, it should
  * display correctly on Dev/Stage/Prod. This work-around will be
  * documented in the wiki.
  */
  margin-top: 0px;
`;

/* eslint-disable-next-line */
const StyledAsOfDatesMenu = styled.div`
  display: inline-block;
  background-color: ${COLORS['@header-background']};
  color: white;
  border: 0px;
`;

export const asOfDatesElements = _asOfDatesList =>
  R.map(_asOfDate => {
    const elementText = formatDateWithLastDayOfMonth(_asOfDate * 1000);
    return (
      <Menu.Item
        id={elementText}
        style={{
          marginTop: 0,
          marginBottom: 0,
          borderRight: 0,
          maxHeight: '32px',
          verticalAlign: 'baseline'
        }}
        className="multi-period-select__item"
        key={_asOfDate}
      >
        {elementText}
      </Menu.Item>
    );
  })(_asOfDatesList);

const overlayStyle = {
  display: 'inlineBlock'
};

const AsOfDatesMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {state, dispatch} = useContext(AppContext);
  const history = useHistory();
  const {selectedAsOfDate, asOfDatesList} = state;

  const StyledMenuIcon = styled.div`
    height: 13px;
    display: inline-block;
    color: ${COLORS['@primary-color']};
    background-color: ${COLORS['@header-background']};
    margin-left: 8px;
  `;

  /* eslint-disable-next-line consistent-return */
  const HandleSelection = async event => {
    event.domEvent.preventDefault();
    const newPortfolioId = `${state.selectedEntityId}:${event.key}`;
    setIsVisible(false);
    dispatch({
      type: appActionTypes.SET_SELECTED_PORTFOLIOID,
      payload: newPortfolioId
    });
    dispatch({
      type: appActionTypes.SET_SELECTED_ASOFDATE,
      payload: event.key
    });
    await client.resetStore();
    logger.trace({
      method: 'AsOfDatesMenu:post-resetStore',
      asOfDate: state.selectedAsOfDate,
      portfolioId: state.selectedPortfolioId,
      location: history.location.pathname
    });
  };

  const openMenu = e => {
    e.preventDefault();
    setIsVisible(true);
  };

  const SelectionMenu = () => {
    return (
      <StyledAsOfDatesMenu id="multi-period-menu-container">
        <Menu
          inlineIndent="0"
          id="multi-period-select__dropdown"
          selectable
          selectedKeys={['Feb 28 2022']}
          onClick={HandleSelection}
          className="multi-period-select__dropdown"
        >
          {asOfDatesElements(asOfDatesList)}
        </Menu>
      </StyledAsOfDatesMenu>
    );
  };

  /* NOTE: we have epoch dates; formatter utilities need JavaScript dates! */
  const currentItemDate = formatDateWithLastDayOfMonth(selectedAsOfDate * 1000);
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid  */
  return (
    <StyledAsOfMenuContainer>
      <StyledAsOfDateSeparator>|</StyledAsOfDateSeparator>
      <StyledAsOfText className="body-small-bold">as&nbsp;of</StyledAsOfText>
      <StyledOutsideClickHandler
        className="outside-click-handler"
        style={{display: 'inline-block', width: '150px', marginLeft: '4px'}}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            if (isVisible) {
              setIsVisible(false);
            }
          }}
        >
          <StyledAsOfDatesContainer id="multi-period-select" className="multi-period-select">
            <Dropdown
              position="bottomLeft"
              getPopupContainer={() => document.getElementById('multi-period-select')}
              trigger={['click']}
              visible={isVisible}
              overlay={() => SelectionMenu()}
              overlayClassName="multi-period-select__dropdown"
              overlayStyle={overlayStyle}
              className="multi-period-select__popover"
            >
              <a id="multi-period-current" onClick={e => openMenu(e)}>
                {currentItemDate}
                <StyledMenuIcon className="body-small-bold">
                  {isVisible ? (
                    <CaretUpOutlined id="caret-icon" />
                  ) : (
                    <CaretDownOutlined id="caret-icon" />
                  )}
                </StyledMenuIcon>
              </a>
            </Dropdown>
          </StyledAsOfDatesContainer>
        </OutsideClickHandler>
      </StyledOutsideClickHandler>
    </StyledAsOfMenuContainer>
  );
};

export default AsOfDatesMenu;
