import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Bar,
  CartesianGrid,
  Tooltip,
  LabelList
} from 'recharts';
import {assetClassProperties} from '../../utils/assetClassProperties';
import * as COLORS from '../../theme/colors';
import {
  formatDollarsInMillions,
  formatDollarsInMillionsWithDecimal,
  formatPercent,
  formatPercentWithoutDecimal
} from '../../utils';
import AttributionToolTip from './AttributionToolTip';

const AttributionBarGraph = ({graphData, type, testingWidth}) => {
  const [graphType, setGraphType] = useState(null);
  useEffect(() => {
    const totalReturn = {
      yAxistickCount: 5
    };

    const weightedContribution = {
      yAxistickCount: 6
    };

    if (type === 'totalReturn') {
      setGraphType(totalReturn);
    } else {
      setGraphType(weightedContribution);
    }
  }, [graphData.length, type]);

  const renderPercentLabels = (props, assetClass) => {
    const {x, y, width, value, height, offset} = props;
    const sign = height >= 0 ? 2 : -2;
    if (assetClass === value.bar) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y - sign * offset}
            fill="black"
            textAnchor="middle"
            dominantBaseline="middle"
            className="body-small-bold"
          >
            {type === 'totalReturn'
              ? formatDollarsInMillionsWithDecimal(value.total)
              : formatPercent(value.total)}
          </text>
        </g>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width={testingWidth} height={350}>
      <BarChart
        height={400}
        barCategoryGap="15%"
        data={graphData}
        margin={{
          top: 30
        }}
        maxBarSize={200}
        stackOffset="sign"
      >
        <CartesianGrid vertical={false} stroke={COLORS['neutrals-4']} fill={COLORS['neutrals-4']} />
        <YAxis
          dataKey="yAxis"
          tickFormatter={tick => {
            if (type === 'totalReturn') {
              if (formatDollarsInMillions(tick) === '-$0M') {
                return '$0M';
              }
              return formatDollarsInMillions(tick);
            }
            if (formatPercentWithoutDecimal(tick) === '-0%') {
              return '$0%';
            }
            return formatPercentWithoutDecimal(tick);
          }}
          domain={['auto', 'auto']}
          axisLine={false}
          tickLine={false}
          tickCount={graphType?.yAxistickCount}
          tick={{
            stroke: COLORS['@neutrals-9'],
            fontSize: 11,
            fontStyle: 'normal',
            letterSpacing: 1
          }}
          tickMargin={10}
        />
        <XAxis
          dataKey="displayName"
          tick={{
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: '1px',
            fill: COLORS['@neutrals-9']
          }}
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          padding={{left: 10, right: 10}}
        />
        <Bar dataKey="cash" stackId="a" fill={assetClassProperties('cash')?.colorCode}>
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'cash')}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="fixedIncome"
          stackId="a"
          fill={assetClassProperties('fixedIncome')?.colorCode}
        >
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'fixedIncome')}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="balancedAccounts"
          stackId="a"
          fill={assetClassProperties('balancedAccounts')?.colorCode}
        >
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'balancedAccounts')}
            position="top"
          />
        </Bar>
        <Bar dataKey="equities" stackId="a" fill={assetClassProperties('equities')?.colorCode}>
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'equities')}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="alternative"
          stackId="a"
          fill={assetClassProperties('alternative')?.colorCode}
        >
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'alternative')}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="otherInvestments"
          stackId="a"
          fill={assetClassProperties('otherInvestments')?.colorCode}
        >
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'otherInvestments')}
            position="top"
          />
        </Bar>
        <Bar dataKey="hybrid" stackId="a" fill={assetClassProperties('hybrid')?.colorCode}>
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'hybrid')}
            position="top"
          />
        </Bar>
        <Bar dataKey="multiAsset" stackId="a" fill={assetClassProperties('multiAsset')?.colorCode}>
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'multiAsset')}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="privateEquities"
          stackId="a"
          fill={assetClassProperties('privateEquities')?.colorCode}
        >
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'privateEquities')}
            position="top"
          />
        </Bar>
        <Bar dataKey="realAssets" stackId="a" fill={assetClassProperties('realAssets')?.colorCode}>
          <LabelList
            dataKey="labelPositions"
            content={props => renderPercentLabels(props, 'realAssets')}
            position="top"
          />
        </Bar>
        <Tooltip
          cursor={false}
          content={payload => {
            return <AttributionToolTip payload={payload} type={type} />;
          }}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

AttributionBarGraph.propTypes = {
  graphData: PropTypes.array,
  testingWidth: PropTypes.number,
  type: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  offset: PropTypes.number,
  value: PropTypes.number
};

export default AttributionBarGraph;
