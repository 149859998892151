import * as R from 'ramda';
import {assetClassOrder, assetClassDisplayName} from './assetClassProperties';

const xAxisLabels = {
  CYTD: 'CYTD',
  FYTD: 'FYTD',
  ITD: 'ITD',
  '1YR AACR': '1 YR',
  '3YR AACR': '3 YR',
  '5YR AACR': '5 YR',
  '7YR AACR': '7 YR',
  '10YR AACR': '10 YR',
  '20YR AACR': '20 YR'
};

export const formatPeriodDataForAssetClassGraphs = periodsData => {
  const assetClassData = {};
  periodsData?.forEach(periodData => {
    const {code, assets, displayName} = periodData;
    assets?.forEach(asset => {
      if (asset.assetClass === 'realAssets') {
        const realAssetsSubtypes = asset.subType1;
        realAssetsSubtypes?.forEach(subtype => {
          if (subtype.displayName === 'Real Estate' || subtype.displayName === 'Hard Assets') {
            if (assetClassData[subtype.displayName]) {
              if (subtype.irr !== null) {
                const updatedAssetData = assetClassData[subtype.displayName];
                updatedAssetData.push({
                  period: code,
                  displayLabel: xAxisLabels[displayName],
                  gainsPercent: subtype.irr,
                  benchmarks: subtype.benchmarkPerformance
                });
                assetClassData[subtype.displayName] = updatedAssetData;
              }
            } else if (subtype.irr !== null) {
              assetClassData[subtype.displayName] = [
                {
                  period: code,
                  displayLabel: xAxisLabels[displayName],
                  gainsPercent: subtype.irr,
                  benchmarks: subtype.benchmarkPerformance
                }
              ];
            }
          }
        });
      } else if (assetClassData[asset.assetClass]) {
        if (
          (asset.assetClass === 'privateEquities' && asset.irr !== null) ||
          (asset.assetClass === 'hybrid' && asset.irr !== null) ||
          asset.gainsPercent !== null
        ) {
          const updatedAssetData = assetClassData[asset.assetClass];
          updatedAssetData.push({
            period: code,
            displayLabel: xAxisLabels[displayName],
            benchmarks: asset.benchmarkPerformance,
            gainsPercent:
              asset.assetClass === 'privateEquities' || asset.assetClass === 'hybrid'
                ? asset.irr
                : asset.gainsPercent
          });
          assetClassData[asset.assetClass] = updatedAssetData;
        }
      } else if (
        (asset.assetClass === 'privateEquities' && asset.irr !== null) ||
        (asset.assetClass === 'hybrid' && asset.irr !== null) ||
        asset.gainsPercent !== null
      ) {
        assetClassData[asset.assetClass] = [
          {
            period: code,
            displayLabel: xAxisLabels[displayName],
            benchmarks: asset.benchmarkPerformance,
            gainsPercent:
              asset.assetClass === 'privateEquities' || asset.assetClass === 'hybrid'
                ? asset.irr
                : asset.gainsPercent
          }
        ];
      }
    });
  });

  const assetClassDataMapped = [];
  Object.keys(assetClassData)?.forEach(key => {
    assetClassDataMapped.push({
      assetClass: key,
      assetClassDisplayName: assetClassDisplayName(key),
      graphData: assetClassData[key]
    });
  });

  const assetClassDataMappedSorted = R.sortBy(obj => {
    return R.indexOf(obj.assetClass, assetClassOrder());
  })(assetClassDataMapped || []);
  return assetClassDataMappedSorted;
};
