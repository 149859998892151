import React from 'react';

/**
 * Get and display version information here
 * @params {object} - window.globals
 */
const Version = () => {
  const myGlobals = window.globals || {};
  const versionNbr = myGlobals.REACT_APP_VERSION_NBR || 'unknown';
  const versionTag = myGlobals.REACT_APP_VERSION_TAG || 'unknown';
  return <h3>{`Version number: ${versionNbr}, tag: ${versionTag}`}</h3>;
};

export default Version;
