import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';

import {GET_CLIENT_PORTFOLIO} from '../../../graphql/queries/queries';
import BarGraph from '../../../components/BarGraph';
import {AppContext} from '../../../context/AppContext';
import {NA, setRenderError} from '../../../utils/errorHelpers';
import {setLoadingCountCoverPage} from '../../../utils/utilHelpers';

const StyledBigPicture = styled.div`
  position: relative;
  width: 100%;
  height: 471px;
  margin-bottom: 26px;
  padding: 25px;
`;

const BigPicture = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedAsOfDate, selectedClientId, selectedPortfolioId} = state;

  const {loading, error, data} = useQuery(GET_CLIENT_PORTFOLIO, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {
      id: selectedClientId,
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountCoverPage({key: 'BigPicture/index/GET_CLIENT_PORTFOLIO', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountCoverPage({key: 'BigPicture/index/GET_CLIENT_PORTFOLIO', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_CLIENT_PORTFOLIO:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }
  const page = 'coverPage';

  if (R.isNil(data?.data))
    setRenderError(dispatch, `[${page}:BigPicture] No GET_CLIENT_PORTFOLIO graphql data`);
  if (R.isNil(data.data.aacrBenchmarks)) return NA;
  const returnsData = data.data.aacrBenchmarks.returns;
  return (
    <>
      <StyledBigPicture className="big-picture component-box-shadow quickstats__big-picture">
        <h3>Average Annual Compound Return</h3>
        <BarGraph returnsData={returnsData} />
      </StyledBigPicture>
    </>
  );
};

export default BigPicture;
