import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Header from '../components/Header';
import EntityRibbon from '../components/EntityRibbon';
import {StyledHeader, StyledBody, StyledDivider} from '../styledComponents';
import {AppContext} from '../context/AppContext'; /* eslint-disable-line */
import logger from '../logger';
import {graphqlLoadingHandler} from '../utils';
import AsOfDatesMenuWithNoPeriods from '../components/AsOfDatesMenuWithNoPeriods';

const StyledAsOfDatesWithNoPeriods = styled(AsOfDatesMenuWithNoPeriods)`
  position: 'absolute';
  right: '20px';
`;

const DefaultLayoutNoPeriods = props => {
  const {state} = useContext(AppContext); /* eslint-disable-line */
  const {children} = props;
  const loaders = state.loadingCountCoverPage;
  const headerLoaders = state.loadingCountHeader;
  const location = useLocation();
  const path = location?.pathname;
  let isLoading = true;

  switch (path) {
    case '/coverPage':
      isLoading =
        Object.values(loaders).reduce((a, b) => a + b, 0) > 0 ||
        Object.values(headerLoaders).reduce((a, b) => a + b, 0) > 0;
      break;
    default:
      isLoading = Object.values(headerLoaders).reduce((a, b) => a + b, 0) > 0;
  }

  // Jest DOES display console.warn and console.error messages in a test!
  logger.trace({method: 'DefaultLayoutNoPeriods/entry', entitiesList: state.entitiesList});
  return (
    <>
      <StyledHeader>
        <div style={{position: 'relative'}}>
          <Header />
          <StyledDivider />
          <StyledAsOfDatesWithNoPeriods />
          <EntityRibbon />
          {isLoading ? (
            <div className="global-loader-outer-div" data-testid="global-loader-cover-page">
              <div className="global-loader-inner-div">
                <section>{graphqlLoadingHandler('loading')}</section>
              </div>
            </div>
          ) : null}
        </div>
      </StyledHeader>
      <StyledBody>{children}</StyledBody>
    </>
  );
};

DefaultLayoutNoPeriods.propTypes = {
  children: PropTypes.shape({})
};

export default DefaultLayoutNoPeriods;
