/* eslint-disable */
import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import './Login.css';
import {DEFAULT_PORTFOLIO, setNewClient} from '../../utils/utilHelpers';
import {AppContext} from '../../context/AppContext'; /* eslint-disable-line */
import {setRenderError} from '../../utils/errorHelpers';

/* eslint-disable-next-line */
const DefaultPeriod = ({clientData, periods, history}) => {
  const {dispatch} = useContext(AppContext);
  useEffect(() => {
    setNewClient(clientData, dispatch);
    history.replace('/coverPage');

    setRenderError(dispatch, false);
  }, [dispatch, history, clientData]);

  return <div className="loading-clients-page" />;
};

DefaultPeriod.propTypes = {
  clientData: PropTypes.shape({
    portfolios: PropTypes.array
  }),
  periods: PropTypes.array,
  history: PropTypes.shape({
    replace: PropTypes.func
  })
};

export default DefaultPeriod;
