import React from 'react';
import PropTypes from 'prop-types';

import ClassList from './ClassList';
import ClassCards from './ClassCards';
// import ClassCharts from './ClassCharts';

const AssetClasses = ({
  cardsOrList,
  listData,
  summaryTotalPortfolioData,
  cardsData,
  cardsGraphData,
  isFYTDClient,
  testingInception
  // assetClassByPeriodData
}) => {
  return (
    <>
      {cardsOrList === 'cards' ? (
        <>
          <ClassCards
            cardsData={cardsData}
            cardsGraphData={cardsGraphData}
            testingInception={testingInception}
          />
        </>
      ) : null}
      {cardsOrList === 'list' ? (
        <>
          <ClassList
            className="xyz-list"
            listData={listData}
            summaryTotalPortfolioData={summaryTotalPortfolioData}
            isFYTDClient={isFYTDClient}
            testingInception={testingInception}
          />
        </>
      ) : null}
      {/* {cardsOrList === 'charts' ? (
        <>
          <ClassCharts
            assetClassByPeriodData={assetClassByPeriodData}
            selectedPeriodAssetData={cardsData}
          />
        </>
      ) : null} */}
    </>
  );
};

AssetClasses.propTypes = {
  cardsOrList: PropTypes.oneOf(['cards', 'list']),
  listData: PropTypes.array,
  cardsData: PropTypes.array,
  cardsGraphData: PropTypes.array,
  isFYTDClient: PropTypes.number,
  testingInception: PropTypes.number
  // assetClassByPeriodData: PropTypes.array
};

export default AssetClasses;
