import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as COLORS from '../../theme/colors';
import {formatPercent, formatDollarsInMillionsWithDecimal} from '../../utils';
import {assetClassProperties} from '../../utils/assetClassProperties';

const StyledToolTip = styled.div`
  background-color: ${COLORS['@primary-navy']};
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${COLORS['@neutrals-1']};
  & .arrow-left {
    z-index: 9;
    position: fixed;
    top: 25px;
    left: 10px;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: ${COLORS['@neutrals-10']};
    border-radius: 3px;
  }
  & .arrow-right {
    z-index: 9;
    position: fixed;
    top: 25px;
    right: 10px;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: ${COLORS['@neutrals-10']};
    border-radius: 3px;
  }
  & .tooltip-body {
    position: relative;
    z-index: 10;
  }
`;

const StyledToolTipTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
`;

const ColoredToolTipSquare = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${props => assetClassProperties(props.assetClass).colorCode};
`;

const StyledToolTipData = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  font-style: normal;
  line-height: 18px;
  text-align: left;
  & .square {
    width: 30px;
  }
  & .asset-name {
    width: 97px;
  }
  & .historical-allocation-tooltip-value {
    width: 110px;
  }
`;

const StyledToolTipTotalReturnLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  & .asset-class-label {
    width: 85px;
  }
`;

const HistoricalAllocationTooltip = props => {
  const {payload} = props;

  if (payload?.active && payload?.payload[0] != null) {
    const toolTipData = R.reverse(payload?.payload) || [];
    return (
      <StyledToolTip>
        <div className="tooltip-body">
          <StyledToolTipTitle>{payload?.payload[0]?.payload?.displayName}</StyledToolTipTitle>

          <StyledToolTipTotalReturnLabels>
            <div className="asset-class-label">Asset Class</div>
            <div className="historical-allocation-value-label">Balance ($)</div>
            <div className="historical-allocation-value-label">Weight (%)</div>
          </StyledToolTipTotalReturnLabels>

          {R.map(data => {
            if (data?.value === 0) return <div key={data?.dataKey} data-testid="tooltip-novalue" />;
            return (
              <StyledToolTipData key={data?.dataKey}>
                <div className="square">
                  <ColoredToolTipSquare assetClass={data?.dataKey} />
                </div>
                <div className="asset-name">{assetClassProperties(data?.dataKey).categoryName}</div>
                <div
                  data-testid="historical-allocation-tooltip-value"
                  className="historical-allocation-tooltip-value"
                >
                  {formatDollarsInMillionsWithDecimal(data?.payload?.marketValues[data?.dataKey])}
                </div>
                <div data-testid="historical-allocation-tooltip-value">
                  {formatPercent(data?.value)}
                </div>
              </StyledToolTipData>
            );
          })(toolTipData)}
        </div>
      </StyledToolTip>
    );
  }
  return <div data-testid="tooltip-nobody" />;
};

HistoricalAllocationTooltip.propTypes = {
  payload: PropTypes.shape({
    coordinate: PropTypes.shape({}),
    position: PropTypes.shape({}),
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
  })
};
export default HistoricalAllocationTooltip;
