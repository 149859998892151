import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  formatDollar,
  formatPercent,
  formatMoc,
  formatPercentNoColorWithSign,
  formatDollarsInMillionsWithDecimal,
  formatDate
} from '../../../utils';

import {AppContext} from '../../../context/AppContext';
import COLORS from '../../../theme/colors';
import DonutGraph from '../../DonutGraph';
import {NA} from '../../../utils/errorHelpers';
import {periodDisplayLabels} from '../../../utils/periodLabels';

const StyledSummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 185px;
  padding: 0px 50px;
  max-width: 1440px;
  margin: auto;
  margin-top: 0px;
`;
const StyledTitle = styled.div`
  margin-right: 75px;
  margin-top: 45px;
`;
const StyledLongTitles = styled.div`
  margin-top: 45px;
  margin-right: 40px;
  width: 340px;
`;
const StyledMarketValue = styled.div`
  margin-right: 40px;
  margin-top: 45px;
  width: 273px;
  & .small-margin {
    margin-left: 7px;
  }
  & .title-margin {
    margin-bottom: 5px;
  }
  & .no-margin {
    margin-bottom: 0;
  }
`;
const StyledPEVCMarketValue = styled.div`
  margin-top: 45px;
  margin-right: 40px;
  width: 273px;
  max-width: 273px;
  & .small-margin {
    margin-left: 7px;
  }
  & .title-margin {
    margin-bottom: 5px;
  }
  & .no-margin {
    margin-bottom: 0;
  }
  & .pe-vc__period-margin {
    margin-left: 3px;
  }
  & .pe-vc__subtitle {
    color: ${COLORS['@neutrals-7']};
  }
`;
const StyledAllocationContainer = styled.div`
  margin-right: 75px;
  margin-top: 45px;
  margin-bottom: 45px;
  & .title-margin {
    margin-bottom: 5px;
  }
  & .no-margin {
    margin-bottom: 0;
  }
`;
const StyledAllocation = styled.div`
  display: flex;
  & .allocation-graph-margin {
    margin-right: 10px;
  }
  & .img-size {
    width: 64px;
    height: 64px;
  }
`;
const StyledPEVCAllocation = styled.div`
  margin-right: 40px;
  margin-top: 45px;
  margin-bottom: 45px;
  & .title-margin {
    margin-bottom: 5px;
  }
  & .no-margin {
    margin-bottom: 0;
  }
`;
const StyledMOC = styled.div`
  margin-right: 40px;
  margin-top: 45px;
  & .title-margin {
    margin-bottom: 5px;
  }
`;
const StyledIRR = styled.div`
  margin-right: 40px;
  margin-top: 45px;
  & .title-margin {
    margin-bottom: 5px;
  }
`;
const StyledUnfunded = styled.div`
  margin-top: 45px;
  & .title-margin {
    margin-bottom: 5px;
  }
`;

const HeaderSummary = ({summaryData}) => {
  const {state} = useContext(AppContext);
  return (
    <StyledSummaryContainer>
      {summaryData.style === 'PE/VC' ? (
        <StyledLongTitles className="page-title">{summaryData.title}</StyledLongTitles>
      ) : (
        <StyledTitle className="page-title">{summaryData.title}</StyledTitle>
      )}
      {summaryData.style !== 'PE/VC' ? (
        <StyledMarketValue>
          <div className="label-large title-margin">
            Market Value ({periodDisplayLabels[state.activePeriod]})
          </div>
          <h1 className="no-margin">{formatDollar(summaryData.marketValue)}</h1>
          <div className="body-large-bold">
            {(summaryData.gainsPercent === null || summaryData.gainsPercent === NA) &&
            summaryData.gains === 0 ? (
              <>
                <span>{NA}</span>
              </>
            ) : (
              <>
                <span>{formatPercent(summaryData.gainsPercent, true)}</span>
                <span className="small-margin">{formatDollar(summaryData.gains, true)}</span>
              </>
            )}
          </div>
        </StyledMarketValue>
      ) : (
        <StyledPEVCMarketValue>
          <div className="label-large title-margin">
            Market Value ({periodDisplayLabels[state.activePeriod]})
          </div>
          <h1 className="no-margin">{formatDollar(summaryData.marketValue)}</h1>
          <div className="body-large-bold">
            {formatPercent(summaryData.irr, true)}
            <span className="small-margin">{formatDollar(summaryData.gains, true)}</span>
            <div className="body-large pe-vc__subtitle">
              Estimated as of {formatDate(summaryData.asOfDate)}
            </div>
          </div>
        </StyledPEVCMarketValue>
      )}
      {summaryData.style !== 'PE/VC' ? (
        <StyledAllocationContainer>
          <div className="label-large title-margin">Allocation</div>
          <StyledAllocation>
            <div className="allocation-graph-margin img-size">
              <DonutGraph
                assetAllocationData={summaryData.donutGraphData}
                assetClassName={summaryData.assetClass}
                graphType="asset-page-donut-graph"
              />
            </div>
            <div>
              <h1 className="no-margin">{formatPercent(summaryData.allocation)}</h1>
              <div className="body-large">of Portfolio</div>
            </div>
          </StyledAllocation>
        </StyledAllocationContainer>
      ) : (
        <StyledPEVCAllocation>
          <div className="label-large title-margin">Allocation</div>
          <StyledAllocation>
            <div className="allocation-graph-margin img-size">
              <DonutGraph
                assetAllocationData={summaryData.donutGraphData}
                assetClassName={summaryData.assetClass}
                graphType="asset-page-donut-graph"
              />
            </div>
            <div>
              <h1 className="no-margin">{formatPercent(summaryData.allocation)}</h1>
              <div className="body-large">of Portfolio</div>
            </div>
          </StyledAllocation>
        </StyledPEVCAllocation>
      )}
      {summaryData.style === 'PE/VC' ? (
        <>
          <StyledMOC>
            <div className="label-large title-margin">MOC</div>
            <h1>{formatMoc(summaryData.moc)}</h1>
          </StyledMOC>
          <StyledIRR>
            <div className="label-large title-margin">
              IRR ({periodDisplayLabels[state.activePeriod]})
            </div>
            <h1>{formatPercentNoColorWithSign(summaryData.irr)}</h1>
          </StyledIRR>
          <StyledUnfunded>
            <div className="label-large title-margin">Total Unfunded</div>
            <h1>{formatDollarsInMillionsWithDecimal(summaryData.totalFundingUnfunded)}</h1>
          </StyledUnfunded>
        </>
      ) : null}
    </StyledSummaryContainer>
  );
};

HeaderSummary.propTypes = {
  summaryData: PropTypes.shape({
    title: PropTypes.string,
    marketValue: PropTypes.number,
    gainsPercent: PropTypes.number,
    gains: PropTypes.number,
    allocation: PropTypes.number,
    moc: PropTypes.number,
    irr: PropTypes.number,
    totalFundingUnfunded: PropTypes.number,
    asOfDate: PropTypes.number,
    assetClass: PropTypes.string,
    donutGraphData: PropTypes.array,
    style: PropTypes.string
  })
};

export default HeaderSummary;
