import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {Redirect} from 'react-router-dom';

import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';
import HeroGraph from './heroGraph';
import HeaderSummary from '../../components/assetClassDetails/HeaderSummary';
import SubType1PEVC from '../../components/subType1PEVC';
import {NA} from '../../utils/errorHelpers';
import ErrorHandler from '../../components/ErrorHandler';
import {setLoadingCountAssetDetailsPage} from '../../utils/utilHelpers';

const StyledPrivateEquitiesContainer = styled.div`
  & .row-item__value {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .row-title__value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
`;

const PrivateEquitiesPage = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedPortfolioId, selectedAsOfDate} = state;
  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountAssetDetailsPage({key: '/privateequitiesPage', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountAssetDetailsPage({key: '/privateequitiesPage', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log('QUERY ERROR: ', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'PEVCPage';

  if (R.isNil(data?.data)) return <ErrorHandler loggedMessage={`[${page}]: No graphQL data`} />;
  if (R.isNil(data?.data?.periods))
    return <ErrorHandler loggedMessage={`[${page}]: No periods data`} />;

  const periodsData = data.data?.periods || [];

  const findPeriod = R.findIndex(R.propEq('displayName', state.activePeriod))(periodsData);
  if (findPeriod === -1)
    return (
      <ErrorHandler
        loggedMessage={`[${page}]: No data for selected period: ${state.activePeriod}`}
      />
    );

  const assetClassData = periodsData[findPeriod];
  if (R.isNil(assetClassData.assets) || R.isEmpty(assetClassData.assets))
    return (
      <ErrorHandler loggedMessage={`[${page}]: No assets data: period ${state.activePeriod}`} />
    );

  const privateEquitiesData = assetClassData.assets.filter(
    asset => asset.assetClass === 'privateEquities'
  );
  if (R.isNil(privateEquitiesData) || R.isEmpty(privateEquitiesData)) {
    // Must be an entity switch where the new entity has no alternatives data
    // Force our way back to the portfolio page
    return <Redirect to="/portfolioPage" />;
  }

  const privateEquitiesAccountData = R.isEmpty(privateEquitiesData) ? {} : privateEquitiesData[0];

  const summaryData = {
    assetClass: privateEquitiesAccountData.assetClass || NA,
    title: 'Private Equity / Venture Capital',
    marketValue: privateEquitiesAccountData.marketValue ?? NA,
    gains: privateEquitiesAccountData.gains ?? NA,
    gainsPercent: privateEquitiesAccountData.gainsPercent ?? NA,
    allocation: privateEquitiesAccountData.allocation ?? NA,
    moc: privateEquitiesAccountData.moc ?? NA,
    irr: privateEquitiesAccountData.irr ?? NA,
    totalFundingUnfunded: privateEquitiesAccountData.totalFundingUnfunded ?? NA,
    asOfDate: privateEquitiesAccountData.asOfDate || NA,
    donutGraphData: assetClassData.assets,
    style: 'PE/VC'
  };

  const subType1s = privateEquitiesAccountData?.subType1 || [];

  return (
    <StyledPrivateEquitiesContainer>
      <div className="portfolio-box-shadow">
        <HeaderSummary summaryData={summaryData} />
        {privateEquitiesAccountData.report !== null ? (
          <HeroGraph
            reportData={privateEquitiesAccountData.report}
            asOfDate={privateEquitiesAccountData.asOfDate}
          />
        ) : null}
      </div>
      <div className="side-padding-50">
        {subType1s.map(subType1PEVC => {
          return (
            <SubType1PEVC data={subType1PEVC} key={subType1PEVC.displayName?.replace(' ', '-')} />
          );
        })}
      </div>
    </StyledPrivateEquitiesContainer>
  );
};

export default PrivateEquitiesPage;
