import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {usePersistedReducer} from '../hooks/usePersistedReducer';
import {initialState} from './referenceConstants'; /* eslint-disable-line import/no-cycle */
import logger from '../logger';

export const AppContext = React.createContext({});
AppContext.displayName = 'WinterAppContext';

export const appActionTypes = {
  SET_ACTIVE_PERIOD: 'SET_ACTIVE_PERIOD',
  SET_CLIENT_ENTITIES: 'SET_CLIENT_ENTITIES',
  SET_CLIENT_INFO: 'SET_CLIENT_INFO',
  RESET_CLIENT_INFO: 'RESET_CLIENT_INFO',
  RESET_STATE: 'RESET_STATE',
  SET_CARDS_OR_LIST: 'SET_CARDS_OR_LIST', // Expected values are 'cards' or 'list'
  SET_RENDER_ERROR: 'SET_RENDER_ERROR',
  SET_INCEPTION_DATE: 'SET_INCEPTION_DATE',
  SET_SELECTED_CLIENTID: 'SET_SELECTED_CLIENTID',
  SET_SELECTED_ASOFDATE: 'SET_SELECTED_ASOFDATE',
  SET_SELECTED_PORTFOLIOID: 'SET_SELECTED_PORTFOLIOID',
  SET_SELECTED_PORTFOLIOINDEX: 'SET_SELECTED_PORTFOLIOINDEX',
  SET_SELECTED_ENTITYID: 'SET_SELECTED_ENTITYID',
  SET_NEW_ENTITY: 'SET_NEW_ENTITY',
  SET_NEW_CLIENT: 'SET_NEW_CLIENT',
  SET_ASOFDATES_LIST: 'SET_ASOFDATES_LIST',
  SET_LOADING_COUNT_HEADER: 'SET_LOADING_COUNT_HEADER',
  SET_LOADING_COUNT_COVER_PAGE: 'SET_LOADING_COUNT_COVER_PAGE',
  SET_LOADING_COUNT_PORTFOLIO_PAGE: 'SET_LOADING_COUNT_PORTFOLIO_PAGE',
  SET_LOADING_COUNT_ASSET_DETAILS_PAGE: 'SET_LOADING_COUNT_ASSET_DETAILS_PAGE',
  SET_REFRESH_DATE: 'SET_REFRESH_DATE',
  SET_REFRESH_TIME: 'SET_REFRESH_TIME',
  SET_REFRESH_ZONE: 'SET_REFRESH_ZONE'
};

export const AppProvider = ({children}) => {
  const generateNewAsOfDatesList = data =>
    R.ifElse(
      () => R.is(Object, data[0]),
      R.always(R.pluck('asOfDate', data)),
      R.always(data)
    )(data);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_PERIOD':
        return {
          ...state,
          activePeriod: action.payload
        };
      case 'SET_CLIENT_ENTITIES':
        logger.trace({
          method: 'SET_CLIENT_ENTITIES',
          entitiesList: action.payload
        });
        return {
          ...state,
          entitiesList: action.payload,
          clientInfo: {
            ...state.clientInfo
            // deprecated
            // entitiesList: action.payload
          }
        };
      case 'SET_CLIENT_INFO':
        logger.trace({
          method: 'SET_CLIENT_INFO',
          payload: action.payload
        });
        return {
          ...state,
          clientInfo: action.payload
        };
      case 'RESET_CLIENT_INFO':
        return {
          ...state,
          clientInfo: initialState.clientInfoInitialState
        };
      case 'SET_CARDS_OR_LIST':
        return {
          ...state,
          cardsOrList: action.payload
        };
      case 'SET_RENDER_ERROR':
        return {
          ...state,
          renderError: action.payload
        };
      case 'RESET_STATE':
        return {
          cardsOrList: 'cards',
          activePeriod: 'CYTD',
          clientInfo: initialState.clientInfoInitialState,
          renderError: false
        };
      case 'SET_INCEPTION_DATE':
        return {
          ...state,
          // Prepare for new location of 'selected' values
          inceptionDate: action.payload,
          clientInfo: {
            ...state.clientInfo,
            quickSummary: {
              ...state.clientInfo.clientSummary,
              // deprecated
              inceptionDate: action.payload
            }
          }
        };
      case 'SET_SELECTED_CLIENTID':
        return {
          ...state,
          // Prepare for new location of 'selected' values
          selectedClientId: action.payload,
          clientInfo: {
            ...state.clientInfo,
            // deprecated
            selectedClientId: action.payload
          }
        };
      case 'SET_SELECTED_ASOFDATE':
        return {
          ...state,
          // Prepare for new location of 'selected' values
          selectedAsOfDate: action.payload,
          clientInfo: {
            ...state.clientInfo,
            // deprecated
            selectedAsOfDate: action.payload
          }
        };
      case 'SET_SELECTED_PORTFOLIOID':
        if (typeof action.payload !== 'string') {
          logger.trace({
            method: 'SET_SELECTED_PORTFOLIOID',
            error: 'invalid set portfolioId',
            payload: action.payload
          });
        }
        return {
          ...state,
          // Prepare for new location of 'selected' values
          selectedPortfolioId: action.payload,
          clientInfo: {
            ...state.clientInfo,
            // deprecated
            selectedPortfolioId: action.payload
          }
        };
      case 'SET_SELECTED_PORTFOLIOINDEX':
        return {
          ...state,
          selectedPortfolioIndex: action.payload
        };
      case 'SET_NEW_CLIENT':
        logger.trace({method: 'SET_NEW_CLIENT/payload', payload: action.payload});
        /* eslint-disable-next-line no-case-declarations */
        const newClientState = {
          ...state,
          entitiesList: action.payload.clientEntities,
          asOfDatesList: generateNewAsOfDatesList(action.payload.asOfDatesList),
          activePeriod: action.payload.activePeriod,
          cardsOrList: action.payload.cardsOrList,
          selectedPortfolioId: action.payload.portfolioId,
          selectedClientId: action.payload.clientId,
          selectedEntityId: action.payload.entityId
        };
        logger.trace({method: 'SET_NEW_CLIENT/newClientState', newClientState});
        return newClientState;
      case 'SET_NEW_ENTITY':
        logger.trace({method: 'SET_NEW_ENTITY/payload', payload: action.payload});
        /* eslint-disable-next-line no-case-declarations */
        const newEntityState = {
          ...state,
          asOfDatesList: generateNewAsOfDatesList(action.payload.asOfDatesList),
          selectedEntityId: action.payload.entity,
          selectedPortfolioId: action.payload.portfolioId,
          selectedPortfolioIndex: action.payload.portfolioIndex,
          selectedAsOfDate: action.payload.asOfDate
        };
        logger.trace({method: 'SET_NEW_ENTITY/newEntityState', newEntityState});
        return newEntityState;
      case 'SET_SELECTED_ENTITYID':
        return {
          ...state,
          selectedEntityId: action.payload
        };
      case 'SET_ASOFDATES_LIST':
        // Normalize [n,n,n,n] or [{},{},{},{}] => [n,n,n,n]
        /* eslint-disable-next-line no-case-declarations */
        const newPayload = generateNewAsOfDatesList(action.payload);
        // Set asOfDatesList as a sibling to portfolios
        logger.trace({
          method: 'SET_ASOFDATES_LIST',
          oldPayload: action.payload,
          newPayload
        });
        return {
          ...state,
          asOfDatesList: newPayload
        };
      case 'SET_LOADING_COUNT_HEADER': {
        logger.trace({
          method: 'SET_LOADING_COUNT_HEADER',
          payload: action.payload
        });
        const newState = {...state};
        newState.loadingCountHeader[action.payload.key] = action.payload.value;
        return {
          ...newState
        };
      }
      case 'SET_LOADING_COUNT_COVER_PAGE': {
        logger.trace({
          method: 'SET_LOADING_COUNT_COVER_PAGE',
          payload: action.payload
        });
        const newState = {...state};
        newState.loadingCountCoverPage[action.payload.key] = action.payload.value;
        return {
          ...newState
        };
      }
      case 'SET_LOADING_COUNT_PORTFOLIO_PAGE': {
        logger.trace({
          method: 'SET_LOADING_COUNT_PORTFOLIO_PAGE',
          payload: action.payload
        });
        const newState = {...state};
        newState.loadingCountPortfolioPage[action.payload.key] = action.payload.value;
        return {
          ...newState
        };
      }
      case 'SET_LOADING_COUNT_ASSET_DETAILS_PAGE': {
        logger.trace({
          method: 'SET_LOADING_COUNT_ASSET_DETAILS_PAGE',
          payload: action.payload
        });
        const newState = {...state};
        newState.loadingCountAssetDetailsPage[action.payload.key] = action.payload.value;
        return {
          ...newState
        };
      }
      case 'SET_REFRESH_DATE':
        return {
          ...state,
          refreshDate: action.payload
        };
      case 'SET_REFRESH_TIME':
        return {
          ...state,
          refreshTime: action.payload
        };
      case 'SET_REFRESH_ZONE':
        return {
          ...state,
          refreshZone: action.payload
        };
      default:
        /* eslint-disable-next-line no-console */
        console.log('Invalid action.type', action.type);
        return state;
    }
  };

  const [state, dispatch] = usePersistedReducer(reducer, 'winterAppState', initialState);
  const value = {state, dispatch};
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.shape({})
};

export default AppContext;
