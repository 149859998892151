import React from 'react';
// import * as R from 'ramda';
import PropTypes from 'prop-types';
// import styled from '@emotion/styled';
import {Bar, Line, ComposedChart, ResponsiveContainer} from 'recharts';
import * as COLORS from '../../../theme/colors';

/**
 * @arg: reportData: {array}
 * @arg: testingWidth: {integer} - sets REsponsiveContainer width; see
 * https://stackoverflow.com/questions/60215153/when-mounted-in-enzyme-why-does-a-recharts-chart-with-a-responsivecontainer-not
 */

const DistCCAssetListGraph = ({reportData, displayMarketValue, testingWidth}) => {
  return (
    <ResponsiveContainer width={testingWidth} height={75}>
      <ComposedChart width={140} height={75} data={reportData} stackOffset="sign" maxBarSize={15}>
        <Line
          name="YAxis"
          dataKey={() => {
            return 0;
          }}
          stroke={COLORS['@neutrals-4']}
          fill={COLORS['@neutrals-4']}
          strokeWidth={1}
          isAnimationActive={false}
          dot={false}
          activeDot={false}
          yAxisId="left"
        />
        <Bar
          name="Distributions"
          dataKey="distribution"
          fill={COLORS['@secondary-blue']}
          stackId="stack"
          yAxisId="left"
        />
        <Bar
          name="Capital Calls"
          dataKey="capitalCalls"
          fill={COLORS['@category-blue']}
          stackId="stack"
          yAxisId="left"
        />
        <Line
          name="Net Flows"
          dataKey="netFlows"
          strokeWidth={0}
          isAnimationActive={false}
          dot={{stroke: COLORS['@neutrals-9'], strokeWidth: 1, fill: COLORS['@neutrals-9'], r: 1.5}}
          activeDot={false}
          yAxisId="left"
        />
        {displayMarketValue ? (
          <Line
            name="Market Value"
            dataKey="marketValue"
            dot={false}
            stroke={COLORS['@primary-color']}
            strokeWidth={2}
            activeDot={false}
            yAxisId="right"
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

DistCCAssetListGraph.propTypes = {
  reportData: PropTypes.array,
  testingWidth: PropTypes.number,
  displayMarketValue: PropTypes.bool
};

export default DistCCAssetListGraph;
