/* eslint-disable no-console */
import {useEffect, useReducer} from 'react';
import logger from '../logger';

export function usePersistedReducer(reducer, key, initialState) {
  const currentKeyValue = JSON.parse(localStorage.getItem(key));
  logger.trace({
    method: 'usePersistedReducer',
    key,
    currentKeyValue,
    initialState
  });

  // If current appStorage is empty, we set version = 0 so we are forced to reload with initialState
  const currentKeyVersion = !currentKeyValue ? 0 : currentKeyValue.clientInfo.version.split('.')[1];
  const initialStateVersion = initialState.clientInfo.version.split('.')[1];

  // Get the initial state from localStorage if it exists and is a current version
  // Else, use the initialState passed in
  const init =
    !currentKeyValue || initialStateVersion > currentKeyVersion ? initialState : currentKeyValue;

  // localStorage.setItem(key, JSON.stringify(init)); // This might not be needed?
  const [state, dispatch] = useReducer(reducer, init);
  logger.trace({method: 'usePersistedReducer/state', state});

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, dispatch];
}
