/* eslint-disable no-console */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {Menu, Dropdown} from 'antd';
import {useHistory} from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import * as COLORS from '../theme/colors';
import {GET_MY_CLIENTS} from '../graphql/queries';
import {GraphqlLoadingHandler} from './graphqlHandlers';
import {AppContext} from '../context/AppContext';
import {setNewClient, isAllowableMgetErrors} from '../utils/utilHelpers';
import logger from '../logger'; /* eslint-disable-line */

const StyledClientSelectionContainer = styled.div`
  padding: 0;
  text-align: left;
  & .ant-popover-inner-content {
    padding: 0 !important;
    color: ${COLORS['@primary-navy']};
  }
  & .ant-dropdown {
    top: 37px !important;
  }
  & .ant-menu,
  .ant-dropdown-menu {
    max-height: 75vh;
    overflow: auto;
    border-radius: 0px;
  }
  & .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    padding: 8px 12px;
  }
  & .ant.downdown-menu-title-content {
    padding: 2px 0;
  }
`;

/* eslint-disable-next-line */
const StyledSelectionMenu = styled.div`
  background-color: ${COLORS['@primary-navy']};
  color: white;
  border: 0;
`;

export const selectionElements = _clientList =>
  R.map(_client => {
    return (
      <Menu.Item id={_client.displayName} key={_client.displayName}>
        {_client.displayName}
      </Menu.Item>
    );
  })(_clientList);

const ClientSelectionMenu = ({isVisible, setNotVisible}) => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedAsOfDate, selectedClientId} = state.clientInfo;
  const history = useHistory();
  logger.error({method: 'ClientSelectionMenu: selectedClientId', selectedClientId});

  const {client, loading, error, data} = useQuery(GET_MY_CLIENTS, {
    returnPartialData: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {id: selectedClientId, asOfDate: selectedAsOfDate}
  });

  if (loading) {
    return <GraphqlLoadingHandler />;
  }

  // NOTE for ticket 3412
  if (error && !isAllowableMgetErrors(error.message))
    /* eslint-disable-next-line no-console */
    console.error('[ClientSelectionMenu] GET_MY_CLIENTS QUERY ERROR:', error.message);

  const clientList = data?.data;

  /* eslint-disable-next-line */
  // NOTE: OK to use portfolios[0] here as it's for GET_MY_CLIENTS call
  // Ensure that we have valid data to work with; if not, reject the client entirely
  // TODO: Create rejectAndLog function to:
  // 1. reject an item for a condition
  // 2. log the fact that this was rejected
  // 3. Email to CDE_Email with details
  const selectionList = R.compose(
    R.append({dataLogout: 'logout', id: 'Logout', displayName: 'Logout'}),
    R.reject(item => R.isNil(item.portfolios[0].performanceData)), // Bug #3636
    R.reject(item => R.isNil(item.portfolios[0].quickSummary)), // Bug #3636
    R.reject(item => R.isNil(item.portfolios[0])), // bug #3636
    R.reject(item => R.isNil(item.portfolios)), // Bug #3636
    R.reject(item => R.isNil(item))
  )(clientList || []);

  const handleSelection = async event => {
    const clientData = R.find(myClient => myClient.displayName === event.key)(selectionList);
    switch (clientData.id) {
      case 'Logout':
        history.push('/logout');
        break;
      default: {
        setNewClient(clientData, dispatch);
        await client.resetStore();
        history.push('/coverPage');
      }
    }
  };

  const SelectionMenu = () => {
    return (
      <StyledSelectionMenu>
        <Menu selectable onClick={handleSelection}>
          {selectionElements(selectionList)}
        </Menu>
      </StyledSelectionMenu>
    );
  };

  const overlayStyle = {
    display: 'block',
    color: `${COLORS['@primary-color']}`,
    backgroundColor: 'white',
    border: `10px ${COLORS['@primary-navy']}`
  };

  return (
    <div className="outside-click-handler">
      <OutsideClickHandler
        onOutsideClick={() => {
          if (isVisible) {
            setNotVisible(false);
          }
        }}
      >
        <StyledClientSelectionContainer id="client-select" className="client-select">
          <Dropdown
            getPopupContainer={() => document.getElementById('client-select')}
            placement="bottom"
            trigger={['click']}
            visible={isVisible}
            overlay={SelectionMenu}
            overlayClassName="client-select__dropdown"
            overlayStyle={overlayStyle}
            arrowPointAtBottom
            className="client-select__popover"
          >
            <div />
          </Dropdown>
        </StyledClientSelectionContainer>
      </OutsideClickHandler>
    </div>
  );
};

ClientSelectionMenu.propTypes = {
  isVisible: PropTypes.bool,
  setNotVisible: PropTypes.func
};
export default ClientSelectionMenu;
