import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as R from 'ramda';
import * as COLORS from '../../../theme/colors';
import {formatDollar, assetClassDisplayName} from '../../../utils';

const StyledAssetCategory = styled.div`
  margin-left: 35px;
  width: 315px;
`;

const StyledAssetTotalsTitles = styled.div`
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 7px;
`;

const StyledAsset = styled.div`
  display: flex;
  margin-bottom: 7px;
  justify-content: space-between;
  width: 100%;
  & .asset-class-name {
    color: ${COLORS['@neutrals-7']};
  }
  & .total {
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

const classifyPhrase = phrase => {
  return phrase.toLowerCase().replace(/ {2}/g, ' ').replace(' ', '-');
};
const ClassByCategory = ({assets, title}) => {
  const total = R.compose(
    R.sum,
    R.map(asset => asset.marketValue)
  )(assets);

  if (R.isEmpty(assets)) return null;

  const classNameBase = classifyPhrase(`${title} assets`);
  return (
    <StyledAssetCategory>
      <StyledAssetTotalsTitles>
        <div className={classNameBase}>{title} Assets</div>
      </StyledAssetTotalsTitles>
      {assets.map(asset => (
        <StyledAsset key={asset.assetClass}>
          <div className="body-medium asset-class-name">
            {asset.assetClass === 'privateEquities'
              ? 'PE/VC'
              : assetClassDisplayName(asset.assetClass)}
          </div>
          <div className="body-medium">{formatDollar(asset.marketValue)}</div>
        </StyledAsset>
      ))}
      <StyledAsset>
        <div className={`total-${classNameBase} total asset-class-name`}>Total {title}</div>
        <div className="body-medium-bold">{formatDollar(total)}</div>
      </StyledAsset>
    </StyledAssetCategory>
  );
};

ClassByCategory.propTypes = {
  assets: PropTypes.array,
  title: PropTypes.string
};

export default ClassByCategory;
