import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CheckedBox from './CheckedBox';

const StyledGraphHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;
`;

const DistCCHeroGraphHeader = ({displayMarketValue, setDisplayMarketValue}) => {
  return (
    <StyledGraphHeader>
      <div className="label-large">Distributions and Capital Calls</div>
      <CheckedBox
        displayMarketValue={displayMarketValue}
        setDisplayMarketValue={setDisplayMarketValue}
        label="Show Market Value"
      />
    </StyledGraphHeader>
  );
};

DistCCHeroGraphHeader.propTypes = {
  displayMarketValue: PropTypes.bool,
  setDisplayMarketValue: PropTypes.func
};

export default DistCCHeroGraphHeader;
