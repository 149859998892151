import React from 'react';
import PropTypes from 'prop-types';

import {Text} from 'recharts';
import * as COLORS from '../../../theme/colors';

const CustomizedAxisTick = props => {
  const {numOfPoints, type, period, x, y, payload, itdQuarterly} = props;
  const tick = payload.value;
  const angledPeriods = ['CYTD', 'FYTD', '1YR AACR', 'ITD'];
  if (tick.toString().split(' ').length > 1) {
    return (
      <g>
        <Text
          x={x}
          y={y}
          fill={COLORS['@neutrals-7']}
          textAnchor="middle"
          verticalAnchor="start"
          className="label-small"
          angle={
            numOfPoints > 9 &&
            type === 'assetCard-graph' &&
            angledPeriods.includes(period) &&
            !itdQuarterly
              ? -30
              : 0
          }
        >
          {`${tick.split(' ')[0]}${tick.split(' ')[1].includes('*') ? '* ' : ''}`}
        </Text>
        <Text
          x={x}
          y={y + 20}
          fill={COLORS['@neutrals-7']}
          textAnchor="middle"
          verticalAnchor="start"
          className="label-small"
        >
          {tick.split(' ')[1].includes('*')
            ? tick.slice(tick.split(' ')[0].length, -1)
            : tick.slice(tick.split(' ')[0].length)}
        </Text>
      </g>
    );
  }
  return (
    <Text
      x={x}
      y={y}
      fill={COLORS['@neutrals-7']}
      textAnchor="middle"
      verticalAnchor="start"
      className="label-small"
      angle={
        numOfPoints > 9 &&
        type === 'assetCard-graph' &&
        angledPeriods.includes(period) &&
        !itdQuarterly
          ? -30
          : 0
      }
    >
      {tick}
    </Text>
  );
};

CustomizedAxisTick.propTypes = {
  y: PropTypes.number,
  x: PropTypes.number,
  numOfPoints: PropTypes.number,
  type: PropTypes.string,
  period: PropTypes.string,
  itdQuarterly: PropTypes.bool,
  payload: PropTypes.shape({
    value: PropTypes.string
  })
};

export default CustomizedAxisTick;
