import {gql} from '@apollo/client';

/**
 * @function: GetAssetAllocationPortfolio / GET_ASSET_ALLOCATION_PORTFOLIO
 * @arg {string} client Id
 * @returns {JSON} Asset allocation data
 */

export const GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO = gql`
  query GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO($id: ID!, $portfolioId: ID!, $asOfDate: ID!) {
    data: GetClientPortfolio(id: $id, portfolioId: $portfolioId, asOfDate: $asOfDate) {
      id
      displayName
      historicAllocation {
        data {
          date
          assets {
            assetClass
            percent
            marketValue
          }
        }
      }
    }
  }
`;
