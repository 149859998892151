import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import DonutGraph from '../DonutGraph';

import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';

import './AssetAllocation.css';
import {setRenderError} from '../../utils/errorHelpers';
import {setLoadingCountCoverPage} from '../../utils/utilHelpers';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  & .asset-allocation_title {
    margin-top: 23px;
    margin-left: 23px;
  }
`;

const AssetAllocation = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedAsOfDate, selectedPortfolioId} = state;

  const RenderAssetAllocation = ({children}) => {
    return (
      <StyledContainer
        data-testid="asset-class-cover-piechart"
        className="asset-allocation quickstats__allocation asset-class-cover-piechart"
      >
        <h3 className="asset-allocation_title">Asset Allocation</h3>
        <div>{children}</div>
      </StyledContainer>
    );
  };
  RenderAssetAllocation.propTypes = {
    children: PropTypes.node
  };

  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountCoverPage(
        {key: 'AssetAllocation/index/GET_PORTFOLIO_PERFORMANCE_ASSET', value: 1},
        dispatch
      );
    } else if (!loading) {
      setLoadingCountCoverPage(
        {key: 'AssetAllocation/index/GET_PORTFOLIO_PERFORMANCE_ASSET', value: 0},
        dispatch
      );
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_PORTFOLIO_PERFORMANCE_ASSET QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'coverPage';

  if (R.isNil(data?.data))
    setRenderError(
      dispatch,
      `[${page}:AssetAllocation] No graphQL data: GET_PORTFOLIO_PERFORMANCE_ASSET`
    );
  if (R.isNil(data?.data?.periods || R.isEmpty(data?.data?.periods[0])))
    setRenderError(dispatch, `[${page}:AssetAllocation] No periods data`);

  const periodsData = data.data?.periods;
  if (R.isNil(periodsData[0] || R.isNil(periodsData[0]?.assets)))
    setRenderError(dispatch, `[${page}:AssetAllocation] No asset allocation data`);

  const assetAllocationData = R.path(['assets'], periodsData[0]);
  /* eslint-disable */
  const marketValueData = R.compose(
    R.mergeAll,
    R.map(asset => {
      return {[asset.assetClass]: {marketValue: asset.marketValue, allocation: asset.allocation}};
    })
  )(assetAllocationData);
  return (
    <RenderAssetAllocation>
      <DonutGraph
        marketValueData={marketValueData}
        assetAllocationData={assetAllocationData}
        graphType="cover-page-donut-graph"
      />
    </RenderAssetAllocation>
  );
};

export default AssetAllocation;
