/* eslint-disable no-console */

import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import {useQuery} from '@apollo/client';

import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import AsOfDatesMenu from '../AsOfDatesMenu';
import Period from '../Period';
import {AppContext, appActionTypes} from '../../context/AppContext';
import {StyledPeriodsContainer, StyledAsOfDatesMenuContainer} from '../../styledComponents';
import logger from '../../logger'; /* eslint-disable-line */

const Periods = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedAsOfDate, selectedEntityId, selectedClientId, selectedPortfolioId} = state;
  const periodsOrder = [
    'CYTD',
    'FYTD',
    '1YR AACR',
    '3YR AACR',
    '5YR AACR',
    '7YR AACR',
    '10YR AACR',
    '20YR AACR',
    'ITD'
  ];
  const periodsPlaceholder = [{displayName: 'Loading...', displayLabel: 'Loading...'}];

  const renderPeriods = orderedPeriodsData => {
    return (
      <StyledPeriodsContainer className="periods-container">
        {R.map(period => (
          <Period
            className="periods-container__item"
            key={period.displayName}
            displayName={period.displayName}
            text={period.displayLabel}
          />
        ))(orderedPeriodsData)}
        <StyledAsOfDatesMenuContainer>
          <AsOfDatesMenu />
        </StyledAsOfDatesMenuContainer>
      </StyledPeriodsContainer>
    );
  };

  logger.debug({
    method: 'Periods',
    state,
    selectedPortfolioId,
    selectedClientId,
    selectedAsOfDate,
    selectedEntityId
  });
  useEffect(() => {
    const portfolioAsOfDate = parseInt(selectedPortfolioId.split(':')[1], 10);
    if (selectedAsOfDate !== portfolioAsOfDate) {
      dispatch({
        // Ensure we match the asOfDate with the selectedPortfolioId (primary)
        type: appActionTypes.SET_SELECTED_ASOFDATE,
        payload: portfolioAsOfDate
      });
    }
  }, [selectedAsOfDate, selectedPortfolioId, dispatch]);

  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });
  if (loading) {
    return renderPeriods(periodsPlaceholder);
  }

  if (error) {
    console.error('GET_PORTFOLIO_PERFORMANCE_ASSET:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }
  if (R.isNil(data) || R.isNil(data.data)) {
    console.error('GET_PORTFOLIO_PERFORMANCE_ASSET:', 'No data returned');
  }
  const periodsData = data?.data?.periods;
  if (R.isNil(periodsData)) {
    console.error('GET_PORTFOLIO_PERFORMANCE_ASSET:', 'periodsData is null');
  }

  const findActive = R.find(R.propEq('displayName', state.activePeriod))(periodsData);

  if (findActive === undefined) {
    dispatch({
      type: appActionTypes.SET_ACTIVE_PERIOD,
      payload: 'CYTD'
    });
  }

  const orderedPeriodsData = R.sortBy(obj => {
    return R.indexOf(obj.displayName, periodsOrder);
  })(periodsData);

  return (
    <>
      <StyledPeriodsContainer className="periods-container">
        {R.map(period => (
          <Period
            className="periods-container__item"
            key={period.displayName}
            displayName={period.displayName}
            text={period.displayLabel}
          />
        ))(orderedPeriodsData)}
        <StyledAsOfDatesMenuContainer>
          <AsOfDatesMenu />
        </StyledAsOfDatesMenuContainer>
      </StyledPeriodsContainer>
    </>
  );
};

export default Periods;
