// import React, {useEffect, useState} from 'react';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {InteractionRequiredAuthError, InteractionStatus} from '@azure/msal-browser';
import {useEffect} from 'react';
import {graphqlConfig, silentTokenRequest} from '../../msalAuthConfig';
import logger from '../../logger';

const AuthorizedApolloProvider = ({children}) => {
  const {instance, inProgress} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const [token, setToken] = useState();

  const getTokenAsync = async () => {
    const accounts = await instance.getAllAccounts();

    const account = accounts.length > 0 ? accounts[0] : null;

    if (account && inProgress === InteractionStatus.None) {
      try {
        const request = {
          ...silentTokenRequest,
          account
        };

        // commenting out for release v0.9.36 until logic can be refined.  this change insures token will be renewed for 24 hours...
        // ... which is the life of the refresh token.  And the user will not be logged out until refresh token expires - 24 hours
        // if (account.idTokenClaims.exp < new Date() / 1000) {
        //   // if session token expiration is less than current time... force logout
        //   instance.logoutRedirect();
        // }

        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // if silent token renewal returns an error that interaction is required, log the user out and start over
          instance.logoutRedirect();
        }
        // .. otherwise, just throw the error
        throw err;
      }
    }
    return null;
  };

  const authLink = setContext(async (_, {headers}) => {
    const token = await getTokenAsync();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null
      }
    };
  });

  const httpLink = createHttpLink({
    uri: graphqlConfig.url
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({message, locations, path}) =>
        logger.trace({method: '[MSAL GraphQL error]', message, locations, path})
      );
    if (networkError) logger.trace({method: '[MSAL Network error]', networkError});
  });

  const retryLink = new RetryLink();

  const client = new ApolloClient({
    link: from([authLink, retryLink, errorLink, httpLink]),
    cache: new InMemoryCache()
  });

  useEffect(() => {
    if (!useIsAuthenticated) {
      (async () => {
        await client.resetStore();
      })();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
