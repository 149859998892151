import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {LineChart, Line, ResponsiveContainer} from 'recharts';

import * as COLORS from '../../theme/colors';
import {formatMonth} from '../../utils';
import {NA} from '../../utils/errorHelpers';

/**
 *@arg: testingWidth: {integer} - sets ResponsiveContainer width; see
 * https://stackoverflow.com/questions/60215153/when-mounted-in-enzyme-why-does-a-recharts-chart-with-a-responsivecontainer-not
 */

const SparklinesGraph = ({reportData, testingWidth}) => {
  if (R.isNil(reportData) || R.isEmpty(reportData)) return NA;
  const sparklinesDataMapped = R.map(dataPoint => {
    return {
      name: formatMonth(dataPoint.date).toUpperCase(),
      value: dataPoint.value
    };
  })(reportData);

  return (
    <ResponsiveContainer width={testingWidth} height={75}>
      <LineChart
        data={sparklinesDataMapped}
        width={140}
        height={75}
        margin={{top: 25, right: 5, left: 5}}
        cursor="auto"
      >
        <Line
          dataKey="value"
          stroke={COLORS['@secondary-blue']}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
          activeDot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

SparklinesGraph.propTypes = {
  reportData: PropTypes.array,
  testingWidth: PropTypes.number
};

export default SparklinesGraph;
