import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {AppContext, appActionTypes} from '../../context/AppContext';
import * as COLORS from '../../theme/colors';
import {setRenderError} from '../../utils/errorHelpers';

const PeriodButton = styled.button`
  color: ${COLORS['@header-text']};
  background-color: ${COLORS['@header-background']};
  border: none;
  padding: 0px 0px;
  &.period--selected {
    border-bottom: 3px solid ${COLORS['@primary-color']};
    outline: none;
    padding-bottom: 5px;
  }
  &:active:focus,
  :focus {
    outline: none;
  }
`;
const PaddingStyle = styled.div`
  position: static;
  padding-left: 14px;
  padding-right: 5px;
  height: 100%;
`;
const Period = ({text, displayName}) => {
  const {state, dispatch} = useContext(AppContext);
  const handleClick = () => {
    dispatch({type: appActionTypes.SET_ACTIVE_PERIOD, payload: displayName});
    setRenderError(dispatch, false);
  };

  return (
    <PaddingStyle>
      <PeriodButton
        className={`body-small-bold period-${displayName} ${
          state.activePeriod === displayName ? 'period period--selected' : 'period'
        }`}
        onClick={event => handleClick(event)}
        aria-label={text}
        value={text}
      >
        {text}
      </PeriodButton>
    </PaddingStyle>
  );
};

Period.propTypes = {
  text: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
};

export default Period;
