import React from 'react';
import PropTypes from 'prop-types';

import {StyledBody} from '../styledComponents';

const EmptyLayout = props => {
  const {children} = props;
  return (
    <>
      <StyledBody>{children}</StyledBody>
    </>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.shape({})
};

export default EmptyLayout;
