import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';

import CurrentAssetAllocationBarGraph from '../../../components/CurrentAssetAllocationBarGraph';
import {GET_ASSET_ALLOCATION_PORTFOLIO} from '../../../graphql/queries';
import {AppContext} from '../../../context/AppContext';
import {setRenderError} from '../../../utils/errorHelpers';
import {setLoadingCountPortfolioPage} from '../../../utils/utilHelpers';
import logger from '../../../logger'; /* eslint-disable-line */

const StyledCurrentAssetAllocation = styled.div`
  position: relative;
`;

const CurrentAssetAllocation = ({marketValueData}) => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedClientId, selectedPortfolioId, selectedAsOfDate} = state;

  const {loading, error, data} = useQuery(GET_ASSET_ALLOCATION_PORTFOLIO, {
    variables: {
      id: selectedClientId,
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountPortfolioPage(
        {
          key: 'PortfolioPage/CurrentAssetAllocation/index/GET_ASSET_ALLOCATION_PORTFOLIO',
          value: 1
        },
        dispatch
      );
    } else if (!loading) {
      setLoadingCountPortfolioPage(
        {
          key: 'PortfolioPage/CurrentAssetAllocation/index/GET_ASSET_ALLOCATION_PORTFOLIO',
          value: 0
        },
        dispatch
      );
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_ASSET_ALLOCATION_PORTFOLIO:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'portfolioPage';

  if (R.isNil(data?.data))
    setRenderError(
      dispatch,
      `[${page}:CurrentAssetAllocation] No GET_ASSET_ALLOCATION_PORTFOLIO graphql data`
    );
  if (R.isNil(data.data.allocation))
    setRenderError(
      dispatch,
      `[${page}:CurrentAssetAllocation] No GET_ASSET_ALLOCATION_PORTFOLIO allocation data`
    );

  if (R.isNil(data?.data?.allocation?.summary?.assets))
    setRenderError(
      dispatch,
      `[${page}:CurrentAssetAllocation] No GET_ASSET_ALLOCATION_PORTFOLIO allocation.summary.assets data`
    );

  const assetAllocationData = R.path(['allocation', 'summary', 'assets'], data.data);

  return (
    <StyledCurrentAssetAllocation>
      <CurrentAssetAllocationBarGraph
        marketValueData={marketValueData}
        data={assetAllocationData}
      />
    </StyledCurrentAssetAllocation>
  );
};

CurrentAssetAllocation.propTypes = {
  marketValueData: PropTypes.shape({})
};

export default CurrentAssetAllocation;
