import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {Table} from 'antd';

import COLORS from '../../theme/colors';
import useWindowDimensions from '../../hooks/useWindowSize';

const StyledTable = styled(Table)`
  margin-top: 25px;
  thead > tr > th {
    background-color: none;
    padding-bottom: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  tbody {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
  }
  tbody > tr > td:last-child {
    border: none;
  }
  tbody > tr > td {
    color: ${COLORS['@body-text']};
    border-color: ${COLORS['@neutrals-4']};
    & .sparkline-container {
      width: 120px;
    }
  }
  tbody > tr > .detail-table-padding {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  tbody > tr > .detail-table-padding-cash-fixed {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  tr: last-of-type > td {
    border: none;
  }
  .manager {
    font-weight: 700;
    width: 260px;
  }
  .manager-equities-style {
    font-weight: 700;
    width: 200px;
  }
  .no-border {
    border: none;
  }
  & .lowercase-heading {
    text-transform: none;
  }
`;

const DetailTableAntD = ({tableColumns, record}) => {
  const dimensions = useWindowDimensions();
  return (
    <StyledTable
      dataSource={record.subType2s}
      columns={tableColumns}
      pagination={false}
      onHeaderRow={(_columns, _index) => {
        return (
          <>
            header: {_columns} {_index}
          </>
        );
      }}
      scroll={dimensions.width < 1117 ? {x: 1000} : ''}
      tableLayout="auto"
    />
  );
};

DetailTableAntD.propTypes = {
  record: PropTypes.shape({
    subType2s: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      classNames: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

export default DetailTableAntD;
