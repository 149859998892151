import React from 'react';
import PropTypes from 'prop-types';
import {Dot} from 'recharts';
import * as COLORS from '../../../theme/colors';

const CustomizedDot = props => {
  const {cx, cy, graphType} = props;
  return (
    <Dot
      r={graphType === 'assetCard-graph' ? 3.5 : 5}
      cx={cx}
      cy={cy}
      fill={COLORS['@neutrals-9']}
    />
  );
};

CustomizedDot.propTypes = {
  cy: PropTypes.number,
  cx: PropTypes.number,
  graphType: PropTypes.string
};

export default CustomizedDot;
