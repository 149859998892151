import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as COLORS from '../../../theme/colors';
import {formatDollar} from '../../../utils';

const StyledToolTip = styled.div`
  background-color: ${COLORS['@neutrals-10']};
  position: relative;
  font-size: 12px;
  line-height: 18px;
  border-radius: 3px;
  padding: 5px 7px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${COLORS['@neutrals-1']};
  font-weight: 500;
`;

const StyledToolTipTitle = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledToolTipValues = styled.div`
  & .distribution-dot {
    background-color: ${COLORS['@secondary-blue']};
  }
  & .capitalCalls-dot {
    background-color: ${COLORS['@category-blue']};
  }
  & .netFlows-dot {
    background-color: ${COLORS['@neutrals-9']};
  }
`;

const StyledToolTipData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledDot = styled.span`
  height: 10px;
  width: 10px;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const CustomAssetCardToolTip = props => {
  const {payload} = props;

  if (payload.active && payload.payload[0] != null) {
    return (
      <StyledToolTip>
        <StyledToolTipTitle>{payload.payload[0].payload.toolTipLabel}</StyledToolTipTitle>
        <StyledToolTipValues>
          {payload.payload.map(data => (
            <StyledToolTipData key={data.dataKey}>
              <StyledDot className={`${data.dataKey}-dot`} />
              <div>
                {data.value < 0
                  ? `(${formatDollar(data.value).slice(1)})`
                  : formatDollar(data.value)}
              </div>
            </StyledToolTipData>
          ))}
        </StyledToolTipValues>
      </StyledToolTip>
    );
  }
  return null;
};

CustomAssetCardToolTip.propTypes = {
  payload: PropTypes.shape({
    coordinate: PropTypes.shape({}),
    position: PropTypes.shape({}),
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
  })
};
export default CustomAssetCardToolTip;
