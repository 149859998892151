import React from 'react';
import './PlaceHolder.css';

const PlaceHolder = () => {
  return (
    <>
      <div className="place-holder">Loading...</div>
    </>
  );
};

export default PlaceHolder;
