import React, {useState, useContext} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import COLORS from '../../theme/colors';
import SummaryTable from '../assetClassDetails/SummaryTable';
import {AppContext} from '../../context/AppContext';
import {
  tapLog /* eslint-disable-line no-unused-vars */,
  formatSummaryPercent,
  formatSummaryDollar,
  formatDollar,
  formatPercent,
  formatDate,
  formatSummaryDollarPercent,
  formatSummaryDollarPercentNAValue,
  formatPercentNoColorWithSign
} from '../../utils';
import SparklinesGraph from '../SparklinesGraph';
import {NA} from '../../utils/errorHelpers';
import {periodDisplayLabels} from '../../utils/periodLabels';
import DetailTableAntD from '../assetClassDetails/DetailTableAntD';

const StyledSubTypeContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 20px;
  & .border-bottom {
    border-bottom: 1px solid ${COLORS['@neutrals-4']};
  }
  & .summary {
    display: flex;
    flex-wrap: wrap;
  }
  & .summary-title-outer {
    width: 390px;
    margin-right: 10px;
  }
  & .summary-items {
    min-width: 300px;
    padding-top: 15px;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    margin-bottom: 20px;
    row-gap: 30px;
    column-gap: 10px;
  }
  & .summary-title__title {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #262626;
  }
  & .summary-title__value {
    margin-bottom: 30px;
  }
  & .summary-item__title {
    margin-bottom: 10px;
  }
  & .summary-item__NA-value {
    margin: auto;
    padding-right: 70px;
  }
  & .summary-item__value {
    margin-right: 5px;
  }
  & .summary-item-flex {
    display: flex;
    flex-wrap: wrap;
  }
  & .benchmarks {
    display: flex;
    flex-wrap: wrap;
  }
  & .benchmark-margin {
    margin-right: 20px;
  }
  & .dropdown-caret {
    outline: none;
    cursor: pointer;
    padding-top: 10px;
    margin-right: 15px;
    color: ${COLORS['@primary-color']};
  }
  & .gain-loss-margin-right {
    margin-right: 7px;
  }
  & .sparkline-container {
    width: 140px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 20px;
  }
  & .sparkline-position {
    position: relative;
  }
  & .sparkline-position-inner {
    position: absolute;
    top: -25px;
    left: -10px;
  }
`;

const SubType1 = ({data}) => {
  const {state} = useContext(AppContext);
  const {activePeriod} = state;
  const [displayAccounts, setDisplayAccounts] = useState(false);

  const handleDropDownClick = () => {
    if (displayAccounts === false) {
      setDisplayAccounts(true);
    } else {
      setDisplayAccounts(false);
    }
  };

  const renderBenchmarks = benchmarks => {
    if (R.isNil(benchmarks)) return null;
    if (R.isEmpty(benchmarks)) return null;
    return (
      <div className="benchmarks">
        {benchmarks.map(benchmark => (
          <div
            key={`${benchmark.displayName}-${benchmark.gainsPercent}`}
            className="benchmark-margin"
          >
            {benchmark.displayName}: {formatPercentNoColorWithSign(benchmark.gainsPercent, true)}
          </div>
        ))}
      </div>
    );
  };

  const renderSummaryTitle = (_title, _record) => {
    return (
      <div className="summary-title-outer">
        <div className="summary-title">
          <div
            tabIndex="0"
            role="button"
            onClick={() => handleDropDownClick()}
            onKeyDown={() => {}}
            className="summary-title__title"
          >
            {_record?.displayName || NA}
          </div>
          <h3 className="summary-title__value">
            {_record?.gainsPercent === null && _record?.gains === 0 ? (
              <>
                <span>{NA}</span> {`(${activePeriod})`}
              </>
            ) : (
              <>
                <span>{formatPercent(_record?.gainsPercent, true)}</span>
                {` ${formatDollar(_record?.gains, true)} (${periodDisplayLabels[activePeriod]})`}
              </>
            )}
          </h3>
          <div className="body-small ">
            {_record?.benchmarks ? renderBenchmarks(_record.benchmarks) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderFlows = (_title, _record) => {
    return <div>{formatSummaryDollar(_title, _record?.netflows)}</div>;
  };

  const renderMarketValue = (_title, _record) => {
    return <div>{formatSummaryDollar(_title, _record?.marketValue)}</div>;
  };

  const renderAllocation = (_title, _record) => {
    return <div>{formatSummaryPercent('Allocation', _record?.allocation)}</div>;
  };

  const renderSparkline = (_title, _record) => {
    if (R.isNil(_record?.report)) return NA;
    if (R.isNil(_record?.report?.data)) return NA;
    if (R.isEmpty(_record?.report?.data)) return NA;
    return (
      <div className="sparkline-container">
        <SparklinesGraph reportData={_record?.report?.data} type="sparklines" />
      </div>
    );
  };

  const renderHeaderSparkline = (_title, _record) => {
    if (R.isNil(_record?.report)) return NA;
    if (R.isNil(_record?.report?.data)) return NA;
    if (R.isEmpty(_record?.report?.data)) return NA;
    return (
      <div className="sparkline-position">
        <div className="sparkline-container sparkline-position-inner">
          <SparklinesGraph reportData={_record?.report?.data} type="sparklines" />
        </div>
      </div>
    );
  };

  const renderGainLoss = (_title, percentValue, dollarValue, sign) => {
    if (R.isNil(percentValue) || R.isNil(dollarValue)) return NA;
    return (
      <div className="summary-item-flex">
        {formatPercent(percentValue, sign) === 'N/A' && formatDollar(dollarValue) === 'N/A' ? (
          <div className="gain-loss-margin-right">{NA}</div>
        ) : (
          <>
            <div className="gain-loss-margin-right">{formatPercent(percentValue, sign)}</div>
            <div>{formatDollar(dollarValue)}</div>
          </>
        )}
      </div>
    );
  };

  const subHeaderColumns = [
    {
      key: 'title',
      dataIndex: 'marketValue',
      displayName: 'SummaryTitle',
      render: (_title, _record) => renderSummaryTitle(_title, _record)
    },
    {
      key: 'allocation',
      displayName: 'Allocation',
      dataIndex: 'allocation',
      render: (_title, _record) => renderAllocation(_title, _record)
    },
    {
      key: 'marketValue',
      displayName: 'Market Value',
      dataIndex: 'marketValue',
      render: (_title, _record) => renderMarketValue(_title, _record)
    },
    {
      key: 'summarySparkline',
      displayName: 'SummarySparkline',
      dataIndex: 'report',
      render: (_title, _record) => renderHeaderSparkline(_title, _record)
    },
    {
      key: 'netflows',
      displayName: `Net Flows (${periodDisplayLabels[activePeriod]})`,
      dataIndex: 'netflows',
      render: (_title, _record) => renderFlows(_title, _record)
    },
    {
      key: 'ITDGainLoss',
      displayName: 'Gain/Loss (ITD)',
      dataIndex: ['ITDGains', 'ITDGainsPercent'],
      render: (_title, _record) => {
        const ITDGainsPercent = _record?.ITDGainsPercent;
        const ITDGains = _record?.ITDGains;
        return ITDGainsPercent === null && ITDGains === 0
          ? formatSummaryDollarPercentNAValue(_title, NA)
          : formatSummaryDollarPercent(_title, ITDGainsPercent, ITDGains, true);
      }
    }
  ];

  const tableColumns = [
    {ellipsis: true, width: '8px', className: 'no-border detail-table-padding'},
    {
      key: 'manager',
      title: 'Manager',
      dataIndex: 'displayName',
      className: 'body-large manager-equities-style detail-table-padding',
      sorter: {
        compare: (a, b) => a.displayName > b.displayName
      },
      render: (text, item) => item?.displayName || NA
    },
    {
      key: 'allocation',
      title: 'Allocation',
      dataIndex: 'allocation',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.allocation - b.allocation
      },
      render: (text, item) => formatPercent(item?.allocation)
    },
    {
      key: 'marketValue',
      title: 'Market Value',
      dataIndex: 'marketValue',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.marketValue - b.marketValue
      },
      render: (text, item) => formatDollar(item?.marketValue)
    },
    {
      key: 'report',
      title: ``,
      dataIndex: 'report',
      className: 'body-medium detail-table-padding',
      render: (text, item) => renderSparkline(text, item)
    },
    {
      key: 'netflows',
      title: (
        <div>
          Net Flows{' '}
          <span className="body-small lowercase-heading">
            ({periodDisplayLabels[activePeriod]})
          </span>
        </div>
      ),
      dataIndex: 'netflows',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.netflows - b.netflows
      },
      render: (text, item) => formatDollar(item?.netflows)
    },
    {
      key: 'gainLoss',
      title: (
        <div>
          Gain/Loss{' '}
          <span className="body-small lowercase-heading">
            ({periodDisplayLabels[activePeriod]})
          </span>
        </div>
      ),
      dataIndex: ['gains', 'gainsPercent'],
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.gainsPercent - b.gainsPercent
      },
      render: (text, item) => {
        const gainsPercent = item?.gainsPercent ?? NA;
        const gains = item?.gains ?? NA;
        return renderGainLoss(text, gainsPercent, gains, true);
      }
    },
    {
      key: 'ITDGainLoss',
      title: (
        <div>
          Gain/Loss <span className="body-small lowercase-heading">(ITD)</span>
        </div>
      ),
      dataIndex: ['ITDGains', 'ITDGainsPercent'],
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.ITDGainsPercent - b.ITDGainsPercent
      },
      render: (text, item) => {
        const ITDGainsPercent = item?.ITDGainsPercent ?? NA;
        const ITDGains = item?.ITDGains ?? NA;
        return ITDGains === 0
          ? renderGainLoss(text, ITDGainsPercent, NA, true)
          : renderGainLoss(text, ITDGainsPercent, ITDGains, true);
      }
    },
    {
      key: 'inceptionDate',
      title: 'Inception Date',
      dataIndex: 'inceptionDate',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.inceptionDate - b.inceptionDate
      },
      render: (text, item) => formatDate(item?.inceptionDate) || NA
    },
    {ellipsis: true, width: '8px', className: 'detail-table-padding'}
  ];

  return (
    <StyledSubTypeContainer>
      <SummaryTable
        className="summary border-bottom"
        displayAccounts={displayAccounts}
        handleDropDownClick={handleDropDownClick}
        componentTitle={data?.displayName || NA}
        activePeriod={activePeriod}
        record={data}
        subHeaderColumns={subHeaderColumns}
      />
      {displayAccounts ? <DetailTableAntD record={data} tableColumns={tableColumns} /> : null}
    </StyledSubTypeContainer>
  );
};

SubType1.propTypes = {
  data: PropTypes.shape({
    subType2s: PropTypes.array,
    ITDGainsPercent: PropTypes.number,
    ITDGains: PropTypes.number,
    allocation: PropTypes.number,
    benchmarks: PropTypes.array,
    gains: PropTypes.number,
    gainsPercent: PropTypes.number,
    marketValue: PropTypes.number,
    netflows: PropTypes.number,
    report: PropTypes.shape({
      data: PropTypes.array
    }),
    displayName: PropTypes.string
  })
};

export default SubType1;
