import {gql} from '@apollo/client';

/**
 * @function getPortfolioPerformance / GET_PORTFOLIO_PERFORMANCE
 * Used for the assetAllocation graphs
 */

export const GET_PORTFOLIO_PERFORMANCE = gql`
  query GET_PORTFOLIO_PERFORMANCE($portfolioId: ID!, $asOfDate: ID!) {
    data: GetPortfolioPerformance(portfolioId: $portfolioId, asOfDate: $asOfDate) {
      periods {
        displayName
        code
        assets {
          assetClass
          allocation
          marketValue
        }
        summary {
          gains
          gainsPercent
          monthlyGainsPercent
          totalMarketValue
        }
      }
    }
  }
`;
