/* eslint-disable-line react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as COLORS from '../../../../theme/colors';

const CheckedBox = ({displayMarketValue, setDisplayMarketValue, label}) => {
  const hideMarketValue = false;

  const StyledCheckedBoxContainer = styled.div`
    display: flex;
    align-items: center;
    & .checked-box {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 20px;
      width: 20px;
      border-radius: 2px;
      margin-right: 10px;
      border: 1px solid ${COLORS['@checkbox-border']};
    }
    & .checked-box-checked {
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      height: 20px;
      width: 20px;
      border-radius: 2px;
      margin-right: 10px;
      border: 1px solid ${COLORS['@primary-color']};
      background-color: ${COLORS['@primary-color']};
      & .checkmark {
        display: inline-block;
        transform: rotate(45deg);
        height: 10px;
        width: 5px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        position: absolute;
        bottom: 5px;
        left: 6px;
      }
    }
  `;

  const handleCheckedBoxOnChange = () => {
    if (displayMarketValue) {
      setDisplayMarketValue(false);
    } else {
      setDisplayMarketValue(true);
    }
  };

  if (hideMarketValue) return null;

  /* eslint-disable react/no-unknown-property */
  return (
    <StyledCheckedBoxContainer className="checked-box-container">
      {displayMarketValue ? (
        <div
          className="checked-box-checked"
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          checked={displayMarketValue}
          onClick={handleCheckedBoxOnChange}
        >
          <div className="checkmark" data-testid="checkmark" />
        </div>
      ) : (
        <div
          className="checked-box"
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          checked={displayMarketValue}
          onClick={handleCheckedBoxOnChange}
        />
      )}
      <div className="body-large-bold">{label}</div>
    </StyledCheckedBoxContainer>
  );
  /* eslint-enable react/no-unknown-property */
};

CheckedBox.propTypes = {
  displayMarketValue: PropTypes.bool,
  setDisplayMarketValue: PropTypes.func,
  label: PropTypes.string
};

export default CheckedBox;
