import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {LineChart, Line, Tooltip, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';

import styled from '@emotion/styled';
import * as COLORS from '../../theme/colors';
import {formatDollar, formatYAxisValue, formatLineGraphXAxis} from '../../utils';

import {AppContext} from '../../context/AppContext';
import {NA} from '../../utils/errorHelpers';
import {getYAxisMinMax} from '../../utils/utilHelpers';
import CustomizedAxisTick from '../DistCCGraph/CustomRechartsComponents/CustomizedAxisTick';

const StyledToolTip = styled.div`
  background-color: ${COLORS['@neutrals-10']};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 0px 7px;
  border-radius: 3px;
  margin-left: -40px;
  color: ${COLORS['@neutrals-1']};
  & .tool-tip-value {
    min-width: 30px;
  }
`;

/**
 * @arg: type = line-graph, hero-graph
 * line-graph: contains data points, x-axis values, no x-axis
 * hero-graph: contains data points and cartesian grid, x-axis and y-axis
 * @arg: testingWidth: {integer} - sets REsponsiveContainer width; see
 * https://stackoverflow.com/questions/60215153/when-mounted-in-enzyme-why-does-a-recharts-chart-with-a-responsivecontainer-not
 */

const LineGraph = ({reportData, type, testingWidth, testingPeriod, testingInception}) => {
  const sortData = data => {
    return R.isNil(data) ? [] : R.sort((a, b) => a.date - b.date)(data);
  };
  const sortedReportData = useMemo(() => sortData(reportData), [reportData]);
  const [graphType, setGraphType] = useState(null);
  const {state} = useContext(AppContext);
  const {activePeriod} = state;
  const activeEntity = state.selectedEntityId;
  const activePortfolio = R.find(R.propEq('entity', activeEntity))(
    state.clientInfo?.portfolios || []
  );
  const clientInceptionDate = testingInception || activePortfolio?.quickSummary?.inceptionDate;

  const periodsWithCustomTick = ['CYTD', 'FYTD', '1YR AACR'];

  useEffect(() => {
    const lineGraph = {
      responsiveContainerHeight: 200,
      chartHeight: 200,
      chartWidth: 360,
      chartMargin: {
        top: 40,
        right: 25,
        left: 25,
        bottom: 25
      },
      xAxisLine: false,
      xAxisTickLine: false,
      yAxisDataKey: 'value',
      yAxisHide: true,
      yAxisLine: false,
      yAxisTickLine: false,
      lineDot: {stroke: COLORS['@secondary-blue'], fill: COLORS['@secondary-blue']},
      lineActiveDot: {
        stroke: COLORS['@secondary-blue'],
        fill: COLORS['@neutrals-1'],
        strokeWidth: 3,
        r: 5
      },
      toolTipsCursor: {stroke: COLORS['@neutrals-4']},
      toolTipsPosition: {y: 38},
      axisAngle: sortedReportData.length > 6 ? -35 : 0
    };

    const heroGraph = {
      responsiveContainerHeight: 290,
      chartHeight: 200,
      chartWidth: 360,
      chartMargin: {
        top: 10,
        left: 30,
        right: 30,
        bottom: 30
      },
      xAxisLine: {stroke: COLORS['neutrals-1']},
      xAxisTickLine: {stroke: COLORS['@neutrals-4']},
      xAxisTickLinePadding: {left: 50, right: 50},
      yAxisDataKey: 'yAxis',
      yAxisHide: false,
      yAxisLine: false,
      yAxisTickLine: false,
      lineDot: {stroke: COLORS['@secondary-blue'], fill: COLORS['@secondary-blue']},
      lineActiveDot: {
        stroke: COLORS['@secondary-blue'],
        fill: COLORS['@neutrals-1'],
        strokeWidth: 3,
        r: 5
      },
      tickMarginSize: 15,
      toolTipsCursor: false,
      toolTipsPosition: {y: -3},
      cartesianGrid: true,
      axisAngle: sortedReportData.length > 13 ? -30 : 0
    };

    if (type === 'line-graph') {
      setGraphType(lineGraph);
    } else {
      setGraphType(heroGraph);
    }
  }, [sortedReportData, type]);

  if (R.isNil(sortedReportData) || R.isEmpty(sortedReportData)) return NA;

  const {yAxisMax, yAxisMin} = getYAxisMinMax(reportData, 'lineGraph');

  const customToolTip = ({active, payload}) => {
    if (active && payload[0] != null) {
      return (
        <StyledToolTip>
          <p className="tool-tip-value">{formatDollar(payload[0].payload.value)}</p>
        </StyledToolTip>
      );
    }
    return null;
  };

  return graphType === null ? (
    false
  ) : (
    <ResponsiveContainer width={testingWidth} height={graphType.responsiveContainerHeight}>
      <LineChart
        data={
          testingPeriod || testingInception
            ? formatLineGraphXAxis(sortedReportData, type, testingPeriod, clientInceptionDate)
            : formatLineGraphXAxis(sortedReportData, type, activePeriod, clientInceptionDate)
        }
        width={graphType.chartWidth}
        height={graphType.chartHeight}
        margin={graphType.chartMargin}
        cursor="pointer"
      >
        {graphType.cartesianGrid ? (
          <CartesianGrid
            vertical={false}
            stroke={COLORS['neutrals-4']}
            fill={COLORS['neutrals-4']}
          />
        ) : (
          false
        )}
        <XAxis
          dataKey="name"
          axisLine={graphType.xAxisLine}
          tickLine={graphType.xAxisTickLine}
          tick={
            periodsWithCustomTick.includes(activePeriod) ||
            (testingPeriod && periodsWithCustomTick.includes(testingPeriod)) ? (
              <CustomizedAxisTick
                numOfPoints={sortedReportData.length}
                type={type === 'line-graph' ? 'assetCard-graph' : 'hero-graph'}
                period={activePeriod}
              />
            ) : (
              {fontSize: 11, fontWeight: 'bold', fontStyle: 'normal', letterSpacing: '1px'}
            )
          }
          tickMargin={graphType.tickMarginSize}
          tickSize={13}
          padding={graphType.xAxisTickLinePadding}
          angle={graphType.axisAngle}
          interval={0}
        />
        <YAxis
          dataKey={graphType.yAxisDataKey}
          domain={['auto', 'auto']}
          hide={graphType.yAxisHide}
          tickCount={4}
          axisLine={graphType.yAxisLine}
          tickLine={graphType.yAxisTickLine}
          tickFormatter={tick => {
            return formatYAxisValue(tick, yAxisMax, yAxisMin);
          }}
          tickMargin={graphType.tickMarginSize}
          tick={{stroke: COLORS['@neutrals-7'], fontSize: 13, fontWeight: 400, letterSpacing: 1}}
        />
        <Line
          type="linear"
          dataKey="value"
          stroke={COLORS['@secondary-blue']}
          strokeWidth={2}
          dot={graphType.lineDot}
          isAnimationActive={false}
          activeDot={graphType.lineActiveDot}
        />
        <Tooltip
          cursor={graphType.toolTipsCursor}
          content={customToolTip}
          position={graphType.toolTipsPosition}
          isAnimationActive={false}
          allowEscapeViewBox={{x: true, y: true}}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineGraph.propTypes = {
  reportData: PropTypes.array,
  type: PropTypes.string,
  testingWidth: PropTypes.number,
  testingPeriod: PropTypes.string,
  testingInception: PropTypes.number
};

export default LineGraph;
