import React, {useState, useContext} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import COLORS from '../../theme/colors';
import SummaryTable from '../assetClassDetails/SummaryTable';
import {AppContext} from '../../context/AppContext';
import {
  formatSummaryPercent,
  formatDollar,
  formatPercent,
  formatSummaryDollarPercent,
  formatPercentNoColorWithSign,
  formatMoc,
  formatSummaryMOC,
  formatSummaryIRR,
  formatSummaryTotalDistributions,
  formatDollarsInMillionsWithDecimal,
  formatDate
} from '../../utils';
import SparklinesGraph from '../SparklinesGraph';
import DetailTableAntD from '../assetClassDetails/DetailTableAntD';
import {periodDisplayLabels} from '../../utils/periodLabels';
import {NA} from '../../utils/errorHelpers';

const StyledSubTypeContainer = styled.div`
  margin-top: 45px;
  & .border-bottom {
    border-bottom: 1px solid ${COLORS['@neutrals-4']};
  }
  & .summary {
    display: flex;
    flex-wrap: wrap;
  }
  & .summary-title-outer {
    width: 320px;
    margin-right: 15px;
  }
  & .summary-items {
    width: auto;
    min-width: 300px;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 20px;
  }
  & .summary-items > div {
    flex-grow: 1;
    margin-bottom: 30px;
    margin-right: 5px;
  }
  & .summary-title__title {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #262626;
  }
  & .summary-title__value {
    margin-bottom: 30px;
  }
  & .summary-item__title {
    margin-bottom: 10px;
  }
  & .summary-item__value {
    margin-right: 5px;
  }
  & .summary-item-flex {
    display: flex;
    flex-wrap: wrap;
  }
  & .dropdown-caret {
    outline: none;
    cursor: pointer;
    padding-top: 10px;
    margin-right: 15px;
    color: ${COLORS['@primary-color']};
  }
  & .distributions-margin-right,
  .total-funded-margin-right {
    margin-right: 7px;
  }
  & .sparkline-container {
    width: 140px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 20px;
  }
  & .sparkline-position {
    position: relative;
  }
  & .sparkline-position-inner {
    position: absolute;
    top: -25px;
    left: -10px;
  }
`;

const StyledTotalFundedBar = styled.div`
  border: 1px solid ${COLORS['@neutrals-4']};
  width: 200px;
  height: 8px;
  border-radius: 16px;
  background: ${COLORS['@neutrals-3']};
  & .total-funded-value {
    height: 100%;
    width: ${props => (parseFloat(props.width) > 100 ? '100%' : props.width)};
    border-radius: 16px;
    background: ${COLORS['@secondary-blue']};
  }
`;

const StyledNAValue = styled.div`
  text-align: center;
`;

const StyledNASummaryValue = styled.div`
  padding-left: 34px;
`;

const SubType1PEVC = ({data, testAsOfDate}) => {
  const {state} = useContext(AppContext);
  const {activePeriod} = state;
  const [displayAccounts, setDisplayAccounts] = useState(false);
  const asOfDateList = state?.asOfDatesList || [];
  const totalFundedAsOfDate = testAsOfDate || asOfDateList[0];

  const handleDropDownClick = () => {
    if (displayAccounts === false) {
      setDisplayAccounts(true);
    } else {
      setDisplayAccounts(false);
    }
  };

  const renderSummaryTitle = (_title, _record) => {
    return (
      <div className="summary-title-outer">
        <div className="summary-title">
          <div
            tabIndex="0"
            role="button"
            onClick={() => handleDropDownClick()}
            onKeyDown={() => {}}
            className="summary-title__title"
          >
            {_record?.displayName || NA}
          </div>
          <h3 className="summary-title__value">{formatDollar(_record?.marketValue)}</h3>
        </div>
      </div>
    );
  };

  const renderAllocation = (_title, _record) => {
    return <div>{formatSummaryPercent('Allocation', _record?.allocation)}</div>;
  };

  const renderMOC = (_title, _record) => {
    return (
      <div style={{display: 'flex'}}>
        <div>{formatSummaryMOC(_title, _record?.moc)}</div>
      </div>
    );
  };

  const renderDPI = (_title, _record) => {
    return <div>{formatSummaryMOC(_title, _record?.distributionsPerCapital)}</div>;
  };

  const renderIRR = (_title, _record) => {
    return <div>{formatSummaryIRR(_title, _record?.irr)}</div>;
  };

  const renderSparkline = (_title, _record) => {
    if (_record?.report === null) return 'N/A';
    if (_record?.report?.data === null) return 'N/A';
    if (R.isEmpty(_record?.report?.data)) return 'N/A';
    return (
      <div className="sparkline-container">
        <SparklinesGraph reportData={_record.report.data} type="sparklines" />
      </div>
    );
  };

  const formatSummaryTotalFunded = (
    title,
    capitalPercentValue,
    totalPaidCapitalValue,
    totalCommittedCapitalValue,
    asOfDate
  ) => {
    return (
      <div className="summary-item">
        <div className="summary-item__title label-medium">
          {title} AS OF {formatDate(asOfDate)}
        </div>
        <div className="summary-item-flex">
          <div className="summary-item__value body-medium">
            {formatPercent(capitalPercentValue)} <span>&#8226;</span>{' '}
          </div>
          <div className="summary-item__value body-medium">
            {formatDollarsInMillionsWithDecimal(totalPaidCapitalValue)} /
          </div>
          <div className="summary-item__value body-medium">
            {formatDollarsInMillionsWithDecimal(totalCommittedCapitalValue)}
          </div>
        </div>
        <StyledTotalFundedBar width={formatPercent(capitalPercentValue)}>
          <div className="total-funded-value" />
        </StyledTotalFundedBar>
      </div>
    );
  };

  const renderTotalFunded = (
    title,
    capitalPercentValue,
    totalPaidCapitalValue,
    totalCommittedCapitalValue
  ) => {
    return (
      <div className="summary-item">
        <div className="summary-item-flex">
          <div className="total-funded-margin-right">{formatPercent(capitalPercentValue)} - </div>
          <div>{formatDollarsInMillionsWithDecimal(totalPaidCapitalValue)} /</div>
          <div>{formatDollarsInMillionsWithDecimal(totalCommittedCapitalValue)}</div>
        </div>
        <StyledTotalFundedBar width={formatPercent(capitalPercentValue)}>
          <div className="total-funded-value" />
        </StyledTotalFundedBar>
      </div>
    );
  };

  const renderNA = value => {
    return <StyledNAValue>{value}</StyledNAValue>;
  };

  const renderSummaryNA = (title, value) => {
    return (
      <div className="summary-item">
        <div className="summary-item__title label-medium">{title}</div>
        <div className="summary-item-flex">
          <div className="summary-item__value body-medium">
            <StyledNASummaryValue>{value}</StyledNASummaryValue>
          </div>
        </div>
      </div>
    );
  };

  const subHeaderColumns = [
    {
      key: 'title',
      displayName: 'SummaryTitle',
      dataIndex: 'marketValue',
      render: (_title, _record) => renderSummaryTitle(_title, _record)
    },
    {
      key: 'GainLoss',
      displayName: `Gain/Loss (${periodDisplayLabels[activePeriod]})`,
      dataIndex: ['gains', 'gainsPercent'],
      render: (_title, _record) =>
        formatSummaryDollarPercent(_title, _record?.gainsPercent, _record?.gains, true)
    },
    {
      key: 'allocation',
      displayName: 'Allocation',
      dataIndex: 'allocation',
      render: (_title, _record) => renderAllocation(_title, _record)
    },
    {
      key: 'totalDistributionsValue',
      displayName: `Total Distributions (${periodDisplayLabels[activePeriod]})`,
      dataIndex: 'totalDistributionsValue',
      render: (_title, _record) =>
        formatSummaryTotalDistributions(_title, _record?.totalDistributionsValue)
    },
    {
      key: 'distributionsPerCapital',
      displayName: 'dpi',
      dataIndex: 'distributionsPerCapital',
      render: (_title, _record) => renderDPI(_title, _record)
    },
    {
      key: 'moc',
      displayName: 'moc',
      dataIndex: 'moc',
      render: (_title, _record) => renderMOC(_title, _record)
    },
    {
      key: 'irr',
      displayName: `irr (${periodDisplayLabels[activePeriod]})`,
      dataIndex: 'irr',
      render: (_title, _record) => renderIRR(_title, _record)
    },
    {
      key: 'totalFunded',
      displayName: 'Total Funded',
      dataIndex: 'totalFunded',
      render: (_title, _record) =>
        _record?.totalPaidCapitalPercent === null &&
        _record?.totalPaidCapital === null &&
        _record?.totalCommittedCapital === null
          ? renderSummaryNA(_title, NA)
          : formatSummaryTotalFunded(
              _title,
              _record?.totalPaidCapitalPercent,
              _record?.totalPaidCapital,
              _record?.totalCommittedCapital,
              totalFundedAsOfDate
            )
    }
  ];

  const tableColumns = [
    {ellipsis: true, width: '8px', className: 'no-border detail-table-padding'},
    {
      key: 'manager',
      title: 'Manager',
      dataIndex: 'displayName',
      className: 'body-large manager detail-table-padding',
      sorter: {
        compare: (a, b) => a.displayName > b.displayName
      },
      render: (text, item) => item?.displayName || NA
    },
    {
      key: 'vintageYear',
      title: 'Vintage',
      dataIndex: 'vintageYear',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.vintageYear - b.vintageYear
      },
      render: (text, item) => item?.vintageYear || NA
    },
    {
      key: 'marketValue',
      title: 'Market Value',
      dataIndex: 'marketValue',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.marketValue - b.marketValue
      },
      render: (text, item) => formatDollar(item?.marketValue)
    },
    {
      key: 'report',
      title: ``,
      dataIndex: 'report',
      className: 'body-medium detail-table-padding',
      render: (text, item) => renderSparkline(text, item)
    },
    {
      key: 'totalDistributionsValue',
      title: 'Total Distributions',
      dataIndex: 'totalDistributionsValue',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.totalDistributionsValue - b.totalDistributionsValue
      },
      render: (text, item) => formatDollar(item?.totalDistributionsValue)
    },
    {
      key: 'distributionsPerCapital',
      title: 'dpi',
      dataIndex: 'distributionsPerCapital',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.distributionsPerCapital - b.distributionsPerCapital
      },
      render: (text, item) => formatMoc(item?.distributionsPerCapital)
    },
    {
      key: 'moc',
      title: 'moc',
      dataIndex: 'moc',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.moc - b.moc
      },
      render: (text, item) => formatMoc(item?.moc)
    },
    {
      key: 'irr',
      title: 'irr',
      dataIndex: 'irr',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.irr - b.irr
      },
      render: (text, item) => formatPercentNoColorWithSign(item?.irr, true)
    },
    {
      key: 'totalFunded',
      title: `Total Funded As Of ${formatDate(totalFundedAsOfDate)}`,
      dataIndex: 'totalFunded',
      className: 'body-medium detail-table-padding',
      sorter: {
        compare: (a, b) => a.totalPaidCapitalPercent - b.totalPaidCapitalPercent
      },
      render: (text, item) =>
        item?.totalPaidCapitalPercent === null &&
        item?.totalPaidCapital === null &&
        item?.totalCommittedCapital === null
          ? renderNA(NA)
          : renderTotalFunded(
              text,
              item?.totalPaidCapitalPercent,
              item?.totalPaidCapital,
              item?.totalCommittedCapital
            )
    },
    {ellipsis: true, width: '8px', className: 'body-medium detail-table-padding'}
  ];

  return (
    <StyledSubTypeContainer>
      <SummaryTable
        className="summary border-bottom"
        displayAccounts={displayAccounts}
        handleDropDownClick={handleDropDownClick}
        componentTitle={data?.displayName || NA}
        activePeriod={activePeriod}
        record={data}
        subHeaderColumns={subHeaderColumns}
      />
      {displayAccounts ? <DetailTableAntD record={data} tableColumns={tableColumns} /> : null}
    </StyledSubTypeContainer>
  );
};

SubType1PEVC.propTypes = {
  data: PropTypes.shape({
    subType2s: PropTypes.array,
    allocation: PropTypes.number,
    distributionsPerCapital: PropTypes.number,
    gains: PropTypes.number,
    gainsPercent: PropTypes.number,
    ITDGainsPercent: PropTypes.number,
    ITDGains: PropTypes.number,
    irr: PropTypes.number,
    marketValue: PropTypes.number,
    moc: PropTypes.number,
    report: PropTypes.shape({
      data: PropTypes.array
    }),
    totalCommittedCapital: PropTypes.number,
    totalDistributionsValue: PropTypes.number,
    totalPaidCapital: PropTypes.number,
    totalPaidCapitalPercent: PropTypes.number,
    displayName: PropTypes.string
  })
};

export default SubType1PEVC;
