/* eslint-disable func-names */
import * as log from 'loglevel';

// Log levels are: trace/debug/info/warn/error
const loggerLevel = process.env.REACT_APP_LOG_LEVEL || 'info';

const originalFactory = log.methodFactory;
/* eslint-disable-next-line no-import-assign */
log.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function (...args) {
    const messages = [`CDE ${new Date().toISOString()}: `];
    const elements = Object.getOwnPropertyNames(args[0]);
    for (let i = 0; i < elements.length; i++) {
      const elementName = elements[i];
      const elementValue = args[0][elements[i]];
      messages.push(`${elementName}:`);
      switch (typeof elementValue) {
        case 'string':
          messages.push(`'${elementValue}'`);
          break;
        case 'number':
        case 'boolean':
          messages.push(elementValue);
          break;
        case 'undefined':
          messages.push('undefined');
          break;
        case 'null':
          messages.push('null');
          break;
        default:
          messages.push(elementValue);
          console.log('logger', elementValue); /* eslint-disable-line */
      }
    }
    rawMethod.apply(undefined, messages); /* eslint-disable-line */
  };
};
log.setLevel(loggerLevel); // Be sure to call setLevel method in order to apply plugin
log.noConflict();

export default log;
