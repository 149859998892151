import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as COLORS from '../../../theme/colors';
import {formatDollar} from '../../../utils';

const StyledToolTip = styled.div`
  background-color: ${COLORS['@neutrals-10']};
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 12px 20px 7px 15px;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${COLORS['@neutrals-1']};
  & .arrow-left {
    z-index: 9;
    position: fixed;
    top: 25px;
    left: 10px;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: ${COLORS['@neutrals-10']};
    border-radius: 3px;
  }
  & .arrow-right {
    z-index: 9;
    position: fixed;
    top: 25px;
    right: 10px;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: ${COLORS['@neutrals-10']};
    border-radius: 3px;
  }
  & .tooltip-body {
    position: relative;
    z-index: 10;
  }
`;

const StyledToolTipTitle = styled.div`
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
`;

const StyledToolTipData = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & .tooltip-field {
    font-size: 11px;
    font-style: normal;
    letter-spacing: 1px;
  }
  & .tooltip-value {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1px;
  }
`;

const StyledToolTipDataItem = styled.div`
  text-align: right;
  width: 50%;
`;

const CustomToolTip = props => {
  // eslint-disable-next-line prettier/prettier
  const {payload, activeIndex, numOfPoints, toolTipLeftRenderOffset, setToolTipLeftRenderOffset} = props;

  if (
    payload?.payload[0]?.payload?.toolTipOffset &&
    toolTipLeftRenderOffset !== payload?.payload[0]?.payload?.toolTipOffset
  ) {
    setToolTipLeftRenderOffset(payload?.payload[0]?.payload?.toolTipOffset);
  }

  if (payload.active && payload.payload[0] != null) {
    return (
      <StyledToolTip>
        {activeIndex > numOfPoints / 2 ? (
          <div className="arrow-right" />
        ) : (
          <div className="arrow-left" />
        )}
        <div className="tooltip-body">
          <StyledToolTipTitle>{payload.payload[0].payload.toolTipLabel} Summary</StyledToolTipTitle>
          {payload.payload.map(data => (
            <StyledToolTipData key={data.name}>
              <StyledToolTipDataItem className="tooltip-field">{data.name}</StyledToolTipDataItem>
              <StyledToolTipDataItem className="tooltip-value">
                {data.value < 0
                  ? `(${formatDollar(data.value).slice(1)})`
                  : formatDollar(data.value)}
              </StyledToolTipDataItem>
            </StyledToolTipData>
          ))}
        </div>
      </StyledToolTip>
    );
  }
  return null;
};

CustomToolTip.propTypes = {
  payload: PropTypes.shape({
    coordinate: PropTypes.shape({}),
    position: PropTypes.shape({}),
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
  }),
  activeIndex: PropTypes.number,
  numOfPoints: PropTypes.number,
  toolTipLeftRenderOffset: PropTypes.number,
  setToolTipLeftRenderOffset: PropTypes.func
};
export default CustomToolTip;
