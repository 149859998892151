import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {Redirect} from 'react-router-dom';

import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';
import HeaderSummary from '../../components/assetClassDetails/HeaderSummary';
import SubType1 from '../../components/subType1';
import HeroGraph from './heroGraph';
import {NA} from '../../utils/errorHelpers';
import ErrorHandler from '../../components/ErrorHandler';
import DisclosureText from '../../components/DisclosureText';
import {setLoadingCountAssetDetailsPage} from '../../utils/utilHelpers';

const StyledEquitiesContainer = styled.div`
  & .row-item__value {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .row-title__value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .summary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    & .summary-title {
      grid: 1/3;
      min-width: 300px;
    }
    & .summary-item {
    }
  }
`;

const EquitiesPage = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedPortfolioId, selectedAsOfDate} = state;
  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountAssetDetailsPage({key: '/equitiesPage', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountAssetDetailsPage({key: '/equitiesPage', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'EquitiesPage';

  if (R.isNil(data?.data)) return <ErrorHandler loggedMessage={`[${page}] No graphQL data`} />;
  if (R.isNil(data?.data?.periods))
    return <ErrorHandler loggedMessage={`[${page}] No periods data`} />;

  const periodsData = data.data?.periods || [];

  const findPeriod = R.findIndex(R.propEq('displayName', state.activePeriod))(periodsData);
  if (findPeriod === -1)
    return (
      <ErrorHandler
        loggedMessage={`[${page}] No data for selected period: ${state.activePeriod}`}
      />
    );
  const assetClassData = periodsData[findPeriod];
  if (R.isNil(assetClassData.assets) || R.isEmpty(assetClassData.assets))
    return (
      <ErrorHandler loggedMessage={`[${page}] No assets data: period ${state.activePeriod}`} />
    );

  const equitiesData = assetClassData.assets.filter(asset => asset.assetClass === 'equities');
  if (R.isNil(equitiesData) || R.isEmpty(equitiesData)) {
    // Must be an entity switch where the new entity has no alternatives data
    // Force our way back to the portfolio page
    return <Redirect to="/portfolioPage" />;
  }

  const equitiesAccountData = equitiesData[0];

  const summaryData = {
    assetClass: equitiesAccountData.assetClass || NA,
    title: 'Equities',
    marketValue: equitiesAccountData.marketValue ?? NA,
    gains: equitiesAccountData.gains ?? NA,
    gainsPercent: equitiesAccountData.gainsPercent ?? NA,
    allocation: equitiesAccountData.allocation ?? NA,
    donutGraphData: assetClassData.assets
  };

  const subType1s = equitiesAccountData?.subType1s || [];

  return (
    <StyledEquitiesContainer>
      <div className="portfolio-box-shadow">
        <HeaderSummary summaryData={summaryData} />
        {equitiesAccountData.report !== null ? (
          <HeroGraph reportData={equitiesAccountData.report} />
        ) : null}
      </div>
      <div className="side-padding-50">
        {subType1s.map(subType1 => {
          return <SubType1 data={subType1} key={subType1.displayName?.replace(' ', '-')} />;
        })}
        <DisclosureText page={page} />
      </div>
    </StyledEquitiesContainer>
  );
};

export default EquitiesPage;
