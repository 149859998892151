import React, {useContext, useState, useMemo} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DistCCGraph from '../../../components/DistCCGraph/DistCCGraphComponent';
import {formatDistCCGraphXAxis} from '../../../utils';
import {AppContext} from '../../../context/AppContext';
import DistCCHeroGraphHeader from '../../../components/DistCCGraph/DistCCHeroGraphHeader';
import {NA} from '../../../utils/errorHelpers';

const StyledHeroGraph = styled.div`
  margin-bottom: 45px;
`;

const StyledGraphContainer = styled.div``;

const StyledNAValue = styled.div`
  width: 100px;
  margin: auto;
`;

const HeroGraph = ({reportData, asOfDate, testingWidth, testingInception}) => {
  const {state} = useContext(AppContext);
  const {activePeriod} = state;
  const [displayMarketValue, setDisplayMarketValue] = useState(true);
  const activeEntity = state.selectedEntityId;
  const activePortfolio = R.find(R.propEq('entity', activeEntity))(
    state.clientInfo?.portfolios || []
  );
  const clientInceptionDate = testingInception || activePortfolio?.quickSummary?.inceptionDate;

  const sortData = data => {
    return R.isNil(data) ? [] : R.sort((a, b) => a.date - b.date)(data);
  };
  const sortedReportData = useMemo(() => sortData(reportData.data), [reportData]);

  if (R.isNil(reportData.data) || R.isEmpty(reportData.data))
    return <StyledNAValue>{NA}</StyledNAValue>;

  return (
    <div className="side-padding-50">
      <StyledHeroGraph>
        <StyledGraphContainer data-testid="real-assets-herograph">
          <DistCCHeroGraphHeader
            displayMarketValue={displayMarketValue}
            setDisplayMarketValue={setDisplayMarketValue}
          />
          <DistCCGraph
            reportData={formatDistCCGraphXAxis(
              sortedReportData,
              'hero-graph',
              displayMarketValue,
              activePeriod,
              asOfDate,
              clientInceptionDate
            )}
            testingWidth={testingWidth}
            type="hero-graph"
            displayMarketValue={displayMarketValue}
          />
        </StyledGraphContainer>
      </StyledHeroGraph>
    </div>
  );
};

HeroGraph.propTypes = {
  testingWidth: PropTypes.number,
  reportData: PropTypes.shape({
    data: PropTypes.array
  }),
  asOfDate: PropTypes.number,
  testingInception: PropTypes.number
};

export default HeroGraph;
