import React, {useContext} from 'react';
import styled from '@emotion/styled';

import BigPicture from './BigPicture';
import Overview from './Overview';
import {PageContainer} from '../../styledComponents';
import {AppContext} from '../../context/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import DisclosureText from '../../components/DisclosureText';

const StyledCoverPageTitle = styled.div`
  padding-top: 29px;
`;

const CoverPage = () => {
  const {state} = useContext(AppContext);
  const {renderError} = state;
  const loaders = state.loadingCountCoverPage;
  const headerLoaders = state.loadingCountHeader;
  let isLoading = true;
  isLoading =
    Object.values(loaders).reduce((a, b) => a + b, 0) > 0 ||
    Object.values(headerLoaders).reduce((a, b) => a + b, 0) > 0;

  if (renderError !== false) return <ErrorHandler loggedMessage={renderError} />;

  return (
    <PageContainer className="side-padding-50" style={{visibility: isLoading ? 'hidden' : ''}}>
      <StyledCoverPageTitle className="page-title">Portfolio Overview</StyledCoverPageTitle>
      <Overview />
      <BigPicture />
      <DisclosureText page="coverPage" />
    </PageContainer>
  );
};

export default CoverPage;
