import React from 'react';
import PropTypes from 'prop-types';
import {Dot} from 'recharts';
import * as COLORS from '../../../theme/colors';

const CustomizedActiveDot = ({
  payload,
  toolTipYCoordinate,
  setToolTipYCoordinate,
  dotXCoordinate,
  setDotXCoordinate,
  activeIndex,
  setActiveIndex,
  graphType
}) => {
  const {cx, cy, index, payload: _payload} = payload;
  if (toolTipYCoordinate !== cy) setToolTipYCoordinate(cy);
  if (dotXCoordinate !== cx) setDotXCoordinate(cx);
  if (activeIndex !== index) setActiveIndex(index);

  return (
    <svg>
      <Dot
        r={graphType === 'assetCard-graph' ? 15 : 20}
        cx={cx}
        cy={cy}
        strokeWidth={0}
        style={{opacity: 0.3}}
        fill="#77b3d4"
      />
      <Dot
        r={graphType === 'assetCard-graph' ? 5 : 7}
        cx={cx}
        cy={cy}
        strokeWidth={graphType === 'assetCard-graph' ? 3 : 4}
        stroke={_payload.netFlows < 0 ? COLORS['@category-blue'] : COLORS['@secondary-blue']}
        fill={COLORS['@neutrals-4']}
      />
    </svg>
  );
};

CustomizedActiveDot.propTypes = {
  payload: PropTypes.shape({
    payload: PropTypes.shape({
      netFlows: PropTypes.number
    }),
    cy: PropTypes.number,
    cx: PropTypes.number,
    index: PropTypes.number
  }),
  setToolTipYCoordinate: PropTypes.func,
  toolTipYCoordinate: PropTypes.number,
  setDotXCoordinate: PropTypes.func,
  dotXCoordinate: PropTypes.number,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  graphType: PropTypes.string
};

export default CustomizedActiveDot;
