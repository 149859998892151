import React, {useContext} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {AppContext, appActionTypes} from '../../../context/AppContext';
import DisplayWidgets from './DisplayWidgets';
import AssetClasses from './AssetClasses';
import DateRange from './DateRange';
import {assetClassOrder, assetClassDisplayName} from '../../../utils';
import {setRenderError} from '../../../utils/errorHelpers';
import logger from '../../../logger'; /* eslint-disable-line */

const StyledTotalsAndWidgets = styled.div`
  display: grid;
  justify-content: space-between;
  margin-bottom: 10px;
  & .date-range {
    grid-column: 1/2;
  }
  & .display-widgets {
    grid-column: 2/3;
  }
`;

const StyledAssetClassContainer = styled.div`
  margin-top: 16px;
`;

const AssetClassContainer = ({
  assetClassData,
  assetClassAllocationData,
  summaryTotalPortfolioData,
  assetClassGraphData,
  firstDateofPeriod,
  lastDateofPeriod,
  isFYTDClient,
  assetClassByPeriodData,
  testingInception
}) => {
  const {state, dispatch} = useContext(AppContext);
  const setCardsOrList = cardsOrList => {
    dispatch({type: appActionTypes.SET_CARDS_OR_LIST, payload: cardsOrList});
  };

  const page = 'portfolioPage';

  if (R.isNil(assetClassData))
    setRenderError(dispatch, `[${page}:AssetClassContainer] No assetClassData`);
  if (R.isNil(assetClassAllocationData))
    setRenderError(dispatch, `[${page}:AssetClassContainer] No assetClassAllocationData`);

  const orderedAssets = R.sortBy(obj => {
    return R.indexOf(obj.assetClass, assetClassOrder());
  })(assetClassData.assets || []);

  const periodsData = assetClassData || {};
  const cardsAndListData = () => {
    if (R.isEmpty(periodsData)) return [];
    return R.map(asset => {
      /* eslint-disable-line */
      return {
        key: asset.assetClass,
        class: assetClassDisplayName(asset.assetClass) || asset.assetClass,
        ...asset,
        CYTD: assetClassAllocationData[asset.assetClass]?.CYTD,
        YRAACR1: assetClassAllocationData[asset.assetClass]?.YRAACR1,
        YRAACR3: assetClassAllocationData[asset.assetClass]?.YRAACR3,
        YRAACR5: assetClassAllocationData[asset.assetClass]?.YRAACR5,
        FYTD: assetClassAllocationData[asset.assetClass]?.FYTD
      };
    })(orderedAssets);
  };

  if (R.isNil(cardsAndListData())) return 'Loading...';
  return (
    <StyledAssetClassContainer>
      <div data-testid="asset-class-container" className="AssetClassContainer">
        <StyledTotalsAndWidgets>
          {state.cardsOrList !== 'charts' ? (
            <DateRange firstDate={firstDateofPeriod} lastDate={lastDateofPeriod} />
          ) : null}
          <DisplayWidgets cardsOrList={state.cardsOrList} setCardsOrList={setCardsOrList} />
        </StyledTotalsAndWidgets>
        <AssetClasses
          cardsOrList={state.cardsOrList}
          listData={cardsAndListData()}
          summaryTotalPortfolioData={summaryTotalPortfolioData}
          cardsData={cardsAndListData()}
          cardsGraphData={assetClassGraphData}
          isFYTDClient={isFYTDClient}
          testingInception={testingInception}
          assetClassByPeriodData={assetClassByPeriodData}
        />
      </div>
    </StyledAssetClassContainer>
  );
};

AssetClassContainer.propTypes = {
  assetClassContainer: PropTypes.shape({}),
  assetClassAllocationData: PropTypes.shape({}),
  assetClassData: PropTypes.shape({
    assets: PropTypes.array,
    code: PropTypes.string
  }),
  assetClassGraphData: PropTypes.array,
  firstDateofPeriod: PropTypes.number,
  lastDateofPeriod: PropTypes.number,
  isFYTDClient: PropTypes.number,
  assetClassByPeriodData: PropTypes.array,
  testingInception: PropTypes.number
};

export default AssetClassContainer;
