import React from 'react';
import * as R from 'ramda';
import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import './Login.css';
import {GraphqlLoadingHandler} from '../../components/graphqlHandlers';
import {GET_MY_CLIENTS} from '../../graphql/queries';
import DefaultPeriod from './defaultPeriod';
import ErrorHandler from '../../components/ErrorHandler';
import {isAllowableMgetErrors} from '../../utils/utilHelpers';

const LoadingClientsPage = () => {
  const history = useHistory(); /* eslint-disable-line */

  const {loading, error, data} = useQuery(GET_MY_CLIENTS, {
    fetchPolicy: 'network-only',
    returnPartialData: true,
    errorPolicy: 'all'
  });
  if (loading) return <GraphqlLoadingHandler />;

  // Ticket #3412 work-around
  if (error && !isAllowableMgetErrors(error.message)) {
    return <ErrorHandler loggedMessage={`[LoadingClientsPage] GET_MY_CLIENTS ${error.message}`} />;
  }

  if (R.isNil(data?.data) || R.isEmpty(data?.data))
    return (
      <ErrorHandler loggedMessage="[LoadingClientsPage] GET_MY_CLIENTS No client data found!" />
    );

  // Ticket #3412 work-around
  const clientList = R.reject(item => R.isNil(item))(data?.data);
  if (clientList.length === 0) {
    return <ErrorHandler loggedMessage="[LoadingClientsPage] GET_MY_CLIENTS list is empty!" />;
  }
  const clientData = clientList[0];
  // NOTE: This use of the portfolios[0] element is OK -- it's the first time loading the
  // client so the default data there is OK
  /* eslint-disable-next-line */
  const periods = clientData?.portfolios[0]?.performanceData?.periods;
  return <DefaultPeriod clientData={clientData} periods={periods} history={history} />;
};

export default LoadingClientsPage;
