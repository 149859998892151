module.exports = {
  '@primary-color': '#E97C26',
  '@primary-navy': '#242D3B',

  '@header-text': '#FFFFFF',
  '@header-background': '#242D3B',
  '@page-color': '#FFFFFF',

  '@body-text': '#262626',
  '@body-background': '#FAFAFA',
  '@component-background': '#FFFFFF',

  '@checkbox-border': '#BFBFBF',

  '@secondary-blue': '#226EC8',
  '@secondary-green': '#7EBC48',
  '@secondary-darkgreen': '#03935F',
  '@secondary-red': '#AD0202',

  '@cash-asset-category': '#7EBC48',
  '@fixed-asset-category': '#03935F',
  '@category-blue': '#9EC9E0',
  '@balanced-asset-category': '#9EC9E0',
  '@equities-asset-category': '#273292',
  '@alternatives-asset-category': '#9E9AC7',
  '@category-red': '#8F0852',
  '@other-investments-category': '#8F0852',
  '@category-dark-pink': '#C6207D',
  '@hybrid-investments-category': '#C6207D',
  '@category-pink': '#FBA0B6',
  '@multi-asset-investments-category': '#FBA0B6',
  '@pe/vc-asset-category': '#E97C26',
  '@real-asset-category': '#FEC55A',

  '@sequential-1': '#FFFFE6',
  '@sequential-2': '#FFF7BF',
  '@sequential-3': '#FEE496',
  '@sequential-4': '#FEC55A',
  '@sequential-5': '#FE9B3A',
  '@sequential-6': '#E97C26',
  '@sequential-7': '#CC4F1A',
  '@sequential-8': '#993613',
  '@sequential-9': '#66260D',
  '@sequential-10': '#3A1C10',

  '@neutrals-1': '#FFFFFF',
  '@neutrals-2': '#FAFAFA',
  '@neutrals-3': '#F0F0F0',
  '@neutrals-4': '#D9D9D9',
  '@neutrals-5': '#BFBFBF',
  '@neutrals-6': '#8C8C8C',
  '@neutrals-7': '#595959',
  '@neutrals-8': '#434343',
  '@neutrals-9': '#262626',
  '@neutrals-10': '#000000',

  '@text-color': '#262626',
  '@benchmark1-dark-grey': '#525252',
  '@benchmark2-light-grey': '#a5a5a5'
};
