import {gql} from '@apollo/client';

const GET_MY_CLIENTS = gql`
  query GetMyClients {
    data: GetMyClients {
      id
      displayName
      # entity cannot query entity on type client!
      entitiesList {
        entity
        displayName
        ribbonName
      }
      portfolios {
        id
        displayName
        entity
        asOfDatesList {
          asOfDate
        }
        quickSummary {
          gains
          gainsPercent
          monthlyGainsPercent
          totalMarketValue
          cashAndFixedMarketValue
          cashValue
          fixedIncomeValue
          asOfDate
          assetValuationPercent
          assetValuationAsOf
          inceptionDate
          netflows
        }
        performanceData {
          periods {
            code
            displayName
            displayLabel
          }
        }
      }
    }
  }
`;

const GET_MY_CLIENTS_NAMES = gql`
  query GetMyClients {
    data: GetMyClients {
      id
      displayName
      # entity cannot query entity on type client!
      entitiesList {
        entity
        displayName
        ribbonName
      }
      portfolios {
        id
        displayName
        entity
        asOfDatesList {
          asOfDate
        }
      }
    }
  }
`;

const GET_CLIENT_PORTFOLIO = gql`
  query GetClientPortfolio($id: ID!, $portfolioId: ID!, $asOfDate: ID!) {
    data: GetClientPortfolio(id: $id, portfolioId: $portfolioId, asOfDate: $asOfDate) {
      id
      displayName
      quickSummary {
        gains
        gainsPercent
        monthlyGainsPercent
        totalMarketValue
        cashAndFixedMarketValue
        cashValue
        fixedIncomeValue
        asOfDate
        assetValuationPercent
        assetValuationAsOf
        inceptionDate
        netflows
      }
      performanceData {
        periods {
          code
          displayName
        }
      }
      allocation {
        summary {
          assets {
            assetClass
            allocation
          }
        }
      }
      aacrBenchmarks {
        returns {
          displayName
          code
          portfolioPercent
          benchmarks {
            name
            percent
          }
        }
      }
    }
  }
`;

const GET_CLIENT = gql`
  query GetClient($id: ID!, $asOfDate: ID!) {
    data: GetClient(id: $id, asOfDate: $asOfDate) {
      displayName
      portfolios {
        id
        displayName
        asOfDatesList {
          asOfDate
        }
        quickSummary {
          gains
          gains
          gainsPercent
          monthlyGainsPercent
          totalMarketValue
          cashAndFixedMarketValue
          cashValue
          fixedIncomeValue
          asOfDate
          assetValuationPercent
          assetValuationAsOf
          inceptionDate
          netflows
        }
        performanceData {
          periods {
            code
            displayName
          }
        }
        allocation {
          summary {
            assets {
              assetClass
              allocation
            }
          }
        }
        aacrBenchmarks {
          returns {
            displayName
            code
            benchmarkPercent
            portfolioPercent
            benchmarkName
          }
        }
      }
    }
  }
`;

export {GET_MY_CLIENTS, GET_MY_CLIENTS_NAMES, GET_CLIENT, GET_CLIENT_PORTFOLIO};
