import styled from '@emotion/styled';

import * as COLORS from '../theme/colors';

export const StyledBody = styled.div`
  background-color: ${COLORS['@body-background']};
  color: ${COLORS['@body-text']};
`;

export const StyledHeader = styled.div`
  background-color: ${COLORS['@header-background']};
  color: ${COLORS['@header-text']};
  position: sticky;
  width: 100%;
  z-index: 11;
  top: 0;
`;

export const StyledDivider = styled.hr`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 5px 20px;
`;

export const StyledPeriodsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  height: 41px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: ${COLORS['@header-background']};
  & .period-item {
    flex-grow: 1;
  }
`;

export const StyledAsOfDatesMenuContainer = styled.div`
  width: 150px;
  & #multi-period-select.multi-period-select {
  font-size: 13px !important;
    color: white !important;
    & a {
    color: white !important;
  }
`;
