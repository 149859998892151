import React from 'react';
import * as R from 'ramda';
import {Spin, Alert} from 'antd';

import {clientInfoEmptyState} from '../context/referenceConstants'; /* eslint-disable-line import/no-cycle */
import {appActionTypes} from '../context/AppContext'; /* eslint-disable-line import/no-cycle */
import {client} from '../graphql/queries';
import logger from '../logger';

/**
 * @function refetchMyQueries
 * Supports various refetch options & logs result
 * @rg {object} options - various options to use
 *  option: 'all' - refetch all
 *  option: 'active' = refetch inactive
 *  option: 'evict' - refetch evict(fieldName: evict)
 *  option: method - text identifier for logger entry
 * @returns refetchResults
 */
export const refetchMyQueries = async ({method, option, evict}) => {
  let payload;
  switch (option) {
    case 'inactive':
      payload = {include: 'inactive'};
      break;
    case 'all':
      payload = {include: 'all'};
      break;
    case 'evict':
      if (R.isNil(evict) || R.isEmpty(evict)) return 'evict option requires evict values';
      payload = {
        updateCache(cache) {
          cache.evict({fieldName: evict});
        }
      };
      break;
    default:
      payload = `invalid option ${option}`;
  }
  const refetchResults = await client.refetchQueries(payload);
  logger.info({
    method: method ?? 'refetchMyQueries',
    refetchResults
  });
  return refetchResults;
};

/**
 * @contact DEFAULT_PORTFOLIO
 * Set to 0 while we have only one portfolio
 * Use to identify where changes have to made in the future
 */
export const DEFAULT_PORTFOLIO = 0;

/**
 * @function tapLog
 *  Convenience function to tap & console.log Ramda composed elements
 * @arg {string} title - text to display on the console.log
 * @arg {object} implicitElement - the implicit/Ramda-compose input to the function
 * @returns {object} implicitElement - the same object that was received
 */
/* eslint-disable prettier/prettier */
export const tapLog =
  (...args) =>
  data => {
    console.log.apply(null, args.concat([data])); /* eslint-disable-line no-console */
    return data;
  };
/* eslint-enable prettier/prettier */

/**
 * @function graphqlErrorHandler
 *  If graphql error, display spinner & alert message
 *  Internal function: testAndReturnString
 * @arg {object} error - returned error object from useQuery()
 * @returns {JSX} - Spinner/alert mesasge
 */
const testAndReturnString = value => {
  if (typeof value === 'string') return value;
  if (typeof value === 'object' && !R.isNil(value.message) && typeof value.message === 'string')
    return value.message;
  return JSON.stringify(value);
};
export const graphqlErrorHandler = error => {
  const errorMessage = testAndReturnString(error);
  return (
    <Spin
      className="graphql-loading"
      data-testid="graphql-error-handler"
      aria-label="graphql-error"
    >
      <Alert message={errorMessage} />
    </Spin>
  );
};

/**
 * @function graphqlLoadingHandler
 *  When graphql loading, display spinner
 * @arg {string} loadingMessage; defaults to "Loading..."
 * @returns {JSX} Spinner with tip
 */
export const graphqlLoadingHandler = (message = 'Loading...') => {
  // debugger; /* eslint-disable-line */
  return (
    <div
      className="graphql-loading"
      data-testid="graphql-loading-handler"
      aria-label="graphql-loading"
    >
      <Spin className="graphql-loading" aria-label="graphql-loading" tip={message} />
    </div>
  );
};

/**
 * @function graphqlNoDataHandler
 *  when graphql no data returned, display spinner
 * @arg {string} message; defaults to "No data returned..."
 * @returns {JSX} spinner with tip
 */
export const graphqlNoDataHandler = (message = 'No data returned...') => {
  return <Spin aria-label="graphql-no-data" tip={message} />;
};

/**
 * @function getInceptionDateFromClientData(clientData)
 * @function getDefaultPortfolioIdFromClientData(clientData)
 * @function getDefaultEntityFromClientData(clientData)
 * @params {object} clientData - a single client data object as retrieved from gQL
 * @returns {string} portfolioId - first portfolio id
 */
export const getDefaultPortfolioIdFromClientData = clientData => {
  const portfolioIndex = R.findIndex(
    portfolio => R.prop('entity', portfolio) === clientData.entitiesList[0].entity,
    clientData.portfolios
  );
  return portfolioIndex >= 0
    ? clientData.portfolios[portfolioIndex].id
    : clientData.portfolios[DEFAULT_PORTFOLIO].id;
};

export const getDefaultEntityFromClientData = clientData => clientData.entitiesList[0].entity;
export const getInceptionDateFromClientData = clientData =>
  clientData.portfolios[DEFAULT_PORTFOLIO].clientSummary.inceptionDate;

/**
 * @function getInceptionDate(state)
 *  Get inceptionDate for selected portfolio
 * TODO: Review/change for Multi-Entities.
 *
 * @params {object} state - context state
 * @returns {Date} inceptionDate
 */
export const getInceptionDate = state =>
  state.clientInfo.portfolios[DEFAULT_PORTFOLIO].clientSummary.inceptionDate;

/**
 * @function setAsOfDateData
 *  sets asOfData and asOfDatesList state fields
 * If the clientData contains these fields we use those values;
 * Else we throw an error as there is an API issue
 *
 * NB: This logic must change when we support multiple portfolios
 *
 * @params {object} _clientData - from the getMyClients query
 * @params {object} _dispatch -- eponymous state dispatcher
 */
export const setAsOfDateData = (_clientData, _dispatch) => {
  const asOfDatesList =
    R.isEmpty(_clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList) ||
    R.isNil(_clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList)
      ? () => {
          console.log('ERROR: No asOfDatesList from graphQL'); /* eslint-disable-line no-console */
          return [{asOfDate: _clientData.portfolios[DEFAULT_PORTFOLIO].quickSummary.asOfDate}];
        }
      : _clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList;
  const asOfDate = R.isEmpty(asOfDatesList)
    ? _clientData.portfolios[DEFAULT_PORTFOLIO].quickSummary.asOfDate
    : _clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList[0].asOfDate;
  if (R.isEmpty(asOfDatesList)) asOfDatesList.push({asOfDate});
  _dispatch({
    type: appActionTypes.SET_SELECTED_ASOFDATE,
    payload: asOfDate
  });
  // asOfDatesList copied to clientInfo for ease of access
  logger.trace({
    method: 'utilHelpers:setAsOfDateData',
    asOfDatesList,
    payload: R.pluck('asOfDate', asOfDatesList)
  });
  _dispatch({
    type: appActionTypes.SET_ASOFDATES_LIST,
    payload: R.pluck('asOfDate', asOfDatesList)
  });
};

/**
 * @function getStack
 * @returns {object} current stack trace
 */
export const getStack = () => {
  try {
    throw new Error();
  } catch (e) {
    return e.stack;
  }
};

/**
 * @function getEntitiesList
 * @param {object} portfolios
 */
export const getEntitiesList = _portfolios => {
  const entitiesList = R.map(portfolio => {
    return {
      entity: portfolio.id.split(':')[0],
      displayName: portfolio.displayName
    };
  })(_portfolios);
  return entitiesList;
};

/**
 * @function setNewEntity (entity/group)
 *  Given a new entity, update state and ensure graphQL is refreshed
 * @param {object} entityData - entity, displayName, portfolioIndex for starters
 * @param {function} dispatch - eponymous dispatcher of state
 */
export const setNewEntity = (entityData, dispatch) => {
  logger.debug({
    method: 'setNewEntity',
    entityData
  });
  dispatch({
    type: appActionTypes.SET_NEW_ENTITY,
    payload: entityData
  });
};

/**
 * @function setLoadingCountHeader (entity/group)
 * @param {object} countIncrease - either 1 or -1 depending on whether a query has started or completed
 * @param {function} dispatch - eponymous dispatcher of state
 */
export const setLoadingCountHeader = (countIncrease, dispatch) => {
  logger.debug({
    method: 'setLoadingCountHeader',
    countIncrease
  });
  dispatch({
    type: appActionTypes.SET_LOADING_COUNT_HEADER,
    payload: countIncrease
  });
};

/**
 * @function setLoadingCountCoverPage (entity/group)
 * @param {object} countIncrease - either 1 or -1 depending on whether a query has started or completed
 * @param {function} dispatch - eponymous dispatcher of state
 */
export const setLoadingCountCoverPage = (countIncrease, dispatch) => {
  logger.debug({
    method: 'setLoadingCountCoverPage',
    countIncrease
  });
  dispatch({
    type: appActionTypes.SET_LOADING_COUNT_COVER_PAGE,
    payload: countIncrease
  });
};

/**
 * @function setLoadingCountPortfolioPage (entity/group)
 * @param {object} countIncrease - either 1 or -1 depending on whether a query has started or completed
 * @param {function} dispatch - eponymous dispatcher of state
 */
export const setLoadingCountPortfolioPage = (countIncrease, dispatch) => {
  logger.debug({
    method: 'setLoadingCountPortfolioPage',
    countIncrease
  });
  dispatch({
    type: appActionTypes.SET_LOADING_COUNT_PORTFOLIO_PAGE,
    payload: countIncrease
  });
};

/**
 * @function setLoadingCountAssetDetailsPage (entity/group)
 * @param {object} countIncrease - either 1 or -1 depending on whether a query has started or completed
 * @param {function} dispatch - eponymous dispatcher of state
 */
export const setLoadingCountAssetDetailsPage = (countIncrease, dispatch) => {
  logger.debug({
    method: 'setLoadingCountAssetDetailsPage',
    countIncrease
  });
  dispatch({
    type: appActionTypes.SET_LOADING_COUNT_ASSET_DETAILS_PAGE,
    payload: countIncrease
  });
};

/**
 * @function setNewClient
 *  Given new client data, initialize App State/clientInfo, including default activePeriod
 * @param {object} clientData - client info (returned from GET_MY_CLIENTS)
 * @param {function} dispatch - dispatch function from Context
 *
 * NOTE: We CAN use DEFAULT_PORTFOLIO here since we are loading a client for the first time
 *       and then the default is the first portfolio (i.e., [0])
 * This could change in the future if we are required to save user's "last view"...
 */
export const setNewClient = (clientData, dispatch) => {
  const newClientInfo = clientInfoEmptyState;
  newClientInfo.id = clientData.id;
  newClientInfo.displayName = clientData.displayName;
  newClientInfo.entity = getDefaultEntityFromClientData(clientData);
  newClientInfo.selectedPortfolioId = getDefaultPortfolioIdFromClientData(clientData);
  newClientInfo.selectedClientId = clientData.id;
  newClientInfo.portfolios = clientData.portfolios;
  newClientInfo.version = '0.6.0'; // Updated for ticket #3649
  // NOTE: inceptionDate is returned in query: portfolios[<n>].quickSummary.inceptionDate
  dispatch({type: appActionTypes.SET_CLIENT_INFO, payload: newClientInfo});
  // NOTE: We conditionally set the active period depending on whether 1yr aacr data is available
  setAsOfDateData(clientData, dispatch);
  const find1YR = R.find(R.propEq('displayName', '1YR AACR'))(
    clientData.portfolios[DEFAULT_PORTFOLIO].performanceData.periods
  );
  // TODO Someday, we'll have to remember their previous entity? But not now...

  logger.trace({
    method: 'setNewClient',
    clientAsOfDatesList: clientData.asOfDatesList,
    portfoliosAsOfDatesList: clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList,
    selectedPortfolioId: clientData.selectedPortfolioId,
    clientData
  });

  /* eslint-disable-next-line no-unused-vars */
  const newClientData = {
    clientEntities: clientData.entitiesList,
    asOfDatesList: clientData.portfolios[DEFAULT_PORTFOLIO].asOfDatesList,
    activePeriod: find1YR ? '1YR AACR' : 'CYTD',
    cardsOrList: 'cards',
    portfolioId: newClientInfo.selectedPortfolioId,
    clientId: newClientInfo.selectedClientId,
    entityId: newClientInfo.selectedPortfolioId.split(':')[0]
  };
  dispatch({type: appActionTypes.SET_NEW_CLIENT, payload: newClientData});
};

/**
 * @function resetClientWithSameEntityAndUpdatedPortfolios
 *  Given new client data, initialize App State/clientInfo, including default activePeriod
 * @param {object} clientData - client info (returned from GET_MY_CLIENTS)
 * @param {function} dispatch - dispatch function from Context
 */
export const resetClientWithSameEntityAndUpdatedPortfolios = (clientData, dispatch) => {
  const newClientInfo = clientInfoEmptyState;
  newClientInfo.id = clientData.id;
  newClientInfo.displayName = clientData.displayName;
  newClientInfo.entity = clientData.entity;
  newClientInfo.selectedPortfolioId = clientData.selectedPortfolioId;
  newClientInfo.selectedClientId = clientData.id;
  newClientInfo.portfolios = clientData.portfolios;
  newClientInfo.version = '0.6.0'; // Updated for ticket #3649

  dispatch({type: appActionTypes.SET_CLIENT_INFO, payload: newClientInfo});

  logger.trace({
    method: 'setNewClient',
    clientData
  });

  /* eslint-disable-next-line no-unused-vars */
  const newClientData = {
    clientEntities: clientData.entitiesList,
    asOfDatesList: clientData.asOfDatesList,
    activePeriod: clientData.activePeriod,
    cardsOrList: clientData.cardsOrList,
    portfolioId: newClientInfo.selectedPortfolioId,
    clientId: newClientInfo.selectedClientId,
    entityId: newClientInfo.selectedPortfolioId.split(':')[0]
  };
  dispatch({type: appActionTypes.SET_NEW_CLIENT, payload: newClientData});
};

/**
 * @function getYAxisMinMax
 * @param {array} reportData - reportData from which Y-axis data comes from
 * @param {boolean} linegraph - determines which field to map within the reportData
 * @returns {object} = object with the max and min y-axis reportData values
 */
export const getYAxisMinMax = (reportData, type) => {
  if (type === 'capitalCalls') {
    const yAxisValues = R.map(data => data?.capitalCalls, reportData || []);
    const yAxisCapitalCallsMin = R.reduce(
      (accumulatedValue, currentValue) => {
        return Math.min(accumulatedValue, currentValue);
      },
      yAxisValues[0],
      yAxisValues
    );
    return yAxisCapitalCallsMin;
  }
  if (type === 'marketValueOverlayLine') {
    const yAxisValues = R.map(data => data?.marketValue, reportData || []);
    const marketValueYAxisMin = R.reduce(
      (accumulatedValue, currentValue) => {
        return Math.min(accumulatedValue, currentValue);
      },
      yAxisValues[0],
      yAxisValues
    );
    const marketValueYAxisMax = R.reduce(
      (accumulatedValue, currentValue) => {
        return Math.max(accumulatedValue, currentValue);
      },
      yAxisValues[0],
      yAxisValues
    );
    return {marketValueYAxisMax, marketValueYAxisMin};
  }
  const yAxisValues = R.map(
    data => (type === 'lineGraph' ? data?.value : data?.distribution),
    reportData || []
  );
  const yAxisMax = R.reduce(
    (accumulatedValue, currentValue) => {
      return Math.max(accumulatedValue, currentValue);
    },
    yAxisValues[0],
    yAxisValues
  );
  const yAxisMin = R.reduce(
    (accumulatedValue, currentValue) => {
      return Math.min(accumulatedValue, currentValue);
    },
    yAxisValues[0],
    yAxisValues
  );
  return {yAxisMax, yAxisMin};
};

/**
 * @function holdingsAreGreaterThanOneYearOld
 * @function holdingsAreGreaterThanPeriodYearOld
 *  Determine if any subType2/holdings are more than (one/period) years old
 *
 * @param {array} subType2s -- the subType2/holdings array
 * @param {String} period -- the period string; e.g., 'CYTD', '1YR AACR', ...
 * @returns {boolean} true/false depending on inceptionDate of holdings
 * @note the "one-year-prior-date" used is a rough approximation
 *       does not account for leap years
 */

// Rough approximation; doesn't include leap years for now
const ONE_LEAP_DAY = 24 * 60 * 60 * 1000;
const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;
const ONE_YEAR_PRIOR_DATE = Date.now() - ONE_YEAR;

const PRIOR_DATES = [];
PRIOR_DATES[1] = Date.now() - ONE_YEAR;
PRIOR_DATES[3] = Date.now() - ONE_YEAR * 3;
PRIOR_DATES[5] = Date.now() - (ONE_YEAR * 5 + ONE_LEAP_DAY);
PRIOR_DATES[7] = Date.now() - (ONE_YEAR * 7 + ONE_LEAP_DAY * 2);
PRIOR_DATES[10] = Date.now() - (ONE_YEAR * 10 + ONE_LEAP_DAY * 2);
PRIOR_DATES[20] = Date.now() - (ONE_YEAR * 20 + ONE_LEAP_DAY * 5);

export const convertPeriodToYears = _period => {
  if (_period === 'ITD') return 20;
  const periodYears = parseInt(_period, 10);
  if (isNaN(periodYears)) return 1;
  return periodYears;
};

export const holdingsAreGreaterThanPeriodYearsOld = (_subType2s, _period) => {
  if (R.isNil(_subType2s) || R.isEmpty(_subType2s)) return false;

  const periodYears = convertPeriodToYears(_period);
  return R.compose(
    () => R.__ < PRIOR_DATES[periodYears], // comparing epoch dates
    R.reduce(R.min, Infinity),
    tapLog('log.inceptionDate'),
    R.pluck('inceptionDate'),
    tapLog('log.subType2s')
  )(_subType2s);
};

export const holdingsAreGreaterThanOneYearOld = _subType2s => {
  if (R.isNil(_subType2s) || R.isEmpty(_subType2s)) return false;

  return R.compose(
    () => R.__ < ONE_YEAR_PRIOR_DATE, // comparing epoch dates
    R.reduce(R.min, Infinity),
    R.pluck('inceptionDate')
  )(_subType2s);
};

/** isAllowableMgetErrors
 * Are the graphQL errors all the "OK" ERR... on 'mget'?
 * This is to support bypassing null client entries
 * @param {string} message - the graphQL error message(s)
 * @return {boolean} - if all the 'mget' error, then true (allowable)
 */
const MGET_MESSAGE = "ERR wrong number of arguments for 'mget' command";
export const isAllowableMgetErrors = message => {
  if (R.isNil(message) || R.isEmpty(message)) return true;
  const errorResult = R.reject(msg => msg === MGET_MESSAGE)(message.split('\n'));
  return errorResult.length === 0;
};

/**
 * @function abbrevPeriodLabels
 * @param {string} timePeriod - the active time period selected
 * @returns {string} - the abbreviated label to be displayed for each time period
 */
const periodLabels = {
  '1YR AACR': '1YR',
  '3YR AACR': '3YR',
  '5YR AACR': '5YR',
  '7YR AACR': '7YR',
  '10YR AACR': '10YR',
  '20YR AACR': '20YR'
};
export const abbrevPeriodLabels = displayName => {
  if (periodLabels[displayName]) return periodLabels[displayName];
  return displayName;
};

/** jsDateToEpochTime
 * Convert a JavaScript DateTime to Eppch Time
 * @args {javaScriptTime} - jsDate
 * @returns {epochTime} - epoch time
 */
export const jsDateToEpochTime = jsDate => {
  return Math.floor(jsDate / 1000);
};
