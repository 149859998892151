import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Label,
  Text
} from 'recharts';
import * as COLORS from '../../../theme/colors';
import {formatYAxisValueDistCC, formatYAxisValue} from '../../../utils';
import CustomizedAxisTick from '../CustomRechartsComponents/CustomizedAxisTick';
import CustomizedDot from '../CustomRechartsComponents/CustomizedDot';
import CustomizedActiveDot from '../CustomRechartsComponents/CustomizedActiveDot';
import CustomToolTip from '../CustomRechartsComponents/CustomToolTip';
import CustomAssetCardToolTip from '../CustomRechartsComponents/CustomAssetCardToolTip';
import {getYAxisMinMax} from '../../../utils/utilHelpers';

const StyledLegend = styled.span`
  margin-right: 25px;
  margin-left: 7px;
  color: ${COLORS['@neutrals-9']};
`;

/**
 * @arg: type = assetCard-graph, hero-graph
 * assetCard-graph:
 * hero-graph:
 * @arg: testingWidth: {integer} - sets REsponsiveContainer width; see
 * https://stackoverflow.com/questions/60215153/when-mounted-in-enzyme-why-does-a-recharts-chart-with-a-responsivecontainer-not
 */

const DistCCGraph = ({reportData, testingWidth, type, displayMarketValue, period}) => {
  const [graphType, setGraphType] = useState(null);
  const [toolTipYCoordinate, setToolTipYCoordinate] = useState(50);
  const [toolTipLeftRenderOffset, setToolTipLeftRenderOffset] = useState(285);
  const [dotXCoordinate, setDotXCoordinate] = useState(50);
  const [numOfPoints, setNumOfPoints] = useState(10);
  const [activeIndex, setActiveIndex] = useState(10);
  const [assetActivity, setAssetActivity] = useState(true);

  useEffect(() => {
    const findActivity = () => {
      let activity = false;
      for (let i = 0; i < reportData.length; i++) {
        if (reportData[i].distribution !== 0 || reportData[i].netFlows !== 0) {
          activity = true;
          break;
        }
      }
      return activity;
    };

    const assetCardGraph = {
      responsiveContainerHeight: 200,
      responsiveContainerWidth: testingWidth,
      chartHeight: 200,
      chartWidth: 360,
      chartMargin: {bottom: 10, top: 30, left: 10, right: 10},
      xAxisTickMargin: 11,
      xAxisTickSize: 10,
      xAxisPadding: {left: 5, right: 10},
      xAxisTickLine: false,
      displayXAxisLabel: true,
      yAxisTickCount: 5,
      yAxisTickMargin: 5,
      yAxisDataKey: '',
      displayLegend: false,
      displayAssetCardToolTip: true,
      itdQuarter: reportData[0]?.toolTipLabel?.includes('Q')
    };

    const heroGraph = {
      responsiveContainerHeight: 380,
      responsiveContainerWidth: testingWidth,
      chartHeight: 380,
      chartWidth: 360,
      chartMargin: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 16
      },
      xAxisTickMargin: 15,
      xAxisTickSize: 13,
      xAxisPadding: {left: 50, right: 50},
      xAxisTickLine: {stroke: COLORS['@neutrals-4']},
      displayXAxisLabel: false,
      yAxisTickCount: 11,
      yAxisTickMargin: 10,
      yAxisDataKey: 'minMax',
      displayLegend: true,
      displayAssetCardToolTip: false
    };

    setNumOfPoints(reportData.length);

    if (type === 'assetCard-graph') {
      setGraphType(assetCardGraph);
    } else {
      setGraphType(heroGraph);
    }
    if (type === 'assetCard-graph') {
      const activity = findActivity();
      if (activity === false) {
        setAssetActivity(false);
      } else if (assetActivity === false) {
        setAssetActivity(true);
      }
    }
  }, [reportData.length, reportData, type, testingWidth, assetActivity]);

  const {yAxisMax, yAxisMin} = getYAxisMinMax(reportData);
  const yAxisCapitalCallsMin = getYAxisMinMax(reportData, 'capitalCalls');

  const {marketValueYAxisMax, marketValueYAxisMin} = getYAxisMinMax(
    reportData,
    'marketValueOverlayLine'
  );

  const renderLegendText = value => {
    return <StyledLegend className="body-medium-bold">{value}</StyledLegend>;
  };

  const renderQuarterLabel = props => {
    return (
      <g>
        <Text
          x={props.viewBox.x - 32}
          y={props.viewBox.y + 21}
          fill={COLORS['@neutrals-7']}
          textAnchor="middle"
          verticalAnchor="start"
          className="label-small"
        >
          Quarter
        </Text>
        <Text
          x={props.viewBox.x - 32}
          y={props.viewBox.y + 41}
          fill={COLORS['@neutrals-7']}
          textAnchor="middle"
          verticalAnchor="start"
          className="label-small"
          style={{fontSize: '10'}}
        >
          (* = QTD)
        </Text>
      </g>
    );
  };

  const lengendPayload = [
    {value: 'Distributions', type: 'square', color: COLORS['@secondary-blue']},
    {value: 'Capital Calls', type: 'square', color: COLORS['@category-blue']},
    {value: 'Net Flows', type: 'circle', color: COLORS['@neutrals-9']}
  ];

  const lengendPayloadWithMV = [
    {value: 'Distributions', type: 'square', color: COLORS['@secondary-blue']},
    {value: 'Capital Calls', type: 'square', color: COLORS['@category-blue']},
    {value: 'Net Flows', type: 'circle', color: COLORS['@neutrals-9']},
    {
      value: 'Market Value',
      type: 'plainline',
      color: COLORS['@primary-color'],
      payload: {strokeDasharray: 0}
    }
  ];

  return graphType === null ? (
    false
  ) : (
    <ResponsiveContainer
      width={graphType.responsiveContainerWidth}
      height={graphType.responsiveContainerHeight}
    >
      <ComposedChart
        width={graphType.chartWidth}
        height={graphType.chartHeight}
        data={reportData}
        stackOffset="sign"
        margin={graphType.chartMargin}
        barCategoryGap="35%"
        barGap={10}
        maxBarSize={200}
      >
        <CartesianGrid vertical={false} stroke={COLORS['neutrals-4']} fill={COLORS['neutrals-4']} />
        <XAxis
          dataKey="date"
          axisLine={{stroke: COLORS['neutrals-1']}}
          tickLine={graphType.xAxisTickLine}
          /* eslint-disable */
          tick={
            <CustomizedAxisTick
              numOfPoints={numOfPoints}
              type={type}
              period={period}
              itdQuarterly={graphType.itdQuarter}
            />
          }
          /* eslint-enable */
          tickMargin={graphType.xAxisTickMargin}
          tickSize={graphType.xAxisTickSize}
          padding={graphType.xAxisPadding}
          interval={0}
          height={50}
        >
          {(graphType.displayXAxisLabel && period === '3YR AACR') ||
          (graphType.displayXAxisLabel && period === '5YR AACR') ||
          (graphType.displayXAxisLabel && period === 'ITD' && graphType.itdQuarter) ? (
            <Label
              className="label-small"
              value="Quarter (* = QTD)"
              content={renderQuarterLabel}
              style={{fill: '#595959'}}
            />
          ) : null}
        </XAxis>
        <YAxis
          dataKey={graphType.yAxisDataKey}
          orientation="left"
          yAxisId="left"
          type="number"
          hide={false}
          tickCount={graphType.yAxisTickCount}
          axisLine={false}
          tickLine={false}
          tickFormatter={tick => {
            return formatYAxisValueDistCC(
              tick,
              yAxisMax,
              yAxisMin,
              yAxisCapitalCallsMin,
              assetActivity
            );
          }}
          tickMargin={graphType.yAxisTickMargin}
          tick={{stroke: COLORS['@neutrals-7'], fontSize: 13, fontWeight: 400, letterSpacing: 1}}
        />
        {displayMarketValue ? (
          <YAxis
            dataKey="marketValue"
            orientation="right"
            yAxisId="right"
            type="number"
            hide={false}
            tickCount={graphType.yAxisTickCount}
            axisLine={false}
            tickLine={false}
            tickFormatter={tick => {
              return formatYAxisValue(tick, marketValueYAxisMax, marketValueYAxisMin);
            }}
            tickMargin={graphType.yAxisTickMargin}
            tick={{stroke: COLORS['@neutrals-7'], fontSize: 13, fontWeight: 400, letterSpacing: 1}}
            domain={['auto', 'dataMax + 10000']}
          />
        ) : null}
        {graphType.displayAssetCardToolTip ? (
          <Tooltip
            content={payload => {
              return <CustomAssetCardToolTip payload={payload} />;
            }}
            isAnimationActive={false}
          />
        ) : (
          <Tooltip
            cursor={false}
            content={payload => {
              return (
                <CustomToolTip
                  payload={payload}
                  numOfPoints={numOfPoints}
                  activeIndex={activeIndex}
                  toolTipLeftRenderOffset={toolTipLeftRenderOffset}
                  setToolTipLeftRenderOffset={setToolTipLeftRenderOffset}
                />
              );
            }}
            isAnimationActive={false}
            position={{
              x:
                activeIndex > numOfPoints / 2
                  ? dotXCoordinate - toolTipLeftRenderOffset
                  : dotXCoordinate + 10,
              y: toolTipYCoordinate - 40
            }}
          />
        )}
        {graphType.displayLegend ? (
          <Legend
            iconSize={12}
            payload={displayMarketValue ? lengendPayloadWithMV : lengendPayload}
            formatter={renderLegendText}
            wrapperStyle={{bottom: '-15px'}}
          />
        ) : null}
        <Bar
          name="Distributions"
          dataKey="distribution"
          fill={COLORS['@secondary-blue']}
          stackId="stack"
          yAxisId="left"
        />
        <Bar
          name="Capital Calls"
          dataKey="capitalCalls"
          fill={COLORS['@category-blue']}
          stackId="stack"
          yAxisId="left"
        />
        <Line
          name="Net Flows"
          dataKey="netFlows"
          strokeWidth={0}
          isAnimationActive={false}
          dot={<CustomizedDot graphType={type} />}
          activeDot={payload => {
            return (
              <CustomizedActiveDot
                payload={payload}
                toolTipYCoordinate={toolTipYCoordinate}
                setToolTipYCoordinate={setToolTipYCoordinate}
                dotXCoordinate={dotXCoordinate}
                setDotXCoordinate={setDotXCoordinate}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                graphType={type}
              />
            );
          }}
          legendType="circle"
          yAxisId="left"
        />
        {displayMarketValue ? (
          <Line
            name="Market Value"
            dataKey="marketValue"
            dot={false}
            stroke={COLORS['@primary-color']}
            strokeWidth={2}
            activeDot={false}
            yAxisId="right"
            // isAnimationActive={false}
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

DistCCGraph.propTypes = {
  reportData: PropTypes.array,
  testingWidth: PropTypes.number,
  type: PropTypes.string,
  displayMarketValue: PropTypes.bool,
  period: PropTypes.string
};

export default DistCCGraph;
