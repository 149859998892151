import React, {useState, useContext, useEffect} from 'react';
import {UserOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import * as R from 'ramda';

import * as COLORS from '../../theme/colors';
import Logo from '../../images/HallLogo-1.png';
import TopMenu from '../TopMenu';
import {GET_MY_CLIENTS_NAMES} from '../../graphql/queries';
import useWindowDimensions from '../../hooks/useWindowSize';
// import {GraphqlLoadingHandler} from '../graphqlHandlers';
import ClientSelectionMenu from '../ClientSelectionMenu';
import {AppContext, appActionTypes} from '../../context/AppContext'; /* eslint-disable-line */
import {setRenderError, NA} from '../../utils/errorHelpers';
import logger from '../../logger';
import {setLoadingCountHeader} from '../../utils/utilHelpers';
import HeaderWithGetMyClientsQueryCall from './HeaderWithGetMyClientsQueryCall';

const StyledClientInfo = styled.div`
  margin-top: 3px;
  position: relative;
  display: inline-block;
  float: right;
  height: 22px;
`;

const StyledClientName = styled.div`
  padding-right: 6px;
  position: static;
  align-items: center;
  display: inline-block;
  font-family: Playfair Display;
  font-size: 19px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.15);
`;

const StyledRightHeader = styled.div`
  display: flex;
  margin-left: 40px;
  padding: 0;
  text-align: left;
  &:hover {
    background-color: white;
    color: ${COLORS['@primary-navy']};
  }
  &:hover .client-name {
    color: ${COLORS['@primary-navy']};
  }
  & .ant-popover-inner-content {
    padding: 0 !important;
    color: ${COLORS['@primary-navy']} !important;
    background-color: white !important;
  }
`;
const StyledRightHeaderFiller = styled.div`
  width: 20px;
`;

const StyledPortfolioName = styled.div`
  margin-top: 25px;
  margin-right: 40px;
  white-space: nowrap;
`;

const StyledClientIcon = styled(UserOutlined)`
  margin-top: -6px;
  vertical-align: text-top;
  width: 24px;
  height: 21.32px;
  font-size: 24px;
  color: ${COLORS['@primary-color']};
`;

const StyledLeftHeaderFormatting = styled.div`
  margin-top: 10px;
  float: left;
`;

const StyledHeaderContainer = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS['@header-background']};
  color: ${COLORS['@header-text']};
  & .header-left__logo {
    margin-left: 20px;
  }
`;

const StyledTopMenu = styled(TopMenu)`
  margin-top: 5px;
`;

const Header = () => {
  const {state, dispatch} = useContext(AppContext); /* eslint-disable-line */
  const {selectedAsOfDate, selectedClientId} = state.clientInfo;

  if (state.clientInfo?.clientInfo) {
    dispatch({type: appActionTypes.RESET_STATE});
  }
  const dimensions = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const setNotVisible = () => {
    setVisible(false);
  };
  const renderHeader = (portfolioName, clientName, _visible, _setNotVisible) => {
    return (
      <StyledHeaderContainer className="header">
        <StyledLeftHeaderFormatting className="header-left">
          <img className="header-left__logo" src={Logo} alt="Hall Logo" />
          <StyledTopMenu className="header-left__top-menu" />
        </StyledLeftHeaderFormatting>
        <StyledRightHeader
          className="header-right client-select"
          onClick={() => {
            setVisible(!_visible);
            setRenderError(dispatch, false);
          }}
        >
          <StyledRightHeaderFiller />
          <>
            {dimensions.width < 750 ? null : (
              <StyledPortfolioName className="portfolio-name body-medium">
                {portfolioName}
              </StyledPortfolioName>
            )}
          </>
          <>
            {_visible ? (
              <ClientSelectionMenu isVisible={_visible} setNotVisible={_setNotVisible} />
            ) : null}
            <StyledClientInfo className="client-info">
              <StyledClientName className="client-name">{clientName}</StyledClientName>
              {'  '}
              <StyledClientIcon className="client-icon" />
            </StyledClientInfo>
          </>
          <StyledRightHeaderFiller />
        </StyledRightHeader>
      </StyledHeaderContainer>
    );
  };

  useEffect(() => {
    setLoadingCountHeader({key: 'Header/index/GET_MY_CLIENTS', value: 0}, dispatch);
  }, [dispatch]);

  const {loading, error, data} = useQuery(GET_MY_CLIENTS_NAMES, {
    returnPartialData: true,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {id: selectedClientId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountHeader({key: 'Header/index/GET_MY_CLIENTS_NAMES', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountHeader({key: 'Header/index/GET_MY_CLIENTS_NAMES', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    const loadingPortfolioIndex = R.findIndex(
      portfolio => R.prop('entity', portfolio) === state.selectedEntityId,
      state.clientInfo?.portfolios || []
    );
    const dummyPortfolioName =
      state.clientInfo?.portfolios[loadingPortfolioIndex]?.displayName || 'Loading...';
    const dummyClientName = selectedClientId || '...';
    return renderHeader(dummyPortfolioName, dummyClientName, false, () => {});
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    logger.error({method: 'GET_MY_CLIENTS_NAMES query error :', message: error.message});
  }

  if (R.isNil(data?.data) || R.isEmpty(data?.data)) {
    /* eslint-disable-next-line no-console */
    logger.error({method: 'GET_MY_CLIENTS_NAMES query error', message: 'data is empty:'});
  }

  const queryData = data?.data || [];

  const clientIndex = R.findIndex(client => client.id === selectedClientId, queryData);
  if (clientIndex < 0) {
    logger.error({method: 'Header', message: 'clientIndex is -1'});
  }
  const clientName = queryData[clientIndex]?.displayName || NA;
  const entitiesListIndex = R.findIndex(
    portfolio => R.prop('entity', portfolio) === state.selectedEntityId,
    queryData[clientIndex]?.entitiesList || []
  );
  if (entitiesListIndex < 0) {
    logger.error({
      method: 'Header/entitiesListIndex',
      entitiesListIndex,
      clientIndex,
      clientName,
      queryData,
      portfolios: queryData[clientIndex]?.portfolios,
      selectedEntityId: state.selectedEntityId,
      portfolioCount: state.clientInfo.portfolios.length
    });
  }

  const entitiesList = queryData[clientIndex]?.entitiesList;

  if (!R.equals(state.entitiesList, entitiesList)) {
    return (
      <HeaderWithGetMyClientsQueryCall
        renderHeader={renderHeader}
        clientName={clientName}
        entitiesList={entitiesList}
        visible={visible}
        setNotVisible={setNotVisible}
        selectedEntityId={state.selectedEntityId}
        selectedPortfolioId={state.selectedPortfolioId}
        asOfDatesList={state.asOfDatesList}
      />
    );
  }

  const portfolioName = queryData[clientIndex]?.entitiesList[entitiesListIndex]?.displayName || NA;

  return renderHeader(portfolioName, clientName, visible, setNotVisible);
};

export default Header;
