import {useState, useEffect} from 'react';

function getScrollWidths() {
  const elementByClass = document.getElementsByClassName('entityribbon-menu') || [];
  const element = elementByClass[0];
  return {scrollWidth: element?.scrollWidth, clientWidth: element?.clientWidth};
}

export default function useScrollWidth() {
  const [scrollWidths, setscrollWidths] = useState(getScrollWidths());

  useEffect(() => {
    function handleResize() {
      setscrollWidths(getScrollWidths());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return scrollWidths;
}
