import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {ResponsiveContainer, BarChart, Bar, Tooltip, Legend, XAxis, YAxis} from 'recharts';

import {getTextWidth, formatDollar} from '../../utils';
import {assetClassProperties, assetClassOrder} from '../../utils/assetClassProperties';
import * as COLORS from '../../theme/colors';
import CustomLegend from '../CustomLegend';
import logger from '../../logger'; /* eslint-disable-line */

const CurrentAssetAllocationBarGraph = ({marketValueData, data, testingWidth}) => {
  const [hoverClass, setHoverClass] = useState(null);
  const mapIndexed = R.addIndex(R.map);

  // Variables for working with the chart legend popup
  const tooltip = useRef(null);
  const tooltipName = useRef(null);
  const tooltipAllocation = useRef(null);

  const legendFontSize = 13;
  const legendFontWeight = 700;
  const legendStyle = {fontSize: legendFontSize, fontWeight: legendFontWeight};
  const legendWidth = 96;

  // We should be able to center these elements with CSS, but with SVG,
  // things don't work quite as expected. This sorta works, hacky as it is!
  /* eslint-disable-next-line */
  const centerTextByPadding = (text, width = legendWidth) => {
    const textWidth = getTextWidth(text, legendFontSize.toString());
    const nbsp = ' '; // assume simple space and &nbsp; are the same width
    const nbspWidth = getTextWidth(nbsp, legendFontSize.toString());
    const textPadding = (width - textWidth) / 2 / nbspWidth;
    const paddedText = `${'&nbsp;'.repeat(textPadding)}${text}`;
    return paddedText;
  };

  // Commas take up much less space than do most other characters...
  // Therefore, we remove one &nbsp; sequence for each comma
  const removeLeadingNbspCharacters = formattedString => {
    // Return immediately if no leading &nbsp; characters
    const matchNbsp = formattedString.match(/&nbsp;/g);
    if (R.isNil(matchNbsp)) return formattedString;
    const nbrSplits = matchNbsp.length;
    // return immediately if no commas in the string
    const matchCommas = formattedString.match(/,/g);
    if (R.isNil(matchCommas)) return formattedString;
    const nbrCommas = matchCommas.length;
    // Remove up to nbrCommas &nbsp; sequences
    return formattedString.slice(6 * (nbrSplits >= nbrCommas ? nbrCommas : nbrSplits));
  };

  useEffect(() => {
    if (R.isNil(hoverClass)) {
      /* hoverClass is turned off */
    } else {
      const {_assetProps} = hoverClass;
      tooltip.current.style.opacity = 1;
      tooltipName.current.innerHTML = centerTextByPadding(_assetProps.categoryName);
      /* eslint-disable-next-line prefer-destructuring */
      const marketValue = marketValueData[_assetProps.code];
      const formattedMarketValue = formatDollar(marketValue);
      // Remove any non-breaking spaces to allow for the very narrow commas in dollar values
      tooltipAllocation.current.innerHTML = removeLeadingNbspCharacters(
        centerTextByPadding(formattedMarketValue)
      );
    }
  }, [hoverClass, marketValueData]);

  const graphData = [
    R.compose(
      R.mergeAll,
      R.map(({assetClass, allocation}) => ({[assetClass]: allocation}))
    )(data)
  ];
  const graphDataObj = graphData[0];
  const selectAssetClass = R.has(R.__, graphDataObj);
  const orderedGraphData = R.filter(selectAssetClass(R.__), assetClassOrder());
  const orderedRadiusValues =
    R.length(R.keys(graphDataObj)) === 1
      ? [10, 10, 10, 10]
      : [[10, 0, 0, 10], ...R.repeat([0, 0, 0, 0], R.length(orderedGraphData) - 2), [0, 10, 10, 0]];

  const handleMouseEnter = (_assetProps, _graphDataObj) => {
    setHoverClass({_assetProps, _graphDataObj});
  };
  const handleMouseLeave = () => {
    setHoverClass(null);
  };

  const AssetClassDetails = () => {
    return (
      <g
        className="asset-class-details"
        height="48"
        width={legendWidth}
        ref={tooltip}
        style={{opacity: 0, ...legendStyle}}
      >
        <text height="48" width={legendWidth} style={legendStyle} ref={tooltipName}>
          <tspan>PLACEHOLDER</tspan>
        </text>
        <div>
          <tspan style={legendStyle} ref={tooltipAllocation}>
            9.9%
          </tspan>
        </div>
      </g>
    );
  };
  const setOpacity = assetClassCode => {
    return R.isNil(hoverClass) || assetClassCode === hoverClass._assetProps.code ? 1 : 0.2;
  };

  return (
    <div data-testid="asset-class-performance-allocation-graph">
      <div style={{height: 25}} />
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <AssetClassDetails style={{flex: 1}} />
      </div>
      <ResponsiveContainer height={175} width={testingWidth}>
        <BarChart
          layout="vertical"
          data={graphData}
          className="barchart-base-element"
          margin={{top: 20, bottom: 60}}
          stackOffset="expand"
        >
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          {mapIndexed((assetClass, idx) => {
            const assetProps = assetClassProperties(assetClass);
            return (
              <Bar
                barSize={30}
                onMouseEnter={() => handleMouseEnter(assetProps, graphDataObj)}
                onMouseLeave={handleMouseLeave}
                key={assetClass}
                dataKey={assetClass}
                style={{opacity: setOpacity(assetProps.code)}}
                fill={assetProps.colorCode}
                radius={orderedRadiusValues[idx]}
                isAnimationActive
                cursor={COLORS['@neutrals-4']}
                stackId="asset-class"
              >
                <Tooltip cursor={false} wrapperStyle={{display: 'none'}} />
              </Bar>
            );
          })(orderedGraphData)}
          <Legend
            verticalAlign="bottom"
            iconType="circle"
            content={
              <CustomLegend direction="row" graphDataObj={graphDataObj} setOpacity={setOpacity} />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

CurrentAssetAllocationBarGraph.propTypes = {
  data: PropTypes.array,
  testingWidth: PropTypes.number,
  marketValueData: PropTypes.shape({})
};

export default CurrentAssetAllocationBarGraph;
