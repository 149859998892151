import {gql} from '@apollo/client';

/**
 * @function: GetAssetAllocationPortfolio / GET_ASSET_ALLOCATION_PORTFOLIO
 * @arg {string} client Id
 * @returns {JSON} Asset allocation data
 */

export const GET_ASSET_ALLOCATION_PORTFOLIO = gql`
  query GET_ASSET_ALLOCATION_PORTFOLIO($id: ID!, $portfolioId: ID!, $asOfDate: ID!) {
    data: GetClientPortfolio(id: $id, portfolioId: $portfolioId, asOfDate: $asOfDate) {
      id
      displayName
      allocation {
        summary {
          assets {
            assetClass
            allocation
          }
        }
      }
    }
  }
`;
