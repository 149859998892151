import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as COLORS from '../../../theme/colors';

const StyledToggleMenu = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid ${COLORS['@neutrals-4']};
  & .active {
    border-bottom: 3px solid ${COLORS['@primary-color']};
  }
`;

const StyledToggleButton = styled.div`
  cursor: pointer;
  margin-right: 30px;
  & .bold {
    font-weight: 700;
  }
`;

const ToggleMenu = ({setMenuItem, menuItem}) => {
  return (
    <StyledToggleMenu>
      <StyledToggleButton
        tabIndex="0"
        role="button"
        onClick={() => setMenuItem('asset-allocation')}
        onKeyDown={() => {}}
        className={
          menuItem === 'asset-allocation'
            ? 'active asset-allocation__menu-item'
            : 'non-active asset-allocation__menu-item'
        }
      >
        <h3
          className={
            menuItem === 'asset-allocation'
              ? 'bold asset-allocation__menu-item'
              : 'asset-allocation__menu-item'
          }
        >
          Current Asset Allocation
        </h3>
      </StyledToggleButton>
      <StyledToggleButton
        tabIndex="0"
        role="button"
        onClick={() => setMenuItem('historical-asset-allocation')}
        onKeyDown={() => {}}
        className={
          menuItem === 'historical-asset-allocation'
            ? 'active historical-asset-allocation__menu-item'
            : 'non-active historical-asset-allocation__menu-item'
        }
      >
        <h3
          className={
            menuItem === 'historical-asset-allocation'
              ? 'bold historical-asset-allocation__menu-item'
              : ' historical-asset-allocation__menu-item'
          }
        >
          Historical Asset Allocation
        </h3>
      </StyledToggleButton>
      <StyledToggleButton
        tabIndex="0"
        role="button"
        onClick={() => setMenuItem('attribution')}
        onKeyDown={() => {}}
        className={
          menuItem === 'attribution'
            ? 'active attribution__menu-item'
            : 'non-active attribution__menu-item'
        }
      >
        <h3
          className={
            menuItem === 'attribution' ? 'bold attribution__menu-item' : ' attribution__menu-item'
          }
        >
          Contribution
        </h3>
      </StyledToggleButton>
    </StyledToggleMenu>
  );
};
ToggleMenu.propTypes = {
  setMenuItem: PropTypes.func,
  menuItem: PropTypes.string
};

export default ToggleMenu;
