import {formatDollar, formatGainLoss, formatPercent, formatDate} from '../../../utils';

export const tableColumns = [
  {ellipsis: true, width: '8px', className: 'no-border detail-table-padding-cash-fixed'},
  {
    key: 'account',
    title: 'Account',
    dataIndex: 'account',
    className: 'body-large manager detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.displayName > b.displayName
    },
    render: (text, item) => item.displayName
  },
  {
    key: 'allocation',
    title: 'Allocation',
    dataIndex: 'allocation',
    className: 'body-medium detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.allocation - b.allocation
    },
    render: (text, item) => formatPercent(item.allocation)
  },
  {
    key: 'marketValue',
    title: 'Market Value',
    dataIndex: 'marketValue',
    className: 'body-medium detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.marketValue - b.marketValue
    },
    render: (text, item) => formatDollar(item.marketValue)
  },
  {
    key: 'netflows',
    title: 'Netflows',
    dataIndex: 'netflows',
    className: 'body-medium detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.netflows - b.netflows
    },
    render: (text, item) => formatDollar(item.netflows)
  },
  {
    key: 'gainLoss',
    title: 'Gain/Loss',
    dataIndex: 'gainLoss',
    className: 'body-medium detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.gainsPercent - b.gainsPercent
    },
    render: (text, item) => formatGainLoss(item.gainsPercent, item.gains, true)
  },
  {
    key: 'inceptionDate',
    title: 'Inception Date',
    dataIndex: 'inceptionDate',
    className: 'body-medium detail-table-padding-cash-fixed',
    sorter: {
      compare: (a, b) => a.inceptionDate - b.inceptionDate
    },
    render: (text, item) => formatDate(item.inceptionDate)
  },
  {ellipsis: true, width: '8px', className: 'detail-table-padding-cash-fixed'}
];
