import {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {useQuery} from '@apollo/client';

import {GET_MY_CLIENTS} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';
import {
  setLoadingCountHeader,
  setNewClient,
  resetClientWithSameEntityAndUpdatedPortfolios
} from '../../utils/utilHelpers';
import logger from '../../logger'; /* eslint-disable-line */

const HeaderWithGetMyClientsQueryCall = ({
  renderHeader,
  clientName,
  entitiesList,
  visible,
  setNotVisible,
  selectedEntityId,
  selectedPortfolioId,
  asOfDatesList
}) => {
  const {state, dispatch} = useContext(AppContext);
  const {cardsOrList, activePeriod} = state;
  const {selectedAsOfDate, selectedClientId} = state.clientInfo;
  const {client, loading, error, data} = useQuery(GET_MY_CLIENTS, {
    returnPartialData: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {id: selectedClientId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountHeader({key: 'Header/index/GET_MY_CLIENTS', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountHeader({key: 'Header/index/GET_MY_CLIENTS', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    const dummyPortfolioName = 'Loading...';
    const dummyClientName = selectedClientId || '...';
    return renderHeader(dummyPortfolioName, dummyClientName, false, () => {});
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    logger.error({method: 'GET_MY_CLIENTS:', message: error.message});
  }

  const queryData = data?.data;
  const clientData =
    clientName === 'N/A'
      ? queryData[0]
      : R.find(myClient => myClient.id === selectedClientId)(queryData);

  const findSelectedEntity = R.find(R.propEq('entity', selectedEntityId))(clientData.entitiesList);

  const resetClientWithNewSelectedPortfolio = async () => {
    setNewClient(clientData, dispatch);
    await client.resetStore();
  };

  const resetClientWithSameSelectedPortfolio = async clientDataWithSelectedPortfolio => {
    resetClientWithSameEntityAndUpdatedPortfolios(clientDataWithSelectedPortfolio, dispatch);
    await client.resetStore();
  };

  if (findSelectedEntity) {
    const clientDataWithSelectedEntity = {
      ...clientData,
      entity: selectedEntityId,
      selectedPortfolioId,
      cardsOrList,
      asOfDatesList,
      activePeriod
    };
    resetClientWithSameSelectedPortfolio(clientDataWithSelectedEntity);
  } else {
    resetClientWithNewSelectedPortfolio();
  }

  if (findSelectedEntity) {
    return renderHeader(findSelectedEntity.displayName, clientName, visible, setNotVisible);
  }

  if (clientName === 'N/A') {
    return renderHeader(
      clientData.entitiesList[0].displayName,
      clientData.displayName,
      visible,
      setNotVisible
    );
  }
  return renderHeader(entitiesList[0].displayName, clientName, visible, setNotVisible);
};

HeaderWithGetMyClientsQueryCall.propTypes = {
  renderHeader: PropTypes.func,
  clientName: PropTypes.string,
  selectedEntityId: PropTypes.string,
  selectedPortfolioId: PropTypes.string,
  asOfDatesList: PropTypes.array,
  entitiesList: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string
    })
  ),
  setNotVisible: PropTypes.func,
  visible: PropTypes.bool
};
export default HeaderWithGetMyClientsQueryCall;
