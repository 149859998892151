import React from 'react';
import ReactDOM from 'react-dom';
import {debugContextDevtool} from 'react-context-devtool';

import './index.css';

import {BrowserRouter as Router} from 'react-router-dom';

import {MsalProvider} from '@azure/msal-react';
import {EventType, PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from './msalAuthConfig';

// import App from './App';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './hooks/scrollToTop';
import ApolloConnection from './components/Apollo/apollo-connection';

export const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// // Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const {account} = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById('root');
// TODO Wrap <App> in whatever authentication service we decide to use
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      {/* <App pca={msalInstance} /> */}
      <MsalProvider instance={msalInstance}>
        <ApolloConnection />
      </MsalProvider>
    </Router>
  </React.StrictMode>,
  container
);

debugContextDevtool(container, {
  disable: process.env.NODE_ENV === 'production'
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
