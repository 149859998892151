import React, {useContext} from 'react';
import * as R from 'ramda';
import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {AppContext} from '../../../context/AppContext';
import {GET_QUICK_STATS_PORTFOLIO} from '../../../graphql/queries';
import {formatDate, formatDollar, formatPercent} from '../../../utils';
import * as COLORS from '../../../theme/colors';
import {setRenderError} from '../../../utils/errorHelpers';
import {periodDisplayLabels} from '../../../utils/periodLabels';

const StyledPortfolio = styled.div`
  padding-top: 30px;
`;

const StyledPortfolioContainer = styled.div`
  margin-top: 5px;
  display: inline-flex;
  flex-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  grid-column-gap: 50px;
`;

const StyledPortfolioComponent = styled.div`
  text-transform: uppercase;
  & .portfolio-header-gains-percent {
    width: 100%;
    text-align: center;
  }
`;

const StyledH2 = styled.h2`
  display: inline-block;
  margin-right: 10px;
`;

const StyledPortfolioComponentText = styled.div`
  margin-bottom: 47px;
  color: ${COLORS['@neutrals-7']};
`;

const PageHeader = ({
  performanceSummaryGains,
  performanceSummaryGainsPercent,
  performanceNetflows
}) => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedClientId, selectedPortfolioId, selectedAsOfDate} = state;
  const {loading, error, data} = useQuery(GET_QUICK_STATS_PORTFOLIO, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {
      id: selectedClientId,
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });
  if (loading) {
    return null;
  }
  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_QUICK_STATS_PORTFOLIO QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'portfolioPage';

  /* eslint-disable-next-line */
  if (R.isNil(data?.data))
    setRenderError(dispatch, `[${page}:PageHeader] No GET_QUICK_STATS_PORTFOLIO graphql data`);
  // if (R.isNil(data?.data?.portfolios) || R.isEmpty(data?.data?.portfolios))
  //   setRenderError(dispatch, `[${page}:PageHeader] No GET_QUICK_STATS_PORTFOLIO portfolio available`);

  // const [portfolio] = data?.data?.portfolios;
  const {quickSummary} = data.data;

  const formattedAsOfDate = formatDate(quickSummary?.asOfDate);
  const formattedAssetValuationAsOf = formatDate(quickSummary?.assetValuationAsOf);
  const formattedAssetValuationPercent = formatPercent(quickSummary?.assetValuationPercent);
  const formattedTotalMarketValue = formatDollar(quickSummary?.totalMarketValue);
  const formattedGains = formatDollar(performanceSummaryGains, true);
  const formattedGainsPercent = formatPercent(performanceSummaryGainsPercent, true);
  const formattedNetflows = formatDollar(performanceNetflows);

  return (
    <>
      <StyledPortfolio>
        <StyledPortfolioContainer>
          <StyledPortfolioComponent>
            <div>
              <div className="label-large">Total Market Value</div>
              <h2>{formattedTotalMarketValue}</h2>
            </div>
          </StyledPortfolioComponent>
          <StyledPortfolioComponent>
            <div>
              <div className="label-large">
                Performance ({periodDisplayLabels[state.activePeriod]})
              </div>
              <StyledH2
                data-testid="portfolio-performance-gains-percent"
                className="portfolio-header-gains-percent"
              >
                {formattedGainsPercent}
              </StyledH2>
            </div>
          </StyledPortfolioComponent>
          <StyledPortfolioComponent>
            <div>
              <div className="label-large">
                Gain / Loss ({periodDisplayLabels[state.activePeriod]})
              </div>
              <StyledH2>{formattedGains}</StyledH2>
            </div>
          </StyledPortfolioComponent>
          {state.activePeriod !== 'ITD' ? (
            <StyledPortfolioComponent>
              <div>
                <div className="label-large">
                  Net Flows ({periodDisplayLabels[state.activePeriod]})
                </div>
                <StyledH2>{formattedNetflows}</StyledH2>
              </div>
            </StyledPortfolioComponent>
          ) : null}
        </StyledPortfolioContainer>
        <StyledPortfolioComponentText
          data-testid="estimation-statement"
          className="body-large component-text estimation-statement"
        >
          {`Estimated as of ${formattedAsOfDate} | ${formattedAssetValuationPercent} of portfolio assets reflect valuations prior to ${formattedAssetValuationAsOf}`}
        </StyledPortfolioComponentText>
      </StyledPortfolio>
    </>
  );
};

PageHeader.propTypes = {
  performanceSummaryGains: PropTypes.number,
  performanceSummaryGainsPercent: PropTypes.number,
  performanceNetflows: PropTypes.number
};

export default PageHeader;
