import COLORS from '../theme/colors'; /* eslint-disable-line */

export const classOrder = [
  'cash',
  'fixedIncome',
  'balancedAccounts',
  'equities',
  'alternative',
  'otherInvestments',
  'hybrid',
  'multiAsset',
  'privateEquities',
  'realAssets',
  // realAsset subtypes for asset charts
  'Real Estate',
  'Hard Assets'
];

export const classLocations = {
  '/cashandfixedPage': 'Cash & Fixed',
  '/equitiesPage': 'Equities',
  '/alternativesPage': 'Alternatives',
  '/hybridinvestmentsPage': 'Hybrid Investments',
  '/privateequitiesPage': 'Private Equity / Venture Capital',
  '/balancedaccountsPage': 'Balanced Accounts',
  '/realassetsPage': 'Real Assets',
  '/otherinvestmentsPage': 'Other Investments',
  '/multiassetinvestmentsPage': 'Multi-Asset Investments'
};

export const classDisplayNames = {
  cash: 'Cash',
  fixedIncome: 'Fixed Income',
  balancedAccounts: 'Balanced Accounts',
  equities: 'Equities',
  alternative: 'Alternatives',
  otherInvestments: 'Other Investments',
  hybrid: 'Hybrid Investments',
  multiAsset: 'Multi-Asset Investments',
  privateEquities: 'Private Equity / Venture Capital',
  realAssets: 'Real Assets',
  // realAsset subtypes for asset charts
  'Real Estate': 'Real Estate',
  'Hard Assets': ' Hard Assets'
};

export const assetClassCategoryColorsTest = [
  '@cash-asset-category',
  '@fixed-asset-category',
  '@balanced-asset-category',
  '@equities-asset-category',
  '@alternatives-asset-category',
  '@other-investments-category',
  '@hybrid-investments-category',
  '@multi-asset-investments-category',
  '@pe/vc-asset-category',
  '@real-asset-category',
  '@real-asset-category',
  '@real-asset-category'
];
