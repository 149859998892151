import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ResponsiveContainer, PieChart, Pie, Cell, Legend} from 'recharts';
import * as COLORS from '../../theme/colors';
import {formatDollarsInMillionsWithDecimal, formatPercent, assetClassOrder} from '../../utils';
import {assetClassProperties} from '../../utils/assetClassProperties';
import CustomLegend from '../CustomLegend';

const DonutGraph = ({
  marketValueData,
  assetAllocationData,
  graphType,
  assetClassName,
  testingWidth
}) => {
  const [hoverClass, setHoverClass] = useState(null);

  /*
   * graph component on the cover page --> graphType = 'cover-page-donut-graph'
   * graph component on the asset cards view --> graphType = 'asset-card-donut-graph'
   * graph component on the asset page view --> graphType = 'asset-page-donut-graph'
   */

  const orderedAssetData = R.sortBy(obj => {
    return R.indexOf(obj.assetClass, assetClassOrder());
  })(
    R.map(assets => {
      return assets;
    })(assetAllocationData)
  );

  const graphData = [
    R.compose(
      R.mergeAll,
      R.map(({assetClass, allocation}) => ({[assetClass]: allocation}))
    )(orderedAssetData)
  ];
  const graphDataObj = graphData[0];

  // Variables for working with the chart legend popup
  const tooltip = useRef(null);
  const tooltipName = useRef(null);
  const tooltipAllocation = useRef(null);

  useEffect(() => {
    if (R.isNil(hoverClass)) {
      // Avoid null failure upon page refresh
      if (tooltip.current !== null) tooltip.current.style.opacity = 0;
    } else {
      const {_assetProps} = hoverClass;
      tooltip.current.style.opacity = 1;
      tooltipName.current.innerHTML = _assetProps.categoryName;
      /* eslint-disable-next-line prefer-destructuring */
      const marketValue = marketValueData[_assetProps.code].marketValue;
      const formattedMarketValue = formatDollarsInMillionsWithDecimal(marketValue);
      tooltipAllocation.current.innerHTML = formattedMarketValue;
    }
  }, [hoverClass, marketValueData]);

  const handleMouseEnter = (_assetProps, _graphDataObj) => {
    setHoverClass({_assetProps, _graphDataObj});
  };
  const handleMouseLeave = () => {
    setHoverClass(null);
  };
  const setOpacity = assetClassCode => {
    return R.isNil(hoverClass) || assetClassCode === hoverClass._assetProps.code ? 1 : 0.2;
  };

  const formatAssetCardChartLabel = value => {
    const arrayOfAssetData = [R.pick(['assetClass', 'allocation'], value)];
    const getAllocation = R.find(R.propEq('assetClass', assetClassName))(arrayOfAssetData);
    return (
      <g>
        <text
          x={200}
          y={100}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="30px"
          fontWeight="600"
          color={COLORS['@neutrals-9']}
        >
          {R.isNil(R.prop('allocation', getAllocation))
            ? ''
            : formatPercent(R.prop('allocation', getAllocation))}
        </text>
      </g>
    );
  };

  switch (graphType) {
    case 'cover-page-donut-graph':
      /*
       * #6433 issue: larger fonts in environments
       *  Reduce innerRadius by 1px
       */
      return (
        <>
          <ResponsiveContainer height={210} width={testingWidth}>
            <PieChart margin={{top: 8, bottom: 8}}>
              <text fontWeight="700" ref={tooltip} textAnchor="middle">
                <tspan x={110} y={100} ref={tooltipName} />
                <tspan x={110} y={120} ref={tooltipAllocation} />
              </text>
              <Pie
                id="assetClass"
                data={orderedAssetData}
                nameKey="assetClass"
                dataKey="allocation"
                cx={110}
                cy={95}
                outerRadius={75}
                innerRadius={46}
                midAngle={1}
                isAnimationActive={false}
                startAngle={90}
                endAngle={-360}
              >
                {orderedAssetData.map(assetData => {
                  const assetProps = assetClassProperties(assetData.assetClass);
                  return (
                    <Cell
                      onMouseEnter={() => handleMouseEnter(assetProps, graphDataObj)}
                      onMouseLeave={handleMouseLeave}
                      key={`${assetData.assetClass}`}
                      style={{opacity: setOpacity(assetProps.code)}}
                      fill={assetProps.colorCode}
                      stroke={assetProps.colorCode}
                    />
                  );
                })}
              </Pie>
              {/* eslint-disable react/jsx-wrap-multilines */}
              <Legend
                align="right"
                verticalAlign="middle"
                iconType="circle"
                iconSize="12"
                layout="vertical"
                margin={{top: 10, left: 20}}
                content={
                  <CustomLegend
                    direction="column"
                    graphDataObj={graphDataObj}
                    setOpacity={setOpacity}
                  />
                }
              />
              {/* eslint-enable react/jsx-wrap-multilines */}
            </PieChart>
          </ResponsiveContainer>
        </>
      );
    case 'asset-card-donut-graph':
      return (
        <ResponsiveContainer height={210} width={testingWidth}>
          <PieChart margin={{top: 0}}>
            <Pie
              id="assetClass"
              data={orderedAssetData}
              nameKey="assetClass"
              dataKey="allocation"
              cx={205}
              cy={95}
              outerRadius={90}
              innerRadius={60}
              labelLine={false}
              label={formatAssetCardChartLabel}
              midAngle={1}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-360}
            >
              {orderedAssetData.map(payload => {
                const assetProperties = assetClassProperties(payload.assetClass);
                return (
                  <Cell
                    key={`${payload.assetClass}`}
                    fill={
                      payload.assetClass === assetClassName
                        ? assetProperties.colorCode
                        : COLORS['@neutrals-4']
                    }
                    stroke={
                      payload.assetClass === assetClassName
                        ? assetProperties.colorCode
                        : COLORS['@neutrals-4']
                    }
                  />
                );
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    case 'asset-page-donut-graph':
      return (
        <ResponsiveContainer height={73} width={testingWidth}>
          <PieChart margin={{top: -5}}>
            <Pie
              id="assetClass"
              data={orderedAssetData}
              nameKey="assetClass"
              dataKey="allocation"
              cx={33}
              cy={37}
              outerRadius={30}
              innerRadius={20}
              labelLine={false}
              label={false}
              midAngle={1}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-360}
            >
              {orderedAssetData.map(payload => {
                const assetProperties = assetClassProperties(payload.assetClass);
                return (
                  <Cell
                    key={`${payload.assetClass}`}
                    fill={
                      payload.assetClass === assetClassName ||
                      (assetClassName === 'cash' && payload.assetClass === 'fixedIncome')
                        ? assetProperties.colorCode
                        : COLORS['@neutrals-4']
                    }
                    stroke={
                      payload.assetClass === assetClassName ||
                      (assetClassName === 'cash' && payload.assetClass === 'fixedIncome')
                        ? assetProperties.colorCode
                        : COLORS['@neutrals-4']
                    }
                  />
                );
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    default:
      return null;
  }
};

DonutGraph.propTypes = {
  assetAllocationData: PropTypes.array,
  testingWidth: PropTypes.number,
  graphType: PropTypes.string,
  assetClassName: PropTypes.string,
  marketValueData: PropTypes.shape({})
};
export default DonutGraph;
