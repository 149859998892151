import {Log, WebStorageStateStore, UserManager} from 'oidc-client';
import {oidcConfig} from '../config';

// DELETE ONCE CI/CD PIPELINE CONFIGURATION IS COMPLETE
// Custom OIDC
// const OidcConfig = {
//   stsAuthority: 'http://127.0.0.1:3001/',
//   clientId: 'auth-code-client',
//   clientRoot: process.env.REACT_APP_BASE_URL,
//   clientScope: 'openid profile email api',
//   loadUserInfo: false,
//   audience: process.env.REACT_APP_BASE_URL
// };

// // AZURE B2C PGP
// const OidcConfig = {
//   stsAuthority: 'https://winterb2cdev.b2clogin.com/winterb2cdev.onmicrosoft.com/B2C_1_Signin/v2.0/',
//   clientId: '0d820b71-8123-4ead-ad84-0c7a75c2eb22',
//   clientRoot: process.env.REACT_APP_BASE_URL,
//   clientScope: 'openid 0d820b71-8123-4ead-ad84-0c7a75c2eb22'
// };

// AZURE B2C HALL
// const OidcConfig = {
//   stsAuthority: 'https://hallb2cdev.b2clogin.com/hallb2cdev.onmicrosoft.com/B2C_1_Signin/v2.0/',
//   clientId: '0d820b71-8123-4ead-ad84-0c7a75c2eb22',
//   clientRoot: 'https://halldevgraphqlweb.z13.web.core.windows.net/',
//   clientScope: 'openid 0d820b71-8123-4ead-ad84-0c7a75c2eb22'
// };

// AZURE B2C HALL LOCALHOST
// const OidcConfig = {
//   stsAuthority: 'https://hallb2cdev.b2clogin.com/hallb2cdev.onmicrosoft.com/B2C_1_Signin/v2.0/',
//   clientId: '0d820b71-8123-4ead-ad84-0c7a75c2eb22',
//   clientRoot: process.env.REACT_APP_BASE_URL,
//   clientScope: 'openid 0d820b71-8123-4ead-ad84-0c7a75c2eb22',
//   loadUserInfo: false,
//   audience: process.env.REACT_APP_BASE_URL
// };

export const authConfig = {
  authority: oidcConfig.stsAuthority,
  client_id: oidcConfig.clientId,
  id_token_hint: oidcConfig.clientId,
  userStore: new WebStorageStateStore({store: window.localStorage}),
  redirect_uri: `${oidcConfig.clientRoot}callback`,
  silent_redirect_uri: `${oidcConfig.clientRoot}callback`,
  post_logout_redirect_uri: `${oidcConfig.clientRoot}`,
  loadUserInfo: oidcConfig.loadUserInfo,
  response_type: 'code',
  scope: oidcConfig.clientScope,
  audience: oidcConfig.audience,
  // client_authentication: 'client_secret_post',
  filterProtocolClaims: true
};

export class AuthenticationService {
  constructor() {
    this.userManager = new UserManager(authConfig);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  async getUser() {
    return await this.userManager.getUser(); /* eslint-disable-line no-return-await */
  }

  async login() {
    await this.userManager.signinRedirect({
      extraQueryParams: {audience: oidcConfig.audience}
    });
  }

  async renewToken() {
    await this.userManager.signinSilent();
  }

  async logout() {
    await this.userManager.signoutRedirect();
  }
}
