import {MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {EventType, InteractionType} from '@azure/msal-browser';
import * as R from 'ramda';

import {loginRequest} from './msalAuthConfig';
import {AppProvider} from './context/AppContext';
import './App.css';
import {Logout} from './pages/logoutPage';
import DefaultLayout from './layouts/DefaultLayout';
import DefaultLayoutNoPeriods from './layouts/DefaultLayoutNoPeriods';
import EmptyLayout from './layouts/EmptyLayout';
import AlternativesPage from './pages/alternativesPage';
import BalancedAccountsPage from './pages/balancedAccountsPage';
import CashAndFixedPage from './pages/cashAndFixedPage';
import CoverPage from './pages/coverPage';
import EquitiesPage from './pages/equitiesPage';
import HybridInvestmentsPage from './pages/hybridInvestmentsPage';
import MultiAssetInvestmentsPage from './pages/multiAssetInvestmentsPage';
import PortfolioPage from './pages/portfolioPage';
import OtherInvestmentsPage from './pages/otherInvestmentsPage';
import PrivateEquitiesPage from './pages/privateEquitiesPage';
import RealAssetsPage from './pages/realAssetsPage';
import PlaceHolderPage from './pages/placeHolderPage';
import LoadingClientsPage from './pages/login';
import Version from './components/Version';
import DisclosurePage from './pages/disclosurePage';
import logger from './logger';

const App = () => {
  const {instance} = useMsal();
  const history = useHistory();

  instance.addEventCallback(event => {
    if (
      event.eventType === EventType.LOGIN_FAILURE &&
      event.error.errorMessage.includes('The user has cancelled')
    ) {
      history.go(0);
    }
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      history.push('/loadingclientsPage');
    }
  });

  return (
    <AppProvider>
      {values => {
        if (window._REACT_CONTEXT_DEVTOOL) {
          window._REACT_CONTEXT_DEVTOOL({
            id: 'uniqContextId',
            displayName: 'winterAppState',
            values
          });
        }
        return null;
      }}
      <Pages />
    </AppProvider>
  );
};

const RouteWithAuth = Component => {
  /* eslint-disable-next-line react/display-name */
  return () => (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={PlaceHolderPage}
    >
      <Component />
    </MsalAuthenticationTemplate>
  );
};

const withLayout = (Layout, Component) => {
  // TODO: query getMyClients for default info
  /* eslint-disable-next-line react/display-name */
  return () => (
    <Layout>
      <Component />
    </Layout>
  );
};

const NotFound = () => {
  return <div>Sorry... we could not find that route</div>;
};

const withDefaultLayout = Component =>
  withLayout(DefaultLayout, Component); /* eslint-disable-line */

const withDefaultLayoutNoPeriods = Component =>
  withLayout(DefaultLayoutNoPeriods, Component); /* eslint-disable-line */

const withEmptyLayout = Component => withLayout(EmptyLayout, Component); /* eslint-disable-line */

const isNotNil = R.complement(R.isNil);
function Pages() {
  // NOTE this is definitely hacky, but it works for now
  // Per https://stackoverflow.com/questions/8074218/speed-cost-of-localstorage
  // localStorage is pretty fast, so we probably won't see any performance hit
  const appState = JSON.parse(localStorage.getItem('winterAppState'));
  const pathname = window.location.pathname; /* eslint-disable-line */
  if (
    isNotNil(appState) &&
    appState.clientInfo.selectedClientId === 'UNKN' &&
    pathname !== '/logout' &&
    pathname !== '/' &&
    pathname !== '/loadingclientsPage'
  ) {
    logger.debug({method: 'Pages', isNilAppState: R.isNil(appState)});
    localStorage.setItem('winterAppState', JSON.stringify(appState));
    const currentLocation = window.location.origin;
    window.location.href = `${currentLocation}/loadingclientsPage`;
  }

  return (
    <Switch>
      <Route
        path="/loadingclientsPage"
        render={RouteWithAuth(withEmptyLayout(LoadingClientsPage))}
      />
      <Route exact path="/" render={RouteWithAuth(withDefaultLayoutNoPeriods(CoverPage))} />
      <Route path="/coverPage" render={RouteWithAuth(withDefaultLayoutNoPeriods(CoverPage))} />
      <Route path="/portfolioPage" render={RouteWithAuth(withDefaultLayout(PortfolioPage))} />
      <Route path="/alternativesPage" render={RouteWithAuth(withDefaultLayout(AlternativesPage))} />
      <Route
        path="/balancedaccountsPage"
        render={RouteWithAuth(withDefaultLayout(BalancedAccountsPage))}
      />
      <Route path="/cashandfixedPage" render={RouteWithAuth(withDefaultLayout(CashAndFixedPage))} />
      <Route path="/equitiesPage" render={RouteWithAuth(withDefaultLayout(EquitiesPage))} />
      <Route
        path="/hybridinvestmentsPage"
        render={RouteWithAuth(withDefaultLayout(HybridInvestmentsPage))}
      />
      <Route
        path="/multiassetinvestmentsPage"
        render={RouteWithAuth(withDefaultLayout(MultiAssetInvestmentsPage))}
      />

      <Route
        path="/otherinvestmentsPage"
        render={RouteWithAuth(withDefaultLayout(OtherInvestmentsPage))}
      />
      <Route
        path="/privateequitiesPage"
        render={RouteWithAuth(withDefaultLayout(PrivateEquitiesPage))}
      />
      <Route path="/realassetsPage" render={RouteWithAuth(withDefaultLayout(RealAssetsPage))} />
      <Route
        path="/disclosurePage"
        render={RouteWithAuth(withDefaultLayoutNoPeriods(DisclosurePage))}
      />
      {/* <Route path="/homePage" render={RouteWithAuth(withEmptyLayout(Home))} /> for testing msal token retrieval */}
      <Route path="/version" render={RouteWithAuth(withEmptyLayout(Version))} />
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export default App;
