import * as R from 'ramda';
/* eslint-disable-next-line import/no-cycle */
import {createMonthEndEpochDate} from '../utils';

const formatRefreshDate = date => date.toLocaleDateString();
const formatRefreshTime = date => date.toLocaleTimeString();
const formatRefreshZone = date => date.toString().match(/\(.*\)/)[0];

const testAsOfDateValue = process.env.REACT_APP_TEST_ASOFDATE;
const asOfDateValue = R.isNil(testAsOfDateValue)
  ? Date.now()
  : new Date(parseInt(testAsOfDateValue, 10));
const dateForRefresh = new Date();
export const referenceRefreshDate = formatRefreshDate(dateForRefresh);
export const referenceRefreshTime = formatRefreshTime(dateForRefresh);
export const referenceRefreshZone = formatRefreshZone(dateForRefresh);
export const referenceAsOfDate = parseInt(createMonthEndEpochDate(asOfDateValue), 10);
export const referenceClientId = 'UNKN';
export const referenceClientDisplayName = 'UNKN';
export const referenceEntityId = 'UNKN_unknown';
export const referenceEntityDisplayName = `${referenceEntityId}-Display`;
export const referenceEntityRibbonName = `${referenceEntityId}-Ribbon`;
export const referenceEntitiesList = [
  {
    entity: referenceEntityId,
    displayName: referenceEntityDisplayName,
    ribbonName: referenceEntityRibbonName
  }
];
export const referencePortfolioId = `${referenceEntityId}:${referenceAsOfDate}`;
export const referencePortfolioIndex = 0;
export const clientInfoInitialState = {
  id: referenceClientId,
  name: '',
  alias: '',
  displayName: referenceClientDisplayName,
  portfolios: [
    {
      id: 'cts-inv',
      entity: referenceEntityId,
      displayName: 'Personal Trust',
      asOfDatesList: [{asOfDate: referenceAsOfDate}],
      quickSummary: {
        asOfDate: referenceAsOfDate,
        inceptionDate: referenceAsOfDate
      },
      performanceData: {
        periods: [
          {
            code: 'CYTD',
            displayLabel: 'CYTD',
            displayName: 'CYTD'
          },
          {
            code: 'FYTD',
            displayLabel: 'FYTD',
            displayName: 'FYTD'
          }
        ],
        quickSummary: {
          asOfDate: referenceAsOfDate,
          inceptionDate: referenceAsOfDate
        }
      }
    }
  ],
  // Following fields created/updated by setNewClient()
  // or setting new Entity
  version: '0.6', // Global Spinner
  // version: '0.5', // multi-group
  selectedAsOfDate: referenceAsOfDate,
  entitiesList: [
    {
      entity: referenceEntityId,
      displayName: `${referencePortfolioId}-Display`,
      ribbonName: `${referenceEntityId}-Ribbon`
    }
  ],
  asOfDatesList: [{asOfDate: referenceAsOfDate}],
  selectedPortfolioIndex: 0, // Support multiple portfolios(entities/groups)
  selectedClientId: `${referenceClientId}`,
  selectedPortfolioId: `${referencePortfolioId}`,
  selectedEntityId: `${referenceEntityId}`,
  loadingCountHeader: {UNKN: 0},
  loadingCountCoverPage: {UNKN: 0},
  loadingCountPortfolioPage: {UNKN: 0},
  loadingCountAssetDetailsPage: {UNKN: 0}
};
export const initialState = {
  features: {},
  activePeriod: 'CYTD',
  cardsOrList: 'cards',
  entitiesList: referenceEntitiesList,
  renderError: false,
  refreshDate: referenceRefreshDate,
  refreshTime: referenceRefreshTime,
  refreshZone: referenceRefreshZone,
  selectedClientId: referenceClientId,
  selectedEntityId: referenceEntityId,
  selectedPortfolioId: referencePortfolioId,
  selectedPortfolioIndex: referencePortfolioIndex,
  selectedAsOfDate: referenceAsOfDate,
  clientInfo: clientInfoInitialState,
  loadingCountHeader: {UNKN: 0},
  loadingCountCoverPage: {UNKN: 0},
  loadingCountPortfolioPage: {UNKN: 0},
  loadingCountAssetDetailsPage: {UNKN: 0}
};
export const clientInfoEmptyState = {
  id: '',
  name: '',
  alias: '',
  displayName: '',
  portfolios: [
    {
      id: '',
      displayName: '',
      asOfDatesList: [],
      entitiesList: [],
      quickSummary: {}
    }
  ],
  // Following fields created/updated by setNewClient()
  // TODO: Move these up one level, outside clientInfo
  version: 0.7, // refresh date/time/zone
  // version: '0.6', // Global Spinner
  // version: '0.5', // multi-group
  // asOfDatesList: [],
  selectedClientId: referenceClientId,
  // selectedEntityId: '',
  selectedPortfolioId: '',
  selectedAsOfDate: 0
};
