import {LogLevel} from '@azure/msal-browser';

import {curry} from 'ramda';
import logger from './logger';

const myEnv = process?.env || {};
const myGlobals = window.globals || {};

// We may want to use proxies. IE may not work so do this for now.
const getConfig = curry((env, global, prop, fallbackValue) => {
  return env[prop] ?? global[prop] ?? fallbackValue;
});

const getValueFromEnvOrGlobals = getConfig(myEnv, myGlobals);

const clientId = getValueFromEnvOrGlobals(
  'REACT_APP_OIDC_CLIENTID',
  '80b884e0-2ad9-498d-9a07-4d82f69e1531'
);

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: getValueFromEnvOrGlobals(
      'REACT_APP_OIDC_CLIENTID',
      '80b884e0-2ad9-498d-9a07-4d82f69e1531'
    ),
    audience: getValueFromEnvOrGlobals(
      'REACT_APP_OIDC_AUDIENCE',
      '80b884e0-2ad9-498d-9a07-4d82f69e1531'
    ),
    authority: getValueFromEnvOrGlobals(
      'REACT_APP_OIDC_STSAUTHORITY',
      'https://hcpb2cdev.b2clogin.com/hcpb2cdev.onmicrosoft.com/B2C_1_Dev_Sign_In/v2.0/'
    ),
    redirectUri: getValueFromEnvOrGlobals('REACT_APP_BASE_URL', 'http://localhost:3000'),
    postLogoutRedirectUri: getValueFromEnvOrGlobals('REACT_APP_BASE_URL', 'http://localhost:3000'),
    knownAuthorities: [
      'hcpb2cdev.b2clogin.com',
      'hcpb2cstg.b2clogin.com',
      'hcpb2cprod.b2clogin.com'
    ],
    navigateToLoginRequestUrl: false,
    protocolMode: 'OIDC'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logger.error({error: message});
            return;
          case LogLevel.Info:
            // Muting all the MSAL 'info' log entries; too much clutter!
            logger.trace({info: message});
            return;
          case LogLevel.Verbose:
            logger.debug({debug: message});
            return;
          case LogLevel.Warning:
            logger.warn({warn: message});
            return;
          default:
            logger.warn({warn: message});
        }
      }
    }
  }
};

export const graphqlConfig = {
  url: getValueFromEnvOrGlobals('REACT_APP_GRAPHQL_URL', 'http://localhost:7071/graphql/')
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

export const loginRequest = {
  prompt: 'login',
  scopes: [`openid ${clientId}`]
};

export const silentTokenRequest = {
  scopes: [`openid ${clientId}`]
};
