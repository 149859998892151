/* eslint-disable-next-line no-console */

import React from 'react';
import AsOfDatesMenu from '../AsOfDatesMenu';
import {StyledPeriodsContainer, StyledAsOfDatesMenuContainer} from '../../styledComponents';

const AsOfDatesWithNoPeriods = () => {
  return (
    <>
      <StyledPeriodsContainer className="periods-container">
        <StyledAsOfDatesMenuContainer>
          <AsOfDatesMenu />
        </StyledAsOfDatesMenuContainer>
      </StyledPeriodsContainer>
    </>
  );
};

export default AsOfDatesWithNoPeriods;
