import React, {useRef, useContext, useEffect} from 'react'; /* eslint-disable-line */
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';

import AssetAllocation from '../../../components/AssetAllocation';
import coverPortfolioValueIcon from '../../../images/icon-coin--orange.svg';
import coverPortfolioIncreaseITDIcon from '../../../images/icon-graph--blue.svg';
import coverPortfolioCashFixedIcon from '../../../images/icon-cash--green.svg';
import {GET_QUICK_STATS_PORTFOLIO} from '../../../graphql/queries';
import {formatDate, formatDollar, formatPercent} from '../../../utils';
import {NA, setRenderError} from '../../../utils/errorHelpers';
import {useComponentSize} from '../../../hooks/useComponentSize';
import COLORS from '../../../theme/colors';
import {AppContext} from '../../../context/AppContext';
import logger from '../../../logger';
import {setLoadingCountCoverPage} from '../../../utils/utilHelpers';

const StyledOverviewAllocationContainer = styled.div`
  position: relative;
  width: 100%;
  vertical-align: middle;
  height: auto;
  margin-bottom: 26px;
`;

const StyledOverviewAllocationOuter = styled.div`
  width: 100%;
  display: grid;
  row-gap: 25px;
  column-gap: 25px;
  grid-auto-rows: minmax(286px, auto);
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  & .allocation {
    display: flex;
    min-height: 286px;
    max-height: 301px;
    min-width: 385px;
    max-width: 429px;
    justify-content: flex-end;
    & .asset-allocation {
      & img {
        max-width: 100%;
      }
    }
  }
`;

const StyledOverviewAllocation = styled.div`
  width: 100%;
  grid-column-end: 3;
  grid-column-start: 1;
  display: grid;
  grid-auto-rows: minmax(185px, auto);
  grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
  align-items: center;
  & .quickstats--border {
    border-left: 1px solid ${COLORS['@neutrals-3']};
  }
`;

const StyledOverviewAllocationComponent = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0.5em;
  & .quickstats-icon {
    margin-bottom: 15px;
  }
`;

const StyledValueContainer = styled.div`
  text-align: center;
`;

const StyledITDContainer = styled.div`
  position: relative;
`;

const StyledITDPercentage = styled.div`
  left: 37%;
  top: 95px;
  position: ${props => (props.width === 0 || props.width >= 590 ? 'absolute' : '')};
  margin-top: ${props => (props.width === 0 || props.width >= 590 ? '25px' : '0px')};
`;

const StyledPageHeaderText = styled.div`
  margin-bottom: 47px;
  &.page-header__text {
    margin-top: 10px;
  }
`;

const Overview = () => {
  const {state, dispatch} = useContext(AppContext);
  const overviewRef = useRef(null);
  const {height, width} = useComponentSize(overviewRef);

  const {selectedClientId, selectedPortfolioId, selectedAsOfDate} = state;
  logger.trace({
    method: 'Overview/index/GET_QUICK_STATS_PORTFOLIO',
    state
  });
  const {loading, error, data} = useQuery(GET_QUICK_STATS_PORTFOLIO, {
    errorPolicy: 'all',
    returnPartialData: true,
    variables: {id: selectedClientId, portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountCoverPage(
        {key: 'Overview/index/GET_QUICK_STATS_PORTFOLIO', value: 1},
        dispatch
      );
    } else if (!loading) {
      setLoadingCountCoverPage(
        {key: 'Overview/index/GET_QUICK_STATS_PORTFOLIO', value: 0},
        dispatch
      );
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }
  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_QUICK_STATS_PORTFOLIO QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'coverPage';

  if (R.isNil(data?.data))
    setRenderError(dispatch, `[${page}:Overview] No GET_QUICK_STATS_PORTFOLIO graphql data`);

  const {quickSummary} = data.data;

  const formattedAsOfDate = formatDate(quickSummary?.asOfDate) ?? NA;
  const formattedAssetValuationAsOf = formatDate(quickSummary?.assetValuationAsOf) ?? NA;
  const formattedAssetValuationPercent = formatPercent(quickSummary?.assetValuationPercent) ?? NA;
  const formattedTotalMarketValue = formatDollar(quickSummary?.totalMarketValue) ?? NA;
  const formattedGains = formatDollar(quickSummary?.gains, true) ?? NA;
  const formattedGainsPercent = formatPercent(quickSummary?.gainsPercent, true) ?? NA;
  const formattedCashAndFixedMarketValue = formatDollar(quickSummary?.cashAndFixedMarketValue);
  return (
    <>
      <StyledOverviewAllocationContainer className="overview">
        <StyledPageHeaderText className="body-large page-header__text">
          {`Estimated as of ${formattedAsOfDate} | ${formattedAssetValuationPercent} of portfolio assets reflect
          valuations prior to ${formattedAssetValuationAsOf}`}
        </StyledPageHeaderText>
        <StyledOverviewAllocationOuter>
          <StyledOverviewAllocation ref={overviewRef} className="component-box-shadow quickstats">
            <StyledOverviewAllocationComponent className="quickstats__value">
              <StyledValueContainer>
                <img
                  src={coverPortfolioValueIcon}
                  alt="Portfolio Value"
                  className="quickstats-icon"
                />
                <h4>Portfolio Value</h4>
                <h2 data-testid="quickstats-value-currency">{formattedTotalMarketValue}</h2>
              </StyledValueContainer>
            </StyledOverviewAllocationComponent>
            <StyledOverviewAllocationComponent
              className={`quickstats__performance ${height > 370 ? '' : 'quickstats--border'}`}
            >
              <StyledITDContainer>
                <img
                  src={coverPortfolioIncreaseITDIcon}
                  alt="Increase ITD"
                  className="quickstats-icon"
                />
                <h4>Portfolio Performance ITD</h4>
                <h2 data-testid="quickstats-performance-currency">{formattedGains}</h2>
                <StyledITDPercentage width={width}>
                  <h4 data-testid="quickstats-performance-percent">{formattedGainsPercent}</h4>
                </StyledITDPercentage>
              </StyledITDContainer>
            </StyledOverviewAllocationComponent>
            <StyledOverviewAllocationComponent
              className={`quickstats__cashfixed ${height > 370 ? '' : 'quickstats--border'}`}
            >
              <div>
                <img
                  src={coverPortfolioCashFixedIcon}
                  alt="Cash & Fixed"
                  className="quickstats-icon"
                />
                <h4>Cash & Fixed</h4>
                <h2 data-testid="quickstats-cashfixed-currency">
                  {formattedCashAndFixedMarketValue}
                </h2>
              </div>
            </StyledOverviewAllocationComponent>
          </StyledOverviewAllocation>
          <div className="component-box-shadow allocation">
            <AssetAllocation />
          </div>
        </StyledOverviewAllocationOuter>
      </StyledOverviewAllocationContainer>
    </>
  );
};

export default Overview;
