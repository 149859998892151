import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {makeUserManager} from 'react-oidc';
import {graphqlConfig} from '../../msalAuthConfig';
import {authConfig} from '../../security/authenticationService';

const userManager = makeUserManager(authConfig);

const authLink = setContext(async (_, {headers}) => {
  const user = await userManager.getUser();
  if (user && user.access_token) {
    const token = localStorage.getItem('token') || user.access_token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  }
  return {
    headers: {
      ...headers
    }
  };
});

const httpLink = createHttpLink({
  uri: graphqlConfig.url
});

// Unable to authenticate this client within queries
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export {client};
