import React from 'react';
import App from '../../App';
import AuthorizedApolloProvider from './authorized-apollo-provider';

const ApolloConnection = () => {
  return (
    <AuthorizedApolloProvider>
      <App />
    </AuthorizedApolloProvider>
  );
};

export default ApolloConnection;
