export const labelPosition = (gainsPercentByAsset, total) => {
  const assetDisplayOrder = [
    'realAssets',
    'privateEquities',
    'multiAsset',
    'hybrid',
    'otherInvestments',
    'alternative',
    'equities',
    'balancedAccounts',
    'fixedIncome',
    'cash'
  ];
  if (total >= 0) {
    for (let i = 0; i < assetDisplayOrder.length; i++) {
      if (
        gainsPercentByAsset[assetDisplayOrder[i]] === undefined ||
        gainsPercentByAsset[assetDisplayOrder[i]] >= 0
      ) {
        return {
          bar: assetDisplayOrder[i],
          total
        };
      }
    }
  } else {
    for (let i = 0; i < assetDisplayOrder.length; i++) {
      if (
        gainsPercentByAsset[assetDisplayOrder[i]] !== undefined &&
        gainsPercentByAsset[assetDisplayOrder[i]] < 0
      ) {
        return {
          bar: assetDisplayOrder[i],
          total
        };
      }
    }
  }
  return {
    bar: 'realAssets',
    total
  };
};

export const sumPositiveNumbers = arr => {
  const isPositive = num => typeof num === 'number' && num > 0;
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    if (isPositive(arr[i])) {
      sum += arr[i];
    }
  }
  return sum;
};

export const sumNegativeNumbers = arr => {
  const isNegative = num => typeof num === 'number' && num < 0;
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    if (isNegative(arr[i])) {
      sum += arr[i];
    }
  }
  return sum;
};
