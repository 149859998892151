import {gql} from '@apollo/client';

/**
 * @function GET_QUERY
 *  query: GetAdmin / GET_ADMIN
 * @returns {JSON} List of createDate, createTime, zone
 */
export const GET_ADMIN = gql`
  query GetAdmin {
    data: GetAdmin {
      creationDate
      creationTime
      timezone
    }
  }
`;
