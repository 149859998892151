import React, {useContext, useState} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import HistoricalAllocationBarGraph from '../../../components/HistoricalAllocationBarGraph';
import {AppContext} from '../../../context/AppContext';
import {classOrder} from '../../../utils/assetClassProperties.data';
import {assetClassProperties} from '../../../utils/assetClassProperties';
import {formatHistoricalAssetAllocationData} from '../../../utils/formatter';
import CheckedBox from '../../../components/DistCCGraph/DistCCHeroGraphHeader/CheckedBox';

const StyledHistoricalAssetAllocationContainer = styled.div`
  margin-top: 35px;
  padding: 40px;
  margin-bottom: 20px;
  & .mv-allocation {
    padding-right: 25px;
  }
  & .percentage-allocation {
    padding-left: 25px;
  }
`;

const StyledHistoricalAssetAllocationGraphs = styled.div``;

const StyledHistoricalAssetAllocationCheckedBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const StyledGraph = styled.div`
  width: 100%;
`;

const StyledAllocationLegend = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const ColoredLegendSquare = styled.div`
  margin-right: 10px;
  height: 12px;
  width: 12px;
  background-color: ${props => assetClassProperties(props.assetClass).colorCode};
`;

const LegendAssetName = styled.div`
  margin-right: 22px;
`;

const HistoricalAssetAllocation = ({
  historicalAssetAllocationData,
  testingInception,
  testingAsOfDate
}) => {
  const [displayLabels, setDisplayLabels] = useState(true);
  const {state} = useContext(AppContext);
  const activeEntity = state.selectedEntityId;
  const asOfDate = testingAsOfDate || state.selectedAsOfDate;
  const activePortfolio = R.find(R.propEq('entity', activeEntity))(
    state.clientInfo?.portfolios || []
  );
  const clientInceptionDate = testingInception || activePortfolio?.quickSummary?.inceptionDate;

  if (!historicalAssetAllocationData) return null;

  const formattedDataPercent = formatHistoricalAssetAllocationData(
    historicalAssetAllocationData,
    clientInceptionDate,
    asOfDate
  );
  console.log(formattedDataPercent); /* eslint-disable-line no-console */

  const legendAssets = R.compose(
    R.reject(asset => !R.includes(asset, classOrder)),
    R.keys,
    R.reduce((a, b) => Object.assign(a, b), {})
  )(formattedDataPercent);

  const orderedLegendAssets = R.sortBy(asset => {
    return R.indexOf(asset, classOrder);
  })(legendAssets || []);

  return (
    <StyledHistoricalAssetAllocationContainer className="component-box-shadow">
      <StyledHistoricalAssetAllocationGraphs>
        <StyledHistoricalAssetAllocationCheckedBox>
          <CheckedBox
            label="Show Labels"
            displayMarketValue={displayLabels}
            setDisplayMarketValue={setDisplayLabels}
          />
        </StyledHistoricalAssetAllocationCheckedBox>
        <StyledGraph className="percentage-allocation">
          <HistoricalAllocationBarGraph
            graphData={formattedDataPercent}
            displayLabels={displayLabels}
          />
        </StyledGraph>
      </StyledHistoricalAssetAllocationGraphs>
      <StyledAllocationLegend>
        {orderedLegendAssets.map(asset => {
          return (
            <StyledLegendItem key={asset}>
              <ColoredLegendSquare assetClass={asset} />
              <LegendAssetName className="body-small-bold">
                {assetClassProperties(asset).categoryName}
              </LegendAssetName>
            </StyledLegendItem>
          );
        })}
      </StyledAllocationLegend>
    </StyledHistoricalAssetAllocationContainer>
  );
};

HistoricalAssetAllocation.propTypes = {
  testingInception: PropTypes.number,
  testingAsOfDate: PropTypes.number,
  historicalAssetAllocationData: PropTypes.array
};

export default HistoricalAssetAllocation;
