import React from 'react';
import * as R from 'ramda';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

/**
 * @function SummaryTable
 * @desc Used to define summary rows for Asset Class Detail page tables
 * @param {array} subHeaderColumns - array of column definition objects
 * @param {array} record - array of records for each summary row
 * @param {boolean} displayAccounts - show (true) or hide (false) the detial data (in DetailTable)
 * @param {function} handleDropDownClick - click handler to show/hide DetailTable rows
 * @example subHeaderColumns
 * const subHeaderColumnsTestSparkline = [
 *   {
 *     key: 'title',
 *     dataIndex: 'marketValue',
 *     displayName: 'SummaryTitle',
 *     render: (_title, _record) => `${_title}, ${_record}`
 *   },
 *   {
 *     key: 'sparkline',
 *     displayName: 'SparklinesGraph',
 *     dataIndex: ['gains', 'gainsPercent'],
 *     render: function SparklinesGraph1(_title, _record) {
 *       // NOTE: includes special 'testingWidth' parameter so that recharts actually generates the graph
 *       // See https://stackoverflow.com/questions/60215153/when-mounted-in-enzyme-why-does-a-recharts-chart-with-a-responsivecontainer-not
 *       return <SparklinesGraph reportData={_record.report} type="sparklines" testingWidth={150} />;
 *     }
 *   }
 * ];
 * @example record
 * export const sparklineTestData = {
 *   allocation: null,
 *   assetClass: 'cash',
 *   marketValue: 75000000,
 *   gains: 39622424,
 *   gainsPercent: 25.4,
 *   netflows: 216200,
 *   outflows: -1050012,
 *   inflows: 321212,
 *   asOfDate: 1617112323,
 *   report: [ <================= Must be inserted into data retrieved from backend
 *     {
 *       id: 1,
 *       date: 1612080000,
 *       value: 111111
 *     },
 *     {
 *       id: 2,
 *       date: 1614499200,
 *       value: 888888
 *     }....
 * @see SummaryTable.sparklineTestData.js
 */
const SummaryTable = ({subHeaderColumns, displayAccounts, record, handleDropDownClick}) => {
  return (
    <div className="summary border-bottom">
      <div
        tabIndex="0"
        role="button"
        onClick={() => handleDropDownClick()}
        onKeyDown={() => {}}
        className="dropdown-caret"
      >
        {displayAccounts ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </div>
      {R.map(column => <div key={column.key}>{column.render(column.displayName, record)}</div>)(
        subHeaderColumns.slice(0, 1)
      )}
      {/* subHeaderColumns[0] is the "title" element; the "item" elements must be wrapped in a <div className="summary-items"> */}
      <div className="summary-items">
        {R.map(column => <div key={column.key}>{column.render(column.displayName, record)}</div>)(
          subHeaderColumns.slice(1)
        )}
      </div>
    </div>
  );
};

SummaryTable.propTypes = {
  displayAccounts: PropTypes.bool,
  handleDropDownClick: PropTypes.func,
  subHeaderColumns: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func
    })
  ),
  record: PropTypes.shape({
    assetClass: PropTypes.string,
    gains: PropTypes.number,
    gainsPercent: PropTypes.number,
    netflows: PropTypes.number,
    inflows: PropTypes.number,
    outflows: PropTypes.number,
    allocation: PropTypes.number
  })
};
export default SummaryTable;
