import React from 'react';
import PropTypes from 'prop-types';

import {formatDate} from '../../../../utils';

const AssetClassDateRange = ({firstDate, lastDate}) => {
  const first = formatDate(firstDate);
  const last = formatDate(lastDate);

  return (
    <>
      <h3 className="date-range">
        Totals from {first} - {last}
      </h3>
    </>
  );
};

AssetClassDateRange.propTypes = {
  firstDate: PropTypes.number,
  lastDate: PropTypes.number
};

export default AssetClassDateRange;
