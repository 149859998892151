import React, {useEffect} from 'react';
import {useMsal} from '@azure/msal-react';
import {client} from '../../graphql/queries/client';

export function Logout() {
  const {instance} = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount()
    });
    client.resetStore();
  }, [instance]);

  return <div>Logout</div>;
}
