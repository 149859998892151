import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import COLORS from '../../../theme/colors';
import SummaryTable from '../../../components/assetClassDetails/SummaryTable';
import DetailTableAntD from '../../../components/assetClassDetails/DetailTableAntD';
import {
  formatSummaryPercent,
  formatSummaryDollar,
  formatSummaryDollarPercent,
  formatSummaryTitle
} from '../../../utils';
import {tableColumns} from './tableColumns';
import {AppContext} from '../../../context/AppContext';

const StyledFixedAccountsContainer = styled.div`
  margin-top: 45px;
  & .border-bottom {
    border-bottom: 1px solid ${COLORS['@neutrals-4']};
  }
  & .summary {
    display: flex;
    flex-wrap: wrap;
  }
  & .summary-title-outer {
    width: 390px;
    margin-right: 10px;
  }
  & .summary-items {
    min-width: 300px;
    padding-top: 15px;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    margin-bottom: 20px;
    row-gap: 30px;
    column-gap: 10px;
  }
  & .summary-title__title {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #262626;
  }
  & .summary-title__value {
    margin-bottom: 30px;
  }
  & .summary-item__title {
    margin-bottom: 10px;
  }
  & .summary-item__value {
    margin-right: 5px;
  }
  & .summary-item-flex {
    display: flex;
    flex-wrap: wrap;
  }
  & .dropdown-caret {
    outline: none;
    cursor: pointer;
    padding-top: 10px;
    margin-right: 15px;
    color: ${COLORS['@primary-color']};
  }
`;

const CashAndFixedAccounts = ({accountsData, componentTitle}) => {
  /* eslint-disable-line */
  const {state} = useContext(AppContext);
  const {activePeriod} = state;
  const [displayAccounts, setdisplayAccounts] = useState(false);

  const handleDropDownClick = () => {
    if (displayAccounts === false) {
      setdisplayAccounts(true);
    } else {
      setdisplayAccounts(false);
    }
  };
  const renderSummaryTitle = (_title, _record) => {
    return (
      <div className="summary-title-outer">
        {formatSummaryTitle(componentTitle, activePeriod, _record, handleDropDownClick)}
      </div>
    );
  };
  const renderGainLoss = (_title, _record) => {
    return (
      <div>
        {formatSummaryDollarPercent('Gain/Loss', _record.gainsPercent, _record.gains, true)}
      </div>
    );
  };

  const renderNetflows = (_title, _record) => {
    return <div>{formatSummaryDollar(_title, _record.netflows, true)}</div>;
  };

  const renderAllocation = (_title, _record) => {
    return <div>{formatSummaryPercent('Allocation', _record.allocation)}</div>;
  };
  const subHeaderColumns = [
    {
      key: 'title',
      dataIndex: 'marketValue',
      displayName: 'SummaryTitle',
      render: (_title, _record) => renderSummaryTitle(_title, _record)
    },
    {
      key: 'allocation',
      displayName: 'Allocation',
      dataIndex: 'allocation',
      render: (_title, _record) => renderAllocation(_title, _record)
    },
    {
      key: 'netflows',
      displayName: 'NetFlows',
      dataIndex: 'netflows',
      render: (_title, _record) => renderNetflows(_title, _record)
    },
    {
      key: 'gainLoss',
      displayName: 'SummaryGainLoss',
      dataIndex: ['gains', 'gainsPercent'],
      render: (_title, _record) => renderGainLoss(_title, _record)
    }
  ];
  const record = accountsData;
  const detailTableData = {
    subType2s: accountsData.accounts,
    ...accountsData
  };
  return (
    <StyledFixedAccountsContainer>
      <SummaryTable
        className="summary border-bottom"
        displayAccounts={displayAccounts}
        record={record}
        handleDropDownClick={handleDropDownClick}
        componentTitle={componentTitle}
        activePeriod={activePeriod}
        subHeaderColumns={subHeaderColumns}
      />
      {displayAccounts ? (
        <DetailTableAntD record={detailTableData} tableColumns={tableColumns} />
      ) : (
        ''
      )}
    </StyledFixedAccountsContainer>
  );
};

CashAndFixedAccounts.propTypes = {
  accountsData: PropTypes.shape({
    marketValue: PropTypes.number,
    gains: PropTypes.number,
    gainsPercent: PropTypes.number,
    netflows: PropTypes.number,
    inflows: PropTypes.number,
    outflows: PropTypes.number,
    allocation: PropTypes.number,
    accounts: PropTypes.array
  }),
  componentTitle: PropTypes.string.isRequired
};

export default CashAndFixedAccounts;
