import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {getStack} from '../../utils/utilHelpers';

const StyledErrorContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  text-align: center;
`;

const CLIENT_ERROR_MESSAGE =
  'An error has occurred. Please contact your client service team for assistance resolving this issue.';

const ErrorHandler = ({loggedMessage}) => {
  /* eslint-disable-next-line no-console */
  console.log(loggedMessage);
  if (loggedMessage.includes('No assets data: period')) {
    /* eslint-disable-next-line */
    console.log("Entering debugger for 'No assets data: period bug'");
    console.log(getStack()); /* eslint-disable-line */
    // debugger; /* eslint-disable-line */
  }
  return (
    <StyledErrorContainer className="side-padding-50">{CLIENT_ERROR_MESSAGE}</StyledErrorContainer>
  );
};

ErrorHandler.propTypes = {
  loggedMessage: PropTypes.string
};

export {CLIENT_ERROR_MESSAGE};
export default ErrorHandler;
