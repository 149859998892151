/** formatter.js
 *  Collect of formatting routines
 *  NOTE: All dates in CDE are UTC dates at the moment
 *  Thus, all date processing is handled in routines here
 */
import React from 'react';
import * as R from 'ramda'; /* eslint-disable-line */

import styled from '@emotion/styled';
import * as COLORS from '../theme/colors';
import {tapLog} from '.'; /* eslint-disable-line */

/**
 * Various formatting functions / values
 */

const NA = 'N/A';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const abbrevMonth = dateValue => months[dateValue.getUTCMonth()].toUpperCase().substring(0, 3);

const currentYear = new Date().getUTCFullYear();

/** dateNow
 * Returns value of Date.now()
 * This is here just to consolidate all date routines into one place
 * @returns {number} Date.now()
 */
export const dateNow = () => Date.now();

// working with epoch dates (for AsOfDates)

/**
 * @function createEpochDate
 * @param {date} - incoming date; number or date-convertible value
 * @return {epochDate} - converted date => epoch
 * @note: Unix epoch date strings are 10 characters > Sep 9 2001 < Nov 20 2286
 *        JavaScript time strings are 13 characters long
 *  We're not interested in dates outside this range
 */
export const createEpochDate = date => Math.floor(new Date(new Date(date).toUTCString()) / 1000);

/** default formatter options
 * basic setup for Intl.NumberFormat
 */
const formatterOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0

  // These options are needed to round to whole numbers if that's what you want.
  //  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
};

const StyledPercentOrDollar = styled.span`
  & .positive-gain {
    color: ${COLORS['@secondary-darkgreen']};
  }
  & .negative-gain {
    color: ${COLORS['@secondary-red']};
  }
`;

export const formatter = new Intl.NumberFormat('en-US', formatterOptions);

/**
 * @function formatDollar
 * @arg {number} dollar - format dollar values; displays with no decimal points
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} - formatted dollar value
 */
export const formatDollar = (value, sign = false, color = false) => {
  /* eslint-disable-line */
  const myFormatter = formatter;
  if (typeof value !== 'number') return 'N/A';

  if (sign && color) {
    return (
      <StyledPercentOrDollar>
        <span className={value >= 0 ? 'positive-gain' : 'negative-gain'}>
          {R.compose(
            /* eslint-disable-line */
            R.concat(value >= 0 ? '+' : ''),
            myFormatter.format
          )(value)}
        </span>
      </StyledPercentOrDollar>
    );
  }

  if (sign) {
    return R.compose(
      /* eslint-disable-line */
      R.concat(value >= 0 ? '+' : ''),
      myFormatter.format
    )(value);
  }
  return myFormatter.format(value);
};

/**
 * @function formatDollarsInMillions
 * @arg {number} dollar - format dollar values; displays with no decimal points
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} - formatted dollar value, in millions, with trailing 'M'
 */
export const formatDollarsInMillions = (value, sign = false) => {
  if (typeof value !== 'number') return 'N/A';
  return R.compose(
    /* eslint-disable-line */
    R.concat(R.__, 'M'),
    _value => formatDollar(_value, sign),
    R.divide(R.__, 1000000)
  )(value);
};

/**
 * @function formatDollarsInMillionsWithDecimal
 * @arg {number} dollar - format dollar values; displays with no decimal points
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} - formatted dollar value, in millions, with trailing 'M'
 */
export const formatDollarsInMillionsWithDecimal = value => {
  if (typeof value !== 'number') return 'N/A';

  const formatterOptionsDecimal = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  };
  const dollarFormatter = new Intl.NumberFormat('en-US', formatterOptionsDecimal);
  return R.compose(
    /* eslint-disable-line */
    R.concat(R.__, 'M'),
    _value => dollarFormatter.format(_value),
    R.divide(R.__, 1000000)
  )(value);
};

/**
 * @function formatDollarsInMillionsForDistCCGraph
 * @arg {number} dollar - format dollar values; displays with no decimal points
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} - formatted dollar value, in millions, with trailing 'M'
 *                  - negative numbers return with '()' rather than sign
 *                  - zero value return as '-'
 */
export const formatDollarsInMillionsForDistCCGraph = (value, sign = false) => {
  if (typeof value !== 'number') return NA;
  if (value === 0) return '$-';
  if (value < 0) {
    return R.compose(
      /* eslint-disable-line */
      R.replace('-$', '$(', R.__),
      R.concat(R.__, 'M)'),
      _value => formatDollar(_value, sign),
      R.divide(R.__, 1000000)
    )(value);
  }
  return R.compose(
    /* eslint-disable-line */
    R.concat(R.__, 'M'),
    _value => formatDollar(_value, sign),
    R.divide(R.__, 1000000)
  )(value);
};

/**
 * @function formatYAxisValue
 * @arg {number} value - format dollar values;
 * @return {string} - formatted dollar value, in millions or thousands, with trailing 'M' or 'K'
 */
export const formatYAxisValue = (value, max, min) => {
  if (typeof value !== 'number') return NA;
  if (max < 1000000) {
    if (value <= -1000000 || value >= 1000000) {
      return formatDollarsInMillionsWithDecimal(value);
    }
    return R.compose(
      R.concat(R.__, 'K'),
      _value => formatDollar(_value),
      R.divide(R.__, 1000)
    )(value);
  }
  if (max < 10000000 || max - min < 1000000) {
    return formatDollarsInMillionsWithDecimal(value);
  }
  return formatDollarsInMillions(value);
};

/**
 * @function formatYAxisValueDistCC
 * @arg {number} value - format dollar values
 * @return {string} - formatted dollar value, in millions and thousands, with trailing 'M' or 'K'
 *                  - negative numbers return with '()' rather than sign
 *                  - zero value return as '-'
 */
export const formatYAxisValueDistCC = (value, max, min, capitalCallsMin, assetActivity) => {
  const formatterOptionsDecimal = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  };
  const dollarFormatter = new Intl.NumberFormat('en-US', formatterOptionsDecimal);
  if (typeof value !== 'number') return NA;
  if (value === 0) return '$-';
  if (!assetActivity) return '';
  if (max < 1000000 && capitalCallsMin > -1000000) {
    if (value <= -1000000) {
      return R.compose(
        R.replace('-$', '$(', R.__),
        R.concat(R.__, 'M)'),
        _value => dollarFormatter.format(_value),
        R.divide(R.__, 1000000)
      )(value);
    }
    if (value >= 1000000) {
      return R.compose(
        R.concat(R.__, 'M'),
        _value => dollarFormatter.format(_value),
        R.divide(R.__, 1000000)
      )(value);
    }
    if (value < 0) {
      return R.compose(
        R.replace('-$', '$(', R.__),
        R.concat(R.__, 'K)'),
        _value => formatDollar(_value),
        R.divide(R.__, 1000)
      )(value);
    }
    return R.compose(
      R.concat(R.__, 'K'),
      _value => formatDollar(_value),
      R.divide(R.__, 1000)
    )(value);
  }
  if ((max < 10000000 && capitalCallsMin > -10000000) || max - min < 1000000) {
    if (value < 0) {
      return R.compose(
        R.replace('-$', '$(', R.__),
        R.concat(R.__, 'M)'),
        _value => dollarFormatter.format(_value),
        R.divide(R.__, 1000000)
      )(value);
    }
    return R.compose(
      R.concat(R.__, 'M'),
      _value => dollarFormatter.format(_value),
      R.divide(R.__, 1000000)
    )(value);
  }
  return formatDollarsInMillionsForDistCCGraph(value);
};

/**
 * @function formatPercent
 * @arg {number} percent - format percent values; displays with exactly one decimal point
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} - formated percent value
 */
export const formatPercent = (value, sign = false) => {
  if (typeof value !== 'number') return 'N/A';
  const myFormatter = new Intl.NumberFormat('en-us', {
    ...formatterOptions,
    style: 'unit',
    unit: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });
  if (sign) {
    return (
      <>
        <StyledPercentOrDollar>
          <span className={value >= 0 ? 'positive-gain' : 'negative-gain'}>
            {R.compose(
              /* eslint-disable-line */
              R.concat(value >= 0 ? '+' : ''),
              myFormatter.format
            )(value)}
          </span>
        </StyledPercentOrDollar>
      </>
    );
  }
  return myFormatter.format(value);
};

/**
 * @function formatPercentWithoutDecimal
 * @arg {number} percent - format percent values; displays with exactly one decimal point
 * @return {string} - formated percent value
 */
export const formatPercentWithoutDecimal = value => {
  if (typeof value !== 'number') return 'N/A';
  const myFormatter = new Intl.NumberFormat('en-us', {
    ...formatterOptions,
    style: 'unit',
    unit: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return myFormatter.format(value);
};

/**
 * @function formatPercentNoColorWithSign
 * @arg {number} percent - format percent values; displays with exactly one decimal point
 * @return {string} - formated percent value with + or - sign
 */

export const formatPercentNoColorWithSign = value => {
  if (typeof value !== 'number') return NA;
  const myFormatter = new Intl.NumberFormat('en-us', {
    ...formatterOptions,
    style: 'unit',
    unit: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });
  return (
    <>
      {R.compose(
        /* eslint-disable-line */
        R.concat(value >= 0 ? '+' : ''),
        myFormatter.format
      )(value)}
    </>
  );
};

/**
 * @function formatText (simple helper function)
 * @arg {string} value - just display the text
 * @return {string} - string as passed in
 */
export const formatText = value => value;

/**
 * @function formatDate
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} - formattedData mm/dd/yyyy for now IN UTC TIME
 */
export const formatDate = value => {
  if (typeof value !== 'number') return NA;
  const date = new Date(value * 1000);
  const formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  return R.isNil(formattedDate.match(/\d?\d\/\d?\d\/\d\d\d\d/)) ? 'N/A' : formattedDate;
};

/**
 * @function formatDateWithLastDayOfMonth
 * @param {date} - dateValue; can be any number or valid date string
 * @return {string} - formatted date string
 */
export const formatDateWithLastDayOfMonth = dateValue => {
  // Ensure that formatDate receives an integer (Time) value
  const tmpDate = Math.floor(new Date(dateValue).getTime() / 1000);
  const formattedDate = formatDate(tmpDate);
  if (formattedDate === NA) return NA;
  const dateFields = formattedDate.split('/');
  const endOfMonthDate = new Date(dateFields[2], dateFields[0], 0);
  return endOfMonthDate.toDateString().slice(4);
};

/**
 * @function formatTestPercent
 * @arg {integer} - percentValue
 * @ereturn {string} - formatted percent value from mock data for testing
 */
export const formatTestPercent = value => {
  if (typeof value !== 'number') return NA;
  return R.compose(
    R.concat(R.__, '%'),
    _number => _number.toFixed(1),
    R.divide(R.__, 10),
    Math.round,
    R.multiply(10)
  )(value);
};

/**
 * @function formatMonth
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into only the month IN UTC TIME
 */
export const formatMonth = value => {
  if (typeof value !== 'number') return 'N/A';

  const date = new Date(value * 1000);

  const thisMonth = months[date.getUTCMonth()];
  return thisMonth;
};

/**
 * @function formatNumberMonth
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into only the month number
 */
export const formatNumberMonth = value => {
  if (typeof value !== 'number') return 'N/A';

  const date = new Date(value * 1000);
  const thisNumberMonth = date.getUTCMonth();
  return thisNumberMonth;
};

/**
 * @function formatMonthAndYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the month and year separated by a dash
 */
export const formatMonthAndYear = value => {
  if (typeof value !== 'number') return 'N/A';

  const date = new Date(value * 1000);

  const year = date.getUTCFullYear().toString().substr(2, 3);
  return R.concat(`${abbrevMonth(date)}-`, year);
};

/**
 * @function formatMoc
 * @arg {integer} MOC - Asset cards MOC amount
 * @return {string} formatted MOC field with an "x" appended
 */
export const formatMoc = value => {
  if (typeof value !== 'number') return 'N/A';

  const formatMocFloats = R.compose(
    R.concat(R.__, 'x'),
    _number => _number.toFixed(1),
    R.divide(R.__, 10),
    Math.round, // Drop all but one decimal place
    R.multiply(10)
  )(value);
  const formatMocIntegers = R.compose(R.concat(R.__, 'x'), _number => _number.toFixed())(value);
  return value % 1 !== 0 ? formatMocFloats : formatMocIntegers;
};

/**
 * @function formatQuarterMonths
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the quarter and month
 */
export const formatQuarterMonths = value => {
  if (typeof value !== 'number' || value === null) return 'N/A';

  const date = new Date(value * 1000);

  return `Q${Math.ceil((date.getUTCMonth() + 1) / 3)} ${date.getUTCFullYear()}`;
};

/**
 * @function formatYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {integer} formatted into only the full year
 */
export const formatYear = value => {
  if (typeof value !== 'number' || value === null) return 'N/A';

  const date = new Date(value * 1000);

  return date.getUTCFullYear();
};

/**
 * @function formatGainLoss
 * @arg {integer} percentValue - formats percent value
 * @arg {integer} dollarValue - formats dollar value
 * @arg {boolean} sign - force a leading sign on the formatted number
 * @return {string} formatted percent and dollar value for Gain/Loss values
 */
export const formatGainLoss = (percentValue, dollarValue, sign = false) => {
  const _percentValue = percentValue ?? NA;
  const _dollarValue = dollarValue ?? NA;

  return formatPercent(_percentValue, sign) === 'N/A' &&
    formatDollar(_dollarValue, sign) === 'N/A' ? (
    NA
  ) : (
    <div>
      {formatPercent(_percentValue, sign)} {formatDollar(_dollarValue, sign)}
    </div>
  );
};

/**
 * @function formatLineGraphYAxis
 * @arg {integer} value - report datapoint value
 * @return {array} with the max and min values to create a buffer around the actual data values
 */
export const formatLineGraphYAxis = value => {
  if (typeof value !== 'number' || value === null) return 'N/A';

  return [value + value / 30, value - value / 30];
};

/**
 * @function findLineGraphMaxValue
 * @arg {array} graphData - report graph data
 * @return {integer} - max report value
 */
export const findLineGraphMaxValue = graphData => {
  const dataValues = R.map(dataPoint => dataPoint.value)(graphData);
  return R.reduce(
    (accumulatedValue, currentValue) => {
      return Math.max(accumulatedValue, currentValue);
    },
    dataValues[0],
    dataValues
  );
};

/**
 * @function formatAbbrevMonth
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the abbreviated month
 */
export const formatAbbrevMonth = (value, timePeriod, first = false) => {
  if (typeof value !== 'number' || typeof timePeriod !== 'string') return NA;
  const date = new Date(value * 1000);

  const year = date.getUTCFullYear().toString();

  switch (timePeriod) {
    case 'FYTD':
    case 'CYTD':
    case '1YR AACR':
      if (first || abbrevMonth(date) === 'JAN') {
        return `${abbrevMonth(date)} ${year}`;
      }
      return abbrevMonth(date);
    default:
      return abbrevMonth(date);
  }
};

/**
 * @function mappedGraphDataByAbbrevMonth
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} mapped array of formatted integer dates (x-axis) with 3-letter month abbreviations and year
 */
export const mappedGraphDataByAbbrevMonth = (reportData, timePeriod) =>
  R.map(dataPoint => {
    return {
      name: formatAbbrevMonth(dataPoint.date, timePeriod, false),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(reportData);

/**
 * @function mappedFirstDataPointByAbbrevMonth
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} formatted only the first data-point value (x-axis) with 3-letter month abbreviations and year
 */
export const mappedFirstDataPointByAbbrevMonth = (reportData, timePeriod) => {
  return {
    name: formatAbbrevMonth(reportData[0].date, timePeriod, true),
    value: reportData[0].value,
    yAxis: formatLineGraphYAxis(reportData[0].value)
  };
};

/**
 * @function mappedGraphDataByAbbrevMonthWithoutFirstData
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} mapped array of formatted integer dates with 3-letter month abbreviations without first data-point
 */
export const mappedGraphDataByAbbrevMonthWithoutFirstData = (reportData, timePeriod) =>
  R.map(dataPoint => {
    return {
      name: formatAbbrevMonth(dataPoint.date, timePeriod),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(reportData.slice(1));

/**
 * @function mappedFirstDataPointByMonthAndYear
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} formatted only the first data-point value (x-axis) with monthly format
 */
export const mappedFirstDataPointByMonthAndYear = (reportData, timePeriod) => {
  return {
    name: formatMonthAndYear(reportData[0].date, timePeriod, true),
    value: reportData[0].value,
    yAxis: formatLineGraphYAxis(reportData[0].value)
  };
};

/**
 * @function mappedLastDataPointByMonthAndYear
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} formatted only the last data-point value (x-axis) with monthly format
 */
export const mappedLastDataPointByMonthAndYear = (reportData, timePeriod) => {
  return {
    name: formatMonthAndYear(reportData[reportData.length - 1].date, timePeriod, true),
    value: reportData[reportData.length - 1].value,
    yAxis: formatLineGraphYAxis(reportData[reportData.length - 1].value)
  };
};

/**
 * @function mappedGraphDataByMonthAndYearWithoutFirstData
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} mapped array of formatted integer dates with monthly format without first data-point
 */
export const mappedGraphDataByYearWithoutFirstData = (reportData, timePeriod) =>
  R.map(dataPoint => {
    return {
      name: formatYear(dataPoint.date, timePeriod),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(reportData.slice(1));

/**
 * @function mappedGraphDataByYearWithoutLastData
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} mapped array of formatted integer dates with monthly format without last data-point
 */
export const mappedGraphDataByYearWithoutLastData = (reportData, timePeriod) =>
  R.map(dataPoint => {
    return {
      name: formatYear(dataPoint.date, timePeriod),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(reportData.slice(0, reportData.length - 1));

/**
 * @function mappedGraphDataByYearWithoutFirstAndLastData
 * @arg {array} reportData - hero and line graph report data
 * @arg {string} timePeriod - current time span
 * @return {object} mapped array of formatted integer dates with monthly format without first and last data-point
 */
export const mappedGraphDataByYearWithoutFirstAndLastData = (reportData, timePeriod) =>
  R.map(dataPoint => {
    return {
      name: formatYear(dataPoint.date, timePeriod),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(reportData.slice(1, reportData.length - 1));

/**
 * @function findPortfolioLength
 * @arg {integer} inceptionDate - client inception date
 * @arg {array} reportData - hero and line graph report data
 * @return {integer} length of portfolio in months
 */
export const findPortfolioLength = (inceptionDate, reportData) => {
  if (
    typeof inceptionDate !== 'number' ||
    inceptionDate === null ||
    typeof reportData !== 'object' ||
    reportData === null
  )
    return NA;

  const numOfMonths =
    (formatYear(reportData[reportData.length - 1].date) - formatYear(inceptionDate)) * 12 +
    (formatNumberMonth(reportData[reportData.length - 1].date) - formatNumberMonth(inceptionDate));
  return numOfMonths;
};

/**
 * @function formatLineGraphXAxis
 * @arg {array} reportData - hero and line graph report data
 * @return {object} mapped array of integer dates (x-axis) and market values (y-axis)
 */
export const formatLineGraphXAxis = (
  graphReportData,
  typeOfGraph,
  selectedTimespan,
  inceptionDate
) => {
  if (graphReportData === null || typeof graphReportData !== 'object') return NA;

  const quarterEndMonths = ['March', 'June', 'September', 'December'];

  const mappedGraphDataByMonth = R.map(dataPoint => {
    return {
      name: formatMonth(dataPoint.date),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedFull1YRGraphData = [
    mappedFirstDataPointByAbbrevMonth(graphReportData, selectedTimespan),
    ...mappedGraphDataByAbbrevMonthWithoutFirstData(graphReportData, selectedTimespan)
  ];

  const mappedGraphDataByMonthAndYear = R.map(dataPoint => {
    return {
      name: formatMonthAndYear(dataPoint.date),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedQuarterEndData = R.map(dataPoint => {
    return {
      name: formatQuarterMonths(dataPoint.date),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedHeroGraphDates = R.map(dataPoint => {
    return formatQuarterMonths(dataPoint.date);
  })(graphReportData);

  mappedHeroGraphDates.splice(0, 1, formatMonthAndYear(graphReportData[0].date));
  mappedHeroGraphDates.splice(
    graphReportData.length - 1,
    1,
    formatMonthAndYear(graphReportData[graphReportData.length - 1].date)
  );

  const mappedNonQuarterEndData = R.map(dataPoint => {
    return {
      name: mappedHeroGraphDates,
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedDecEndData = R.map(dataPoint => {
    return {
      name: formatYear(dataPoint.date),
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedHeroGraph7AndMoreYrAACRDates = R.map(dataPoint => {
    return formatYear(dataPoint.date);
  })(graphReportData);

  mappedHeroGraph7AndMoreYrAACRDates.splice(0, 1, formatMonthAndYear(graphReportData[0].date));
  mappedHeroGraph7AndMoreYrAACRDates.splice(
    graphReportData.length - 1,
    1,
    formatMonthAndYear(graphReportData[graphReportData.length - 1].date)
  );

  const mappedNonDecEndData = R.map(dataPoint => {
    return {
      name: mappedHeroGraph7AndMoreYrAACRDates,
      value: dataPoint.value,
      yAxis: formatLineGraphYAxis(dataPoint.value)
    };
  })(graphReportData);

  const mappedNoDecFirstGraphData = [
    mappedFirstDataPointByMonthAndYear(graphReportData, selectedTimespan),
    ...mappedGraphDataByYearWithoutFirstData(graphReportData, selectedTimespan)
  ];

  const mappedNoDecLastGraphData = [
    ...mappedGraphDataByYearWithoutLastData(graphReportData, selectedTimespan),
    mappedLastDataPointByMonthAndYear(graphReportData, selectedTimespan)
  ];

  const mappedNoDecEndsGraphData = [
    mappedFirstDataPointByMonthAndYear(graphReportData, selectedTimespan),
    ...mappedGraphDataByYearWithoutFirstAndLastData(graphReportData, selectedTimespan),
    mappedLastDataPointByMonthAndYear(graphReportData, selectedTimespan)
  ];

  const portfolioLength = findPortfolioLength(inceptionDate, graphReportData);

  switch (typeOfGraph) {
    case 'hero-graph':
    case 'line-graph':
      switch (selectedTimespan) {
        case 'CYTD':
        case '1YR AACR':
        case 'FYTD':
          return mappedFull1YRGraphData;
        case '3YR AACR':
          if (
            quarterEndMonths.includes(mappedGraphDataByMonth[0].name) &&
            quarterEndMonths.includes(
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name
            )
          ) {
            return mappedQuarterEndData;
          }
          return mappedNonQuarterEndData;
        case '5YR AACR':
          if (
            quarterEndMonths.includes(mappedGraphDataByMonth[0].name) &&
            quarterEndMonths.includes(
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name
            )
          ) {
            return mappedQuarterEndData;
          }
          return mappedNonQuarterEndData;
        case '7YR AACR':
        case '10YR AACR':
        case '20YR AACR':
          if (
            mappedGraphDataByMonth[0].name === 'December' &&
            mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name === 'December'
          ) {
            return mappedDecEndData;
          }
          return mappedNonDecEndData;
        case 'ITD':
          if (portfolioLength > 14 && portfolioLength <= 36) {
            if (
              quarterEndMonths.includes(mappedGraphDataByMonth[0].name) &&
              quarterEndMonths.includes(
                mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name
              )
            ) {
              return mappedQuarterEndData;
            }
            return mappedNonQuarterEndData;
          }
          if (portfolioLength > 36 && portfolioLength <= 60) {
            if (
              quarterEndMonths.includes(mappedGraphDataByMonth[0].name) &&
              quarterEndMonths.includes(
                mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name
              )
            ) {
              return mappedQuarterEndData;
            }
            return mappedNonQuarterEndData;
          }
          if (portfolioLength > 60) {
            if (
              mappedGraphDataByMonth[0].name === 'December' &&
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name === 'December'
            ) {
              return mappedDecEndData;
            }
            if (
              mappedGraphDataByMonth[0].name === 'December' &&
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name !== 'December'
            ) {
              return mappedNoDecLastGraphData;
            }
            if (
              mappedGraphDataByMonth[0].name !== 'December' &&
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name === 'December'
            ) {
              return mappedNoDecFirstGraphData;
            }
            if (
              mappedGraphDataByMonth[0].name !== 'December' &&
              mappedGraphDataByMonth[mappedGraphDataByMonth.length - 1].name !== 'December'
            ) {
              return mappedNoDecEndsGraphData;
            }
          }
          return mappedGraphDataByMonthAndYear;
        default:
          return mappedGraphDataByMonthAndYear;
      }
    default:
      return null;
  }
};

/**
 * @function formatYearWithCYTD
 * @arg {integer} dateValue - epoch time in seconds
 * @return {integer} formatted into only the full year or full year and 'CYTD'
 */

export const formatYearWithCYTD = value => {
  if (formatYear(value) === currentYear) {
    return `${currentYear} CYTD`;
  }
  return formatYear(value).toString();
};

/**
 * @function formatYearWithCYTDAndAsOfDate
 * @arg {integer} dateValue - epoch time in seconds
 * @return {integer} formatted into only the full year or full year and 'CYTD' based on asOfDate
 */

export const formatYearWithCYTDAndAsOfDate = (effectiveDate, value) => {
  const currentEffectiveMonth = formatMonth(effectiveDate);
  const currentEffectiveYear = formatYear(effectiveDate);

  if (formatYear(value) === NA && currentEffectiveYear === NA) {
    return NA;
  }

  if (currentEffectiveMonth !== 'December' && formatYear(value) === currentEffectiveYear) {
    return `${currentEffectiveYear} CYTD`;
  }
  return formatYear(value).toString();
};

/**
 * @function formatAbbrevYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {integer} formatted into abbreviated year ex: '21
 */

export const formatAbbrevYear = value => {
  if (typeof value !== 'number') return NA;
  return `'${formatYear(value).toString().slice(2)}`;
};

/**
 * @function formatAbbrevMonthAndYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the abbreviated month and year
 */
export const formatAbbrevMonthAndYear = (value, first = false) => {
  if (typeof value !== 'number') return NA;

  const date = new Date(value * 1000);

  const year = date.getUTCFullYear().toString();
  if (abbrevMonth(date) === 'JAN' || first) {
    return `${abbrevMonth(date)} ${year}`;
  }
  return abbrevMonth(date);
};

/**
 * @function formatQuarterAndYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the abbreviated quarter and year
 */
export const formatQuarterAndYear = value => {
  if (typeof value !== 'number') return 'N/A';

  const quarter = formatQuarterMonths(value);
  if (quarter.split(' ')[0] === 'Q1') {
    return quarter;
  }
  return quarter.split(' ')[0];
};

/**
 * @function formatAbbrevQuarterAndYear
 * @arg {integer} dateValue - epoch time in seconds
 * @return {string} formatted into the quarter month number and abbreviated year
 */
export const formatAbbrevQuarterAndYear = (value, toolTipLabel) => {
  if (typeof value !== 'number') return 'N/A';
  const date = new Date(value * 1000);
  const quarter = Math.ceil((date.getUTCMonth() + 1) / 3);
  if (quarter === 1 || toolTipLabel) {
    return `${quarter} ${formatAbbrevYear(value)}`;
  }
  return `${quarter}`;
};

/**
 * @function yAxisMinMax
 * @arg {object} reportDataPoint - data point from reportData array
 * @return {array} - array of 2 value to ensure y-axis height dynamically adjusts to 10% above and below the min and max values
 */
export const yAxisMinMax = dataPoint => {
  if (!dataPoint || !dataPoint.perYear) return 'N/A';
  return [
    dataPoint.perYear.distribution + dataPoint.perYear.distribution / 10,
    dataPoint.perYear.capitalCalls - dataPoint.perYear.capitalCalls / -10
  ];
};

/**
 * @function formatDistCCGraphXAxis
 * @arg {array} reportData - hero and portfolioPage graph report data
 * @return {object} mapped array of integer dates (x-axis), distributions, capital calls, and market values (y-axis)
 */
export const formatDistCCGraphXAxis = (
  graphReportData,
  typeOfGraph,
  displayMarketValue,
  selectedTimespan,
  currentEffectiveDate,
  inceptionDate
) => {
  if (graphReportData === null || typeof graphReportData !== 'object') return NA;

  const quarterEndMonths = [2, 5, 8, 11];
  const currentMonth = new Date(
    graphReportData[graphReportData.length - 1].date * 1000
  ).getUTCMonth();

  const mappedGraphDataByQuarterYear = R.map(dataPoint => {
    return {
      date: formatQuarterAndYear(dataPoint.date),
      ...dataPoint.perYear,
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      minMax: yAxisMinMax(dataPoint),
      toolTipLabel: `${formatQuarterMonths(dataPoint.date)} Quarterly`,
      toolTipOffset: 280
    };
  })(graphReportData);

  const mappedGraphDataByYear = R.map(dataPoint => {
    return {
      date: formatYearWithCYTD(dataPoint.date),
      ...dataPoint.perYear,
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      minMax: yAxisMinMax(dataPoint),
      toolTipLabel: `${formatYearWithCYTD(dataPoint.date)} Annual`,
      toolTipOffset: formatYearWithCYTD(dataPoint.date).includes('CYTD') ? 280 : 250
    };
  })(graphReportData);

  const mappedGraphDataByYearWithCurrentEffectiveDate = R.map(dataPoint => {
    return {
      date: formatYearWithCYTDAndAsOfDate(currentEffectiveDate, dataPoint.date),
      ...dataPoint.perYear,
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      minMax: yAxisMinMax(dataPoint),
      toolTipLabel: `${formatYearWithCYTD(dataPoint.date)} Annual`,
      toolTipOffset: formatYearWithCYTD(dataPoint.date).includes('CYTD') ? 280 : 250
    };
  })(graphReportData);

  const mappedGraphDataQuarter = R.map(dataPoint => {
    return {
      date: formatQuarterAndYear(dataPoint.date),
      ...dataPoint.perYear,
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      minMax: yAxisMinMax(dataPoint),
      toolTipLabel: `${formatQuarterMonths(dataPoint.date)} Quarterly`,
      toolTipOffset: 280
    };
  })(graphReportData.slice(0, graphReportData.length - 1));

  const mappedGraphDataNonQuarterLast = {
    date: `${formatQuarterAndYear(graphReportData[graphReportData.length - 1].date)} QTD`,
    ...graphReportData[graphReportData.length - 1].perYear,
    marketValue: displayMarketValue
      ? graphReportData[graphReportData.length - 1].marketValue
      : null,
    minMax: yAxisMinMax(graphReportData[graphReportData.length - 1]),
    toolTipLabel: `${formatQuarterMonths(
      graphReportData[graphReportData.length - 1].date
    )} QTD Quarterly`,
    toolTipOffset: 312
  };

  const mappedGraphDataByQuarterYearNonQuarterEnd = [
    ...mappedGraphDataQuarter,
    mappedGraphDataNonQuarterLast
  ];

  const mappedGraphDataCYTDFirst = {
    date: formatAbbrevMonthAndYear(graphReportData[0].date, true),
    ...graphReportData[0].perYear,
    marketValue: displayMarketValue ? graphReportData[0].marketValue : null,
    minMax: yAxisMinMax(graphReportData[0]),
    toolTipLabel: `${formatAbbrevMonthAndYear(graphReportData[0].date, true)} Monthly`,
    toolTipOffset: 280
  };

  const mappedGraphDataCYTD = R.map(dataPoint => {
    return {
      date: formatAbbrevMonthAndYear(dataPoint.date),
      ...dataPoint.perYear,
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      minMax: yAxisMinMax(dataPoint),
      toolTipLabel: `${formatAbbrevMonthAndYear(dataPoint.date, true)} Monthly`,
      toolTipOffset: 280
    };
  })(graphReportData.slice(1));

  const mappedGraphDataAbbrevMonthYear = [mappedGraphDataCYTDFirst, ...mappedGraphDataCYTD];

  const portfolioLength = findPortfolioLength(inceptionDate, graphReportData);

  switch (typeOfGraph) {
    case 'assetCard-graph':
      return mappedGraphDataAbbrevMonthYear;

    case 'hero-graph':
      switch (selectedTimespan) {
        case '3YR AACR':
        case '5YR AACR':
          if (quarterEndMonths.includes(currentMonth)) {
            return mappedGraphDataByQuarterYear;
          }
          return mappedGraphDataByQuarterYearNonQuarterEnd;
        case '7YR AACR':
        case '10YR AACR':
        case '20YR AACR':
          return mappedGraphDataByYearWithCurrentEffectiveDate;
        case 'ITD':
          if (portfolioLength < 15) {
            return mappedGraphDataAbbrevMonthYear;
          }
          if (portfolioLength > 14 && portfolioLength < 61) {
            if (quarterEndMonths.includes(currentMonth)) {
              return mappedGraphDataByQuarterYear;
            }
            return mappedGraphDataByQuarterYearNonQuarterEnd;
          }
          if (portfolioLength > 60) {
            return mappedGraphDataByYearWithCurrentEffectiveDate;
          }
          return mappedGraphDataByYear;
        default:
          return mappedGraphDataAbbrevMonthYear;
      }
    default:
      return null;
  }
};

export const formatDistCCGraphXAxisAssetCard = (
  graphReportData,
  selectedTimespan,
  inceptionDate,
  asOfDate,
  displayMarketValue
) => {
  if (graphReportData === null || typeof graphReportData !== 'object') return 'N/A';

  const quarterEndMonths = [2, 5, 8, 11];
  const currentMonth = new Date(
    graphReportData[graphReportData.length - 1].date * 1000
  ).getUTCMonth();

  const asOfDateYear = new Date(asOfDate * 1000).getUTCFullYear();

  const mappedGraphDataByYear = R.map(dataPoint => {
    return {
      date: formatYearWithCYTDAndAsOfDate(asOfDate, dataPoint.date),
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      toolTipLabel: formatYearWithCYTDAndAsOfDate(asOfDate, dataPoint.date),
      ...dataPoint.perYear,
      epoch: dataPoint.date
    };
  })(graphReportData);

  const mappedEveryOtherITDGraphData = graphReportData.filter((data, index) => {
    return index % 2 === 0;
  });

  /**
   * appended last data-point from graphReportData displaying current year
   * when mappedEveryOtherITDGraphData doesn't contain current year data
   */
  const mappedEveryOtherITDDataWithAsOfDateYearAsLastDataPoint = [
    ...mappedEveryOtherITDGraphData,
    graphReportData[graphReportData.length - 1]
  ];

  /**
   * @param {*} lastValue - last data-point of mappedEveryOtherITDGraphData
   * @returns the correct mapping of every other data which always contains the current year as the last data-point
   */
  const checkITDLastDataPoint = lastValue => {
    if (formatYear(lastValue) === asOfDateYear) {
      return mappedEveryOtherITDGraphData;
    }
    if (
      formatYear(lastValue) !== asOfDateYear &&
      formatYear(graphReportData[graphReportData.length - 1].date) === asOfDateYear
    ) {
      return mappedEveryOtherITDDataWithAsOfDateYearAsLastDataPoint;
    }
    return mappedEveryOtherITDGraphData;
  };

  const mappedGraphDataByAbbrevYear = R.map(dataPoint => {
    if (formatYear(dataPoint.date) === asOfDateYear && currentMonth !== 11) {
      return {
        date: `${formatAbbrevYear(dataPoint.date)} CYTD`,
        marketValue: displayMarketValue ? dataPoint.marketValue : null,
        toolTipLabel: formatYearWithCYTDAndAsOfDate(asOfDate, dataPoint.date),
        ...dataPoint.perYear
      };
    }
    return {
      date: formatAbbrevYear(dataPoint.date),
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      toolTipLabel: formatYearWithCYTDAndAsOfDate(asOfDate, dataPoint.date),
      ...dataPoint.perYear
    };
  })(
    (graphReportData.length >= 16 && selectedTimespan === 'ITD') || selectedTimespan === '20YR AACR'
      ? checkITDLastDataPoint(
          mappedEveryOtherITDGraphData[mappedEveryOtherITDGraphData.length - 1].date
        )
      : graphReportData
  );

  const mappedGraphDataByQuarterYear = R.map(dataPoint => {
    return {
      date: formatAbbrevQuarterAndYear(dataPoint.date),
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      toolTipLabel: `Q${formatAbbrevQuarterAndYear(dataPoint.date, true)}`,
      ...dataPoint.perYear
    };
  })(graphReportData);

  const mappedGraphDataQuarter = R.map(dataPoint => {
    return {
      date: formatAbbrevQuarterAndYear(dataPoint.date),
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      toolTipLabel: `Q${formatAbbrevQuarterAndYear(dataPoint.date, true)}`,
      ...dataPoint.perYear
    };
  })(graphReportData.slice(0, graphReportData.length - 1));

  const mappedGraphDataNonQuarterLast = {
    date: `${formatAbbrevQuarterAndYear(graphReportData[graphReportData.length - 1].date)}*`,
    marketValue: displayMarketValue
      ? graphReportData[graphReportData.length - 1].marketValue
      : null,
    toolTipLabel: `Q${formatAbbrevQuarterAndYear(
      graphReportData[graphReportData.length - 1].date,
      true
    )} QTD`,
    ...graphReportData[graphReportData.length - 1].perYear
  };

  const mappedGraphDataByQuarterYearNonQuarterEnd = [
    ...mappedGraphDataQuarter,
    mappedGraphDataNonQuarterLast
  ];

  const mappedGraphDataCYTDFirst = {
    date: formatAbbrevMonthAndYear(graphReportData[0].date, true),
    marketValue: displayMarketValue ? graphReportData[0].marketValue : null,
    toolTipLabel: formatAbbrevMonthAndYear(graphReportData[0].date, true),
    ...graphReportData[0].perYear
  };

  const mappedGraphDataCYTD = R.map(dataPoint => {
    return {
      date: formatAbbrevMonthAndYear(dataPoint.date),
      marketValue: displayMarketValue ? dataPoint.marketValue : null,
      toolTipLabel: formatAbbrevMonthAndYear(dataPoint.date),
      ...dataPoint.perYear
    };
  })(graphReportData.slice(1));

  const mappedGraphDataAbbrevMonthYear = [mappedGraphDataCYTDFirst, ...mappedGraphDataCYTD];

  const portfolioLength = findPortfolioLength(inceptionDate, graphReportData);

  switch (selectedTimespan) {
    case '3YR AACR':
    case '5YR AACR':
      if (quarterEndMonths.includes(currentMonth)) {
        return mappedGraphDataByQuarterYear;
      }
      return mappedGraphDataByQuarterYearNonQuarterEnd;
    case '7YR AACR':
      return mappedGraphDataByYear;
    case '10YR AACR':
      return mappedGraphDataByAbbrevYear;
    case '20YR AACR':
      return mappedGraphDataByAbbrevYear;
    case 'ITD':
      if (portfolioLength < 15) {
        return mappedGraphDataAbbrevMonthYear;
      }
      if (portfolioLength > 14 && portfolioLength < 61) {
        if (quarterEndMonths.includes(currentMonth)) {
          return mappedGraphDataByQuarterYear;
        }
        return mappedGraphDataByQuarterYearNonQuarterEnd;
      }
      if (portfolioLength > 60) {
        return mappedGraphDataByAbbrevYear;
      }
      return mappedGraphDataByAbbrevYear;
    default:
      return mappedGraphDataAbbrevMonthYear;
  }
};

/**
 * @function getTextWidth
 *  Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text - The text to be rendered.
 * @param {String} font - The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function getTextWidth(text, font = getCanvasFontSize()) {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

/**
 * @function forceUTCFormat
 * NOTE: We do this to support testing and avoid date mismatches
 * @param {date} -- incoming date; number or valid date string
 * @return {date} -- current date with no timezone offset
 */

/* eslint-disable-next-line no-unused-vars */
const forceUTCFormat = date => new Date(date).toUTCString();

/**
 * @functioncreateMonthEndEpochDate
 * @param {date} - incoming date
 * @return {string} - month-end epochDate in UTC time format
 */
export const createMonthEndEpochDate = date => {
  return R.compose(createEpochDate, formatDateWithLastDayOfMonth)(new Date(date));
};

/**
 * @function formatHistoricalAssetAllocationData
 * @arg {array} allocationData - incoming list of allocation data
 * @arg {integer} inceptionDate - incoming inception date
 * @return {array} - reformatted list of allocation data based on inception date
 */
export const formatHistoricalAssetAllocationData = (allocationData, inceptionDate, asOfDate) => {
  let asOfDateIndex;
  for (let i = allocationData.length - 1; i >= 0; i--) {
    if (allocationData[i].date === asOfDate) {
      asOfDateIndex = i;
      break;
    }
  }
  const slicedAllocationData = R.slice(0, asOfDateIndex + 1, allocationData);
  const portfolioLength = findPortfolioLength(inceptionDate, slicedAllocationData);
  const quarterEndMonths = [2, 5, 8, 11];
  const currentMonth = new Date(asOfDate * 1000).getUTCMonth();

  const mapAssetValues = assets => {
    const mappedAssets = {};
    const mappedMarketValues = {};
    let sum = 0;
    assets.forEach(asset => {
      const percentValue = asset.percent;
      const mvValue = asset.marketValue;
      mappedMarketValues[asset.assetClass] = mvValue;
      mappedAssets[asset.assetClass] = percentValue;
      sum += mvValue;
    });
    mappedAssets.marketValues = mappedMarketValues;
    mappedAssets.allocationTotals = sum;
    mappedAssets.yAxis = [0, 100];
    return mappedAssets;
  };

  const formatMonthly = data => {
    const formattedMonthlyDataFirstMappedAssets = mapAssetValues(data[0].assets);
    const formattedMonthlyDataFirst = {
      displayName: formatAbbrevMonthAndYear(data[0].date, true),
      ...formattedMonthlyDataFirstMappedAssets
    };
    const formattedMonthlyData = data.slice(1).map(allocationsByDate => {
      const formattedAssets = mapAssetValues(allocationsByDate.assets);
      return {
        displayName: formatAbbrevMonthAndYear(allocationsByDate.date),
        ...formattedAssets
      };
    });
    return [formattedMonthlyDataFirst, ...formattedMonthlyData];
  };

  const formatAnnual = data => {
    return data.map(allocationsByDate => {
      const formattedAssets = mapAssetValues(allocationsByDate.assets);
      return {
        displayName: formatYear(allocationsByDate.date),
        ...formattedAssets
      };
    });
  };

  const formatQuarterly = data => {
    return data.map(allocationsByDate => {
      const formattedAssets = mapAssetValues(allocationsByDate.assets);
      return {
        displayName: formatQuarterAndYear(allocationsByDate.date),
        ...formattedAssets
      };
    });
  };

  if (portfolioLength < 15) {
    // format by month
    return formatMonthly(slicedAllocationData);
  }
  if (portfolioLength > 14 && portfolioLength < 61) {
    // format by quarter
    const isQuarterEnd = datapoint => {
      const month = new Date(datapoint.date * 1000).getUTCMonth();
      return quarterEndMonths.includes(month);
    };
    const filteredData = R.filter(isQuarterEnd, slicedAllocationData);
    if (quarterEndMonths.includes(currentMonth)) {
      // quarter end month
      return formatQuarterly(filteredData);
    }
    const lastNonQuarterEndDataPoint = slicedAllocationData[slicedAllocationData.length - 1];
    const formattedAssetsNonQuarterEnd = mapAssetValues(lastNonQuarterEndDataPoint.assets);
    const date = new Date(lastNonQuarterEndDataPoint.date * 1000);
    const year = date.getUTCFullYear().toString();
    const lastNonQuarterEndDataPointMapped = {
      displayName: `${abbrevMonth(date)} ${year}`,
      ...formattedAssetsNonQuarterEnd
    };
    const quarterlyDataMapped = formatQuarterly(filteredData);
    return [...quarterlyDataMapped, lastNonQuarterEndDataPointMapped];
  }
  if (portfolioLength > 60) {
    // format by year
    const isDecember = datapoint => {
      const month = new Date(datapoint.date * 1000).getUTCMonth();
      return month === 11;
    };

    let filteredData = R.filter(isDecember, slicedAllocationData);

    if (portfolioLength > 180) {
      filteredData = filteredData.filter((data, index) => {
        return index % 2 === 0; // get every other year
      });
    }

    if (currentMonth !== 11) {
      const lastAnnualDataPoint = slicedAllocationData[slicedAllocationData.length - 1];
      const formattedAssetsAnnual = mapAssetValues(lastAnnualDataPoint.assets);
      const date = new Date(lastAnnualDataPoint.date * 1000);
      const year = date.getUTCFullYear().toString();
      const lastAnnualDataPointMapped = {
        displayName: `${abbrevMonth(date)} ${year}`,
        ...formattedAssetsAnnual
      };
      const annualDataMapped = formatAnnual(filteredData);
      return [...annualDataMapped, lastAnnualDataPointMapped];
    }

    if (formatYear(filteredData[filteredData.length - 1].date) !== formatYear(asOfDate)) {
      filteredData.push(slicedAllocationData[slicedAllocationData.length - 1]);
    }

    return formatAnnual(filteredData);
  }
  return formatAnnual(slicedAllocationData);
};
