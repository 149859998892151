import React, {useContext} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {AppContext} from '../../../../../context/AppContext';
import AssetClassCard from './AssetCard';
import {setRenderError} from '../../../../../utils/errorHelpers';

const StyledAssetCardsContainer = styled.div`
  display: grid;
  grid-auto-rows: minmax(330px, auto);
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 15px;
  column-gap: 25px;
`;

const ClassCards = ({cardsData, cardsGraphData, testingInception}) => {
  const {dispatch} = useContext(AppContext);
  const page = 'portfolioPage';
  if (R.isNil(cardsData)) setRenderError(dispatch, `[${page}:ClassCards] No cardsData`);

  if (R.isNil(cardsGraphData)) setRenderError(dispatch, `[${page}:ClassCards] No cardsGraphData`);

  return (
    <div data-testid="asset-class-card-view" className="asset-class-card-view">
      <StyledAssetCardsContainer>
        {cardsData.map(card => {
          return (
            <AssetClassCard
              key={card.key}
              assetCardData={card}
              assetCardGraphData={cardsGraphData}
              testingInception={testingInception}
            />
          );
        })}
      </StyledAssetCardsContainer>
    </div>
  );
};

ClassCards.propTypes = {
  cardsData: PropTypes.array,
  cardsGraphData: PropTypes.array,
  testingInception: PropTypes.number
};

export default ClassCards;
