import {useEffect, useState} from 'react';

const getDimensions = myRef => ({
  width: myRef.current.offsetWidth,
  height: myRef.current.offsetHeight
});

export function useComponentSize(myRef) {
  // Todo: this needs to be fixed to accurately set default size
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(myRef));
    };

    // Never enters this condition
    if (myRef.current) {
      setDimensions(getDimensions(myRef));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
}
