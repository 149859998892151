import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as R from 'ramda';
import {AppContext} from '../../../context/AppContext';
import AttributionBarGraph from '../../../components/AttributionBarGraph';
import {classOrder} from '../../../utils/assetClassProperties.data';
import {assetClassProperties} from '../../../utils/assetClassProperties';
import {setRenderError} from '../../../utils/errorHelpers';
import {
  labelPosition,
  sumNegativeNumbers,
  sumPositiveNumbers
} from '../../../utils/labelPositioner';

const StyledAttributionContainer = styled.div`
  margin-top: 35px;
  padding: 40px;
  margin-bottom: 20px;
  & .weighted-contribution {
    padding-left: 25px;
  }
  & .total-return {
    padding-right: 25px;
  }
`;
const StyledAttributionGraphs = styled.div`
  display: flex;
`;
const StyledGraph = styled.div`
  width: 50%;
`;
const StyledAttributionLegend = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const ColoredLegendSquare = styled.div`
  margin-right: 10px;
  height: 12px;
  width: 12px;
  background-color: ${props => assetClassProperties(props.assetClass).colorCode};
`;

const LegendAssetName = styled.div`
  margin-right: 22px;
`;

const Attribution = ({periodsData}) => {
  const {dispatch} = useContext(AppContext);

  const page = 'portfolioPage';

  if (R.isNil(periodsData) || R.isEmpty(periodsData))
    setRenderError(dispatch, `[${page}:Attribution] No Periods Data`);

  const periodGains = R.ifElse(
    R.isNil,
    R.always(null),
    R.always(
      R.map(period => {
        const gainsByAsset = R.compose(
          R.map(asset => {
            return asset?.gains ?? null;
          }),
          R.indexBy(R.prop('assetClass'))
        )(period?.attribution || []);
        const gainsTotals = R.compose(R.sum, R.values)(gainsByAsset);
        const gainsPercentByAsset = R.compose(
          R.map(asset => {
            return (asset?.gains / gainsTotals) * 100 ?? null;
          }),
          R.indexBy(R.prop('assetClass'))
        )(period?.attribution || []);
        const minValue = sumNegativeNumbers([...Object.values(gainsByAsset)]);
        const maxValue = sumPositiveNumbers([...Object.values(gainsByAsset)]);
        return {
          displayName: R.includes('YR', period.displayName)
            ? `${period?.displayName?.split('Y')[0]} YR`
            : period?.displayName,
          code: period?.code,
          ...gainsByAsset,
          gainsPercent: gainsPercentByAsset,
          gainsTotals,
          yAxis: [minValue, maxValue],
          labelPositions: labelPosition(gainsByAsset, gainsTotals)
        };
      })
    )(periodsData)
  )(periodsData);

  const periodWeightedContribution = R.ifElse(
    R.isNil,
    R.always(null),
    R.always(
      R.map(period => {
        const weightedContributionByAsset = R.compose(
          R.map(asset => {
            return asset?.weightedContribution ?? null;
          }),
          R.indexBy(R.prop('assetClass'))
        )(period?.attribution || []);
        const weightedContributionTotals = R.compose(R.sum, R.values)(weightedContributionByAsset);
        const minValue = sumNegativeNumbers([...Object.values(weightedContributionByAsset)]);
        const maxValue = sumPositiveNumbers([...Object.values(weightedContributionByAsset)]);
        return {
          displayName: R.includes('YR', period.displayName)
            ? `${period?.displayName?.split('Y')[0]} YR`
            : period?.displayName,
          code: period?.code,
          ...weightedContributionByAsset,
          weightedContributionTotals,
          yAxis: [minValue, maxValue],
          labelPositions: labelPosition(weightedContributionByAsset, weightedContributionTotals)
        };
      })
    )(periodsData)
  )(periodsData);

  const xAxisOrder = [
    'CYTD',
    'FYTD',
    'YRAACR1',
    'YRAACR3',
    'YRAACR5',
    'YRAACR7',
    'YRAACR10',
    'YRAACR20',
    'ITD'
  ];

  const orderedperiodGains = R.sortBy(obj => {
    return R.indexOf(obj.code, xAxisOrder);
  })(periodGains || []);

  const orderedWeightedContribution = R.sortBy(obj => {
    return R.indexOf(obj.code, xAxisOrder);
  })(periodWeightedContribution || []);

  const legendAssets = R.compose(
    R.reject(asset => !R.includes(asset, classOrder)),
    R.keys,
    R.reduce((a, b) => Object.assign(a, b), {})
  )(orderedperiodGains);

  const orderedLegendAssets = R.sortBy(asset => {
    return R.indexOf(asset, classOrder);
  })(legendAssets || []);

  return (
    <StyledAttributionContainer className="component-box-shadow">
      <StyledAttributionGraphs>
        <StyledGraph className="total-return">
          <h2>Total Return Contribution Over Time</h2>
          <AttributionBarGraph type="totalReturn" graphData={orderedperiodGains} />
        </StyledGraph>
        <StyledGraph className="weighted-contribution">
          <h2>Portfolio Weighted Contribution</h2>
          <AttributionBarGraph
            type="weightedContribution"
            graphData={orderedWeightedContribution}
          />
        </StyledGraph>
      </StyledAttributionGraphs>
      <StyledAttributionLegend>
        {orderedLegendAssets.map(asset => {
          return (
            <StyledLegendItem key={asset}>
              <ColoredLegendSquare assetClass={asset} />
              <LegendAssetName className="body-small-bold">
                {assetClassProperties(asset).categoryName}
              </LegendAssetName>
            </StyledLegendItem>
          );
        })}
      </StyledAttributionLegend>
    </StyledAttributionContainer>
  );
};

Attribution.propTypes = {
  periodsData: PropTypes.array
};

export default Attribution;
