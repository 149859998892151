import {gql} from '@apollo/client';

/**
 * @function: GetQuickStatsPortfolio / GET_QUICK_STATS_PORTFOLIO
 * @arg {string} client Id
 * @arg {string} $period Id for desired QuickStats period
 * @returns {JSON} Quick Stats for that period
 */
export const GET_QUICK_STATS_PORTFOLIO = gql`
  query GET_QUICK_STATS_PORTFOLIO($id: ID!, $portfolioId: ID!, $asOfDate: ID!) {
    data: GetClientPortfolio(id: $id, portfolioId: $portfolioId, asOfDate: $asOfDate) {
      id
      entity
      displayName
      quickSummary {
        gains
        gainsPercent
        monthlyGainsPercent
        totalMarketValue
        cashAndFixedMarketValue
        cashValue
        fixedIncomeValue
        asOfDate
        assetValuationPercent
        assetValuationAsOf
        inceptionDate
        netflows
      }
    }
  }
`;
