import {gql} from '@apollo/client';

/**
 * @function GET_PORTFOLIO_PERFORMANCE_ASSET
 * NOTE that the actual query is still 'getPortfolioPerformance'
 * @arg {string} id - client id
 * @arg {string} portfolioId = portfolio id
 * @returns {object} - data for all assets performance
 */
export const GET_PORTFOLIO_PERFORMANCE_ASSET = gql`
  query GET_PORTFOLIO_PERFORMANCE_ASSET($portfolioId: ID!, $asOfDate: ID!) {
    data: GetPortfolioPerformance(portfolioId: $portfolioId, asOfDate: $asOfDate) {
      periods {
        summary {
          gains
          gainsPercent
          monthlyGainsPercent
          totalMarketValue
          report {
            data {
              date
              value
            }
          }
          netflows
        }
        displayName
        displayLabel
        code
        attribution {
          assetClass
          gains
          weightedContribution
        }
        assets {
          allocation
          assetClass
          marketValue
          gains
          gainsPercent
          monthlyGainsPercent
          weightedContribution
          ... on PortfolioCash {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            netflows
            outflows
            inflows
            accounts {
              displayName
              marketValue
              netflows
              inflows
              outflows
              gains
              gainsPercent
              inceptionDate
              allocation
            }
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
          }
          ... on PortfolioFixedIncome {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            netflows
            outflows
            inflows
            accounts {
              displayName
              marketValue
              netflows
              inflows
              outflows
              gains
              gainsPercent
              inceptionDate
              allocation
            }
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
          }
          ... on PortfolioBalancedAccounts {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
            subType1s {
              displayName
              allocation
              benchmarks {
                displayName
                gainsPercent
              }
              gains
              gainsPercent
              netflows
              ITDGains
              marketValue
              ITDGainsPercent
              report {
                reportType
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                gains
                gainsPercent
                allocation
                netflows
                marketValue
                ITDGains
                ITDGainsPercent
                inceptionDate
                report {
                  reportType
                  data {
                    date
                    value
                  }
                }
              }
            }
          }
          ... on PortfolioEquities {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            netflows
            outflows
            inflows
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
            subType1s {
              displayName
              allocation
              benchmarks {
                displayName
                gainsPercent
              }
              gains
              gainsPercent
              netflows
              ITDGains
              marketValue
              ITDGainsPercent
              report {
                reportType
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                gains
                gainsPercent
                allocation
                netflows
                marketValue
                ITDGains
                ITDGainsPercent
                inceptionDate
                report {
                  reportType
                  data {
                    date
                    value
                  }
                }
              }
            }
          }
          ... on PortfolioAlternative {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            netflows
            outflows
            inflows
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
            subType1s {
              displayName
              allocation
              benchmarks {
                displayName
                gainsPercent
              }
              gains
              gainsPercent
              netflows
              ITDGains
              marketValue
              ITDGainsPercent
              report {
                reportType
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                gains
                gainsPercent
                allocation
                netflows
                marketValue
                ITDGains
                ITDGainsPercent
                inceptionDate
                report {
                  reportType
                  data {
                    date
                    value
                  }
                }
              }
            }
          }
          ... on PortfolioHybrid {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            irr
            moc
            totalDistributionsValue
            totalDistributionsMultiplier
            totalFundingFunded
            totalFundingUnfunded
            totalFundingPercent
            asOfDate
            report {
              reportType
              data {
                date
                perYear {
                  netFlows
                  distribution
                  capitalCalls
                }
                cumulative {
                  netFlows
                  distribution
                  capitalCalls
                }
                marketValue
              }
            }
            subType1 {
              displayName
              marketValue
              gains
              gainsPercent
              allocation
              totalDistributionsValue
              moc
              irr
              totalPaidCapital
              totalPaidCapitalPercent
              totalCommittedCapital
              distributionsPerCapital
              report {
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                vintageYear
                marketValue
                report {
                  data {
                    date
                    value
                  }
                }
                asOfDate
                totalDistributionsValue
                moc
                irr
                totalPaidCapital
                totalPaidCapitalPercent
                totalCommittedCapital
                distributionsPerCapital
              }
            }
          }
          ... on PortfolioMultiAsset {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            irr
            moc
            totalDistributionsValue
            totalDistributionsMultiplier
            totalFundingFunded
            totalFundingUnfunded
            totalFundingPercent
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
            subType1s {
              displayName
              allocation
              benchmarks {
                displayName
                gainsPercent
              }
              gains
              gainsPercent
              netflows
              ITDGains
              marketValue
              ITDGainsPercent
              report {
                reportType
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                gains
                gainsPercent
                allocation
                netflows
                marketValue
                ITDGains
                ITDGainsPercent
                inceptionDate
                report {
                  reportType
                  data {
                    date
                    value
                  }
                }
              }
            }
          }
          ... on PortfolioPrivateEquities {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            irr
            moc
            totalDistributionsValue
            totalDistributionsMultiplier
            totalFundingFunded
            totalFundingUnfunded
            totalFundingPercent
            asOfDate
            report {
              reportType
              data {
                date
                perYear {
                  netFlows
                  distribution
                  capitalCalls
                }
                cumulative {
                  netFlows
                  distribution
                  capitalCalls
                }
                marketValue
              }
            }
            subType1 {
              displayName
              marketValue
              gains
              gainsPercent
              allocation
              totalDistributionsValue
              moc
              irr
              totalPaidCapital
              totalPaidCapitalPercent
              totalCommittedCapital
              distributionsPerCapital
              report {
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                vintageYear
                marketValue
                report {
                  data {
                    date
                    value
                  }
                }
                asOfDate
                totalDistributionsValue
                moc
                irr
                totalPaidCapital
                totalPaidCapitalPercent
                totalCommittedCapital
                distributionsPerCapital
              }
            }
          }
          ... on PortfolioRealAssets {
            asOfDate
            moc
            irr
            totalDistributionsValue
            totalDistributionsMultiplier
            totalFundingFunded
            totalFundingUnfunded
            totalFundingPercent
            report {
              reportType
              data {
                date
                perYear {
                  netFlows
                  distribution
                  capitalCalls
                }
                cumulative {
                  netFlows
                  distribution
                  capitalCalls
                }
                marketValue
              }
            }
            subType1 {
              displayName
              marketValue
              gains
              gainsPercent
              allocation
              totalDistributionsValue
              moc
              irr
              totalPaidCapital
              totalPaidCapitalPercent
              totalCommittedCapital
              distributionsPerCapital
              benchmarkPerformance {
                benchmark
                benchmarkName
                type
              }
              report {
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                vintageYear
                marketValue
                report {
                  data {
                    date
                    value
                  }
                }
                asOfDate
                totalDistributionsValue
                moc
                irr
                totalPaidCapital
                totalPaidCapitalPercent
                totalCommittedCapital
                distributionsPerCapital
              }
            }
          }
          ... on PortfolioOtherInvestments {
            benchmarkPerformance {
              benchmark
              benchmarkName
              type
            }
            asOfDate
            report {
              reportType
              data {
                date
                value
              }
            }
            subType1s {
              displayName
              allocation
              benchmarks {
                displayName
                gainsPercent
              }
              gains
              gainsPercent
              netflows
              ITDGains
              marketValue
              ITDGainsPercent
              report {
                reportType
                data {
                  date
                  value
                }
              }
              subType2s {
                displayName
                type
                gains
                gainsPercent
                allocation
                netflows
                marketValue
                ITDGains
                ITDGainsPercent
                inceptionDate
                report {
                  reportType
                  data {
                    date
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
