import React from 'react';
import DisclosureText from '../../components/DisclosureText';

const page = 'DisclosurePage';

const DisclosurePage = () => {
  return (
    <>
      <DisclosureText page={page} />
    </>
  );
};

export default DisclosurePage;
