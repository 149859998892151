import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import LineGraph from '../../../components/LineGraph';

const StyledHeroGraph = styled.div`
  margin-top: 30px;
  margin-bottom: 45px;
`;

const StyledGraphContainer = styled.div``;

const HeroGraph = ({reportData, testingWidth, testingInception}) => {
  if (R.isNil(reportData.data) || R.isEmpty(reportData.data)) return 'N/A';
  return (
    <div className="side-padding-50">
      <StyledHeroGraph>
        <StyledGraphContainer data-testid="alternatives-herograph">
          <LineGraph
            reportData={reportData.data}
            type="hero-graph"
            testingWidth={testingWidth}
            testingInception={testingInception}
          />
        </StyledGraphContainer>
      </StyledHeroGraph>
    </div>
  );
};

HeroGraph.propTypes = {
  reportData: PropTypes.shape({
    data: PropTypes.array
  }),
  testingWidth: PropTypes.number,
  testingInception: PropTypes.number
};

export default HeroGraph;
