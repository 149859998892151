export const assetClassLocations = {
  cash: '/cashandfixedPage',
  fixedIncome: '/cashandfixedPage',
  equities: '/equitiesPage',
  alternative: '/alternativesPage',
  hybrid: '/hybridinvestmentsPage',
  otherInvestments: '/otherinvestmentsPage',
  privateEquities: '/privateequitiesPage',
  realAssets: '/realassetsPage',
  multiAsset: '/multiassetinvestmentsPage',
  balancedAccounts: '/balancedaccountsPage',
  'Real Estate': '/realassetsPage',
  'Hard Assets': '/realassetsPage'
};
