import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {appActionTypes} from '../context/AppContext'; /* eslint-disable-line import/no-cycle */

/**
 * @function checkForPeriodData
 *  Convenience function to see if we have period data in the given array
 *  @arg {string } period - the period data to be tested
 *  @arg {array} data -- the data that should contain an entry for the given period
 *  @returns {number} periodIndex - the entry into the array or -1 if not found
 */
export const checkForPeriodData = (period, data) =>
  R.findIndex(R.propEq('displayName', period))(data);

export const NoDataMessage = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
`;

export const NA = 'N/A';

const errors = [];
export const setError = message => {
  errors.push(message);
};

/**
 * @function formatErrorMessage
 *  Format the message
 * @param {string} module name
 * @param {string} messageText
 * @param {string} period
 */
export const formatErrorMessage = (...params) => {
  if (params.length === 1) return params[0];
  const [module, message, period] = params;
  const moduleText = R.isNil(module) ? '' : `[${module}]`;
  const messageText = R.isNil(message) ? 'Error message has no content' : message;
  const periodText = R.isNil(period) ? '' : ` activePeriod = ${period}`;
  return `${moduleText} ${messageText}${periodText}`;
};

export const renderErrors = messages => {
  if (R.isNil(messages)) return <NoDataMessage>Error message has no content</NoDataMessage>;
  if (Array.isArray(messages)) {
    const errorDivs = R.map(msg => <NoDataMessage key={msg}>{msg}</NoDataMessage>)(messages);
    return <div>{errorDivs}</div>;
  }
  if (typeof messages === 'string') return <NoDataMessage>{messages}</NoDataMessage>;
  // else it's an object so we can format the message consistently
  const {module, message, period} = messages;
  return <NoDataMessage>{formatErrorMessage(module, message, period)}</NoDataMessage>;
};

/**
 * @function assignIfValid
 *  Ensure param is not nil/undefined or renderError
 * @param {object | null} objectUnderTest - data element to be tested for null
 * @param {string} module - Name of the module/component for message
 * @param {string} message - message text
 * @param {string} period - current period, if available
 */
export const assignIfValid = (data, module, message, period) => {
  return data ?? renderErrors(formatErrorMessage(module, message, period));
};

// DO NOT USE THIS FUNCTION: portfolios[0] will see diminished availability
/* eslint-disable-next-line consistent-return */
// export const checkForDataAvailable = data => {
//   if (R.isNil(data ?.data)) return renderErrors('No graphQL data');
//   // TODO -- this has to check for data in the activePeriod
//   if (R.isNil(data ?.data ?.portfolios) || R.isNil(data ?.data ?.portfolios[0]))
//     return renderErrors('No portfolio data');
// };

/**
 * @function setRenderError
 * @param {function} - dispatch
 * @param {string} - errorMessage
 * @returns {null}
 * TODO: Consider returning <GraphqlErrorHandler />
 */
export const setRenderError = (dispatch, errorMessage) => {
  dispatch({
    type: appActionTypes.SET_RENDER_ERROR,
    payload: errorMessage
  });
  // return <GraphqlErrorHandler />
  return null;
};
