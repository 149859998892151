import {gql} from '@apollo/client';

/**
 * @function GET_QUERY
 *  query: GetPeriods / GET_PERIODS
 * @arg {string} portfolio Id
 * @returns {JSON} List of periods for that portfolio
 */
export const GET_PERIODS = gql`
  query GetPeriods($portfolioId: ID!, $asOfDate: ID!) {
    data: GetPortfolioPerformance(portfolioId: $portfolioId, asOfDate: $asOfDate) {
      periods {
        code
        displayName
        displayLabel
      }
    }
  }
`;
