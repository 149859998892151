import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

// import {ResponsiveContainer, BarChart, Bar, Tooltip, Legend, XAxis, YAxis} from 'recharts';
import {formatPercent, getTextWidth} from '../../utils'; /* eslint-disable-line */
import {assetClassProperties} from '../../utils/assetClassProperties';

const legendFontSize = 13;
const legendFontWeight = 700;
const legendStyle = {fontSize: legendFontSize, fontWeight: legendFontWeight};
// const legendWidth = 96;

const CustomLegendItem = ({asset, setOpacity}) => {
  const assetProps = assetClassProperties(asset.dataKey);
  const labelValue = `${assetProps.categoryName} ${formatPercent(asset.allocation)}`;
  const labelRadius = getTextWidth(labelValue) + 28;
  return (
    <div
      role="button"
      className={`legend-item legend-${assetProps.code}`}
      style={{opacity: setOpacity(assetProps.code)}}
    >
      <svg height="16" width={labelRadius}>
        <circle cx="7" cy="5" r="5.0" fill={assetProps.colorCode} />
        <text x="16" y="10" style={{...legendStyle}}>
          &nbsp;&nbsp;
          {labelValue}
        </text>
      </svg>
    </div>
  );
};
CustomLegendItem.propTypes = {
  asset: PropTypes.shape({
    color: PropTypes.string,
    dataKey: PropTypes.string,
    value: PropTypes.string,
    allocation: PropTypes.number
  }),
  graphDataObj: PropTypes.shape({}),
  setOpacity: PropTypes.func
};

const CustomLegend = ({payload, direction, graphDataObj, setOpacity}) => {
  return (
    <ul
      style={{
        display: 'flex',
        flexDirection: direction,
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      {R.map(asset => {
        const style =
          payload.length > 8
            ? {flex: '0 1 auto', margin: '0 10px', alignContent: 'center'}
            : {
                flex: '0 1 auto',
                margin: '0 20px',
                alignContent: 'center'
              };
        // Normalize the allocation & dataKey/value element so that
        // CustomLegendItem doesn't have to worry about things...
        return (
          <>
            {/* eslint-disable-next-line */}
            <li key={asset.value} style={{...style}}>
              <CustomLegendItem
                asset={{dataKey: asset.value, allocation: graphDataObj[asset.value], ...asset}}
                setOpacity={setOpacity}
              />
            </li>
          </>
        );
      })(payload)}
    </ul>
  );
};
CustomLegend.propTypes = {
  payload: PropTypes.array,
  graphDataObj: PropTypes.shape({}),
  direction: PropTypes.string,
  setOpacity: PropTypes.func
};

export default CustomLegend;
