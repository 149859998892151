import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {Redirect} from 'react-router-dom';

import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';
import HeroGraph from './heroGraph';
import HeaderSummary from '../../components/assetClassDetails/HeaderSummary';
import SubType1PEVC from '../../components/subType1PEVC';
import {NA} from '../../utils/errorHelpers';
import ErrorHandler from '../../components/ErrorHandler';
import {setLoadingCountAssetDetailsPage} from '../../utils/utilHelpers';

const StyledRealAssetsContainer = styled.div`
  & .row-item__value {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .row-title__value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
`;

const RealAssetsPage = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedPortfolioId, selectedAsOfDate} = state;
  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountAssetDetailsPage({key: '/realassetsPage', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountAssetDetailsPage({key: '/realassetsPage', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log('QUERY ERROR: ', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'RealAssetsPage';
  if (R.isNil(data?.data)) return <ErrorHandler loggedMessage={`[${page}]: No graphQL data`} />;
  if (R.isNil(data?.data?.periods))
    return <ErrorHandler loggedMessage={`[${page}]: No periods data`} />;

  const periodsData = data.data?.periods || [];

  const findPeriod = R.findIndex(R.propEq('displayName', state.activePeriod))(periodsData);
  if (findPeriod === -1)
    return (
      <ErrorHandler
        loggedMessage={`[${page}]: No data for selected period: ${state.activePeriod}`}
      />
    );

  const assetClassData = periodsData[findPeriod];
  if (R.isNil(assetClassData.assets) || R.isEmpty(assetClassData.assets))
    return (
      <ErrorHandler loggedMessage={`[${page}]: No assets data: period ${state.activePeriod}`} />
    );

  const realAssetsData = assetClassData.assets.filter(asset => asset.assetClass === 'realAssets');
  if (R.isNil(realAssetsData) || R.isEmpty(realAssetsData)) {
    // Must be an entity switch where the new entity has no alternatives data
    // Force our way back to the portfolio page
    return <Redirect to="/portfolioPage" />;
  }

  const realAssetsAccountData = R.isEmpty(realAssetsData) ? {} : realAssetsData[0];

  const summaryData = {
    assetClass: realAssetsAccountData.assetClass || NA,
    title: 'Real Assets',
    marketValue: realAssetsAccountData.marketValue ?? NA,
    gains: realAssetsAccountData.gains ?? NA,
    gainsPercent: realAssetsAccountData.gainsPercent ?? NA,
    allocation: realAssetsAccountData.allocation ?? NA,
    moc: realAssetsAccountData.moc ?? NA,
    irr: realAssetsAccountData.irr ?? NA,
    totalFundingUnfunded: realAssetsAccountData.totalFundingUnfunded ?? NA,
    asOfDate: realAssetsAccountData.asOfDate || NA,
    donutGraphData: assetClassData.assets,
    style: 'PE/VC'
  };

  const subType1s = realAssetsAccountData?.subType1 || [];

  return (
    <StyledRealAssetsContainer>
      <div className="portfolio-box-shadow">
        <HeaderSummary summaryData={summaryData} />
        {realAssetsAccountData.report !== null ? (
          <HeroGraph
            reportData={realAssetsAccountData.report}
            asOfDate={realAssetsAccountData.asOfDate}
          />
        ) : null}
      </div>
      <div className="side-padding-50">
        {subType1s.map(subType1PEVC => {
          return (
            <SubType1PEVC data={subType1PEVC} key={subType1PEVC.displayName?.replace(' ', '-')} />
          );
        })}
      </div>
    </StyledRealAssetsContainer>
  );
};

export default RealAssetsPage;
