import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as R from 'ramda';

import {AppContext} from '../../../context/AppContext';
import {assetClassOrder, formatDollar} from '../../../utils';
import useWindowDimensions from '../../../hooks/useWindowSize';
import ClassByCategory from './ClassByCategory';
import * as COLORS from '../../../theme/colors';
import {setRenderError} from '../../../utils/errorHelpers';

const StyledAssetTotals = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledPortfolioTotals = styled.div`
  margin-left: 35px;
  display: flex;
  justify-content: space-between;
  width: 315px;
  & .total-label {
    color: ${COLORS['@neutrals-10']};
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  & .total-amount {
    color: ${COLORS['@neutrals-10']};
    font-weight: 700;
    font-size: 15px;
  }
`;

const AssetClassTotals = ({
  assetClassData,
  setDisplayHeaderAsColumn,
  displayHeaderAsColumn,
  totalMarketValueData
}) => {
  const dimensions = useWindowDimensions();

  const {dispatch} = useContext(AppContext);

  useEffect(() => {
    if (dimensions.width < 1200 && !displayHeaderAsColumn) setDisplayHeaderAsColumn(true);
    if (dimensions.width >= 1200 && displayHeaderAsColumn) setDisplayHeaderAsColumn(false);
  }, [dimensions, displayHeaderAsColumn, setDisplayHeaderAsColumn]);

  if (R.isNil(assetClassData))
    setRenderError(dispatch, `[${page}:AssetClassTotals] No assetClassData`);
  if (R.isNil(assetClassData.assets) || R.isEmpty(assetClassData.assets))
    setRenderError(dispatch, `[${page}:AssetClassTotals] No assets for selected period`);

  const liquid = ['cash', 'fixedIncome', 'balancedAccounts', 'equities', 'alternative'];

  const nonLiquid = ['otherInvestments', 'hybrid', 'multiAsset', 'privateEquities', 'realAssets'];

  const orderedAssets = R.sortBy(obj => {
    return R.indexOf(obj.assetClass, assetClassOrder());
  })(assetClassData.assets);

  const liquidAssets = R.filter(asset => R.includes(asset.assetClass, liquid), orderedAssets);
  const nonLiquidAssets = R.filter(asset => R.includes(asset.assetClass, nonLiquid), orderedAssets);

  const testidValue = 'asset-class-performance-totals';
  return (
    <StyledAssetTotals data-testid={testidValue} className={testidValue}>
      <ClassByCategory assets={liquidAssets} title="Liquid" />
      <ClassByCategory assets={nonLiquidAssets} title="Non-Liquid" />
      <StyledPortfolioTotals>
        <div className="total-portfolio-assets total-label">Total Portfolio</div>
        <div className="body-medium-bold total-amount">{formatDollar(totalMarketValueData)}</div>
      </StyledPortfolioTotals>
    </StyledAssetTotals>
  );
};

AssetClassTotals.propTypes = {
  assetClassData: PropTypes.shape({
    assets: PropTypes.array
  }),
  displayHeaderAsColumn: PropTypes.bool,
  setDisplayHeaderAsColumn: PropTypes.func,
  totalMarketValueData: PropTypes.number
};

export default AssetClassTotals;
