import React, {useContext, useEffect} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {useQuery} from '@apollo/client';
import {Redirect} from 'react-router-dom';

import {formatDollar, formatPercent} from '../../utils';
import HeaderSummary from '../../components/assetClassDetails/HeaderSummary';
import CashAndFixedAccounts from './CashAndFixedAccounts';
import {GET_PORTFOLIO_PERFORMANCE_ASSET} from '../../graphql/queries';
import {AppContext} from '../../context/AppContext';
import {NA} from '../../utils/errorHelpers';
import ErrorHandler from '../../components/ErrorHandler';
import {setLoadingCountAssetDetailsPage} from '../../utils/utilHelpers';

const StyledSeparator = styled.div`
  margin: 20px 0;
`;

const StyledCashAndFixedContainer = styled.div`
  & .row-item__value {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .row-title__value {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  & .summary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    & .summary-title {
      grid: 1/3;
      min-width: 300px;
    }
    & .summary-item {
    }
  }
`;

const CashAndFixedPage = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedAsOfDate, selectedPortfolioId} = state;
  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    errorPolicy: 'all',
    variables: {portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountAssetDetailsPage({key: '/cashandfixedPage', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountAssetDetailsPage({key: '/cashandfixedPage', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  const page = 'CashAndFixedPage';

  if (R.isNil(data?.data)) return <ErrorHandler loggedMessage={`[${page}] No graphQL data`} />;
  if (R.isNil(data?.data?.periods))
    return <ErrorHandler loggedMessage={`[${page}] No periods data`} />;

  const periodsData = data.data?.periods || [];

  const findPeriod = R.findIndex(R.propEq('displayName', state?.activePeriod))(periodsData);
  if (findPeriod === -1)
    return (
      <ErrorHandler
        loggedMessage={`[${page}] No data for selected period: ${state.activePeriod}`}
      />
    );

  const assetClassData = periodsData[findPeriod];
  if (R.isNil(assetClassData.assets) || R.isEmpty(assetClassData.assets))
    return (
      <ErrorHandler loggedMessage={`[${page}] No assets data: period ${state.activePeriod}`} />
    );

  let displayCash = true;

  const cashData = assetClassData.assets.filter(asset => asset.assetClass === 'cash');
  if (R.isNil(cashData) || R.isEmpty(cashData)) {
    displayCash = false;
  }
  const cashAccountsData = R.isEmpty(cashData) ? {} : cashData[0];

  let displayFixed = true;

  const fixedData = assetClassData.assets.filter(asset => asset.assetClass === 'fixedIncome');
  if (R.isNil(fixedData) || R.isEmpty(fixedData)) {
    displayFixed = false;
  }
  const fixedAccountsData = R.isEmpty(fixedData) ? {} : fixedData[0];

  const cashSummaryData = {
    assetClass: cashAccountsData.assetClass || NA,
    title: 'Cash',
    marketValue: cashAccountsData.marketValue ?? NA,
    gains: cashAccountsData.gains ?? NA,
    gainsPercent: cashAccountsData.gainsPercent ?? NA,
    allocation: cashAccountsData.allocation ?? NA,
    donutGraphData: assetClassData.assets
  };

  const fixedSummaryData = {
    assetClass: fixedAccountsData.assetClass || NA,
    title: 'Fixed Income',
    marketValue: fixedAccountsData.marketValue ?? NA,
    gains: fixedAccountsData.gains ?? NA,
    gainsPercent: fixedAccountsData.gainsPercent ?? NA,
    allocation: fixedAccountsData.allocation ?? NA,
    donutGraphData: assetClassData.assets
  };

  const summaryData = {
    assetClass: cashAccountsData.assetClass || NA,
    title: 'Cash & Fixed',
    marketValue: cashAccountsData.marketValue + fixedAccountsData.marketValue ?? NA,
    gains: cashAccountsData.gains + fixedAccountsData.gains ?? NA,
    gainsPercent: cashAccountsData.gainsPercent + fixedAccountsData.gainsPercent ?? NA,
    allocation: cashAccountsData.allocation + fixedAccountsData.allocation ?? NA,
    donutGraphData: assetClassData.assets
  };

  const columns = [
    {title: 'Cash Accounts', dataIndex: 'marketValue', key: 'cash-accounts'},
    {
      title: 'Gain/Loss',
      dataIndex: 'gain',
      key: 'gain-loss``',
      align: 'center',
      render: number => formatDollar(number)
    },
    {
      title: 'NetFlows',
      align: 'center',
      dataIndex: 'netflows',
      key: 'netflows',
      render: percent => formatPercent(percent)
    },
    {
      title: 'Inflows',
      align: 'center',
      dataIndex: 'inflows',
      key: 'inflows',
      render: percent => formatPercent(percent)
    },
    {
      title: 'Outflows',
      align: 'center',
      dataIndex: 'outflows',
      key: 'outflows',
      render: percent => formatPercent(percent)
    },
    {
      title: 'Allocation',
      align: 'center',
      dataIndex: 'allocadtion',
      key: 'allocation',
      render: percent => formatPercent(percent)
    }
  ];

  const renderCash = () => {
    if (!displayCash) return null;
    return (
      <div>
        <CashAndFixedAccounts
          componentTitle="Cash"
          accountsData={cashAccountsData}
          activePeriod={state.activePeriod}
          columns={columns}
        />
      </div>
    );
  };

  const renderFixed = () => {
    if (!displayFixed) return null;
    return (
      <div>
        <CashAndFixedAccounts
          componentTitle="Fixed Income"
          accountsData={fixedAccountsData}
          activePeriod={state.activePeriod}
          columns={columns}
        />
      </div>
    );
  };

  const renderHeader = () => {
    if (!displayCash && !displayFixed) {
      // Must be an entity switch where the new entity has no alternatives data
      // Force our way back to the portfolio page
      return <Redirect to="/portfolioPage" />;
    }

    if (!displayCash) return <HeaderSummary summaryData={fixedSummaryData} />;
    if (!displayFixed) return <HeaderSummary summaryData={cashSummaryData} />;
    return <HeaderSummary summaryData={summaryData} />;
  };

  return (
    <StyledCashAndFixedContainer>
      <div className="portfolio-box-shadow">{renderHeader()}</div>
      <div className="side-padding-50">
        {renderCash()}
        <StyledSeparator />
        {renderFixed()}
      </div>
    </StyledCashAndFixedContainer>
  );
};

export default CashAndFixedPage;
