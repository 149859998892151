import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {BarChart, Bar, XAxis, YAxis, Legend, LabelList, ResponsiveContainer} from 'recharts';
import * as R from 'ramda';
import {formatPercent} from '../../utils';
import * as COLORS from '../../theme/colors';

const StyledLegend = styled.span`
  margin-right: 25px;
  margin-left: 7px;
  color: ${COLORS['@neutrals-9']};
`;

const BarGraph = ({returnsData, testingWidth}) => {
  const xAxisLabels = {
    '1YR AACR': '1 YR',
    '3YR AACR': '3 YR',
    '5YR AACR': '5 YR',
    '7YR AACR': '7 YR',
    '10YR AACR': '10 YR',
    '20YR AACR': '20 YR'
  };

  const xAxisOrder = [
    'ITD',
    'YRAACR20',
    'YRAACR10',
    'YRAACR7',
    'YRAACR5',
    'YRAACR3',
    'YRAACR1',
    'FYTD',
    'CYTD'
  ];

  const xAxisDisplayName = displayName => {
    if (xAxisLabels[displayName]) return xAxisLabels[displayName];
    return displayName;
  };

  const benchmarkNames = {};
  const benchmarkNamesMapped = {};

  returnsData.forEach(period => {
    period.benchmarks?.forEach(benchmark => {
      if (!benchmarkNames[benchmark.name]) {
        benchmarkNames[benchmark.name] = 1;
      } else {
        benchmarkNames[benchmark.name] += 1;
      }
    });
  });

  const sortedBenchmarks = [];
  if (Object.keys(benchmarkNames)?.length > 0) {
    Object.keys(benchmarkNames)?.forEach(benchmark => {
      sortedBenchmarks.push([benchmark, benchmarkNames[benchmark]]);
    });
    sortedBenchmarks.sort((a, b) => {
      return b[1] - a[1];
    });

    sortedBenchmarks.forEach((benchmark, index) => {
      const benchmarkName = benchmark[0];
      benchmarkNames[benchmarkName] = index;
      benchmarkNamesMapped[`benchmarkName${index}`] = benchmarkName;
    });
  }

  const graphData = R.map(period => {
    const benchmarks = {};
    period.benchmarks?.forEach(benchmark => {
      benchmarks[`benchmarkName${benchmarkNames[benchmark.name]}`] = benchmark.name;
      benchmarks[`benchmarkPercent${benchmarkNames[benchmark.name]}`] = benchmark.percent;
    });
    return {
      displayName: xAxisDisplayName(period.displayName),
      code: period.code,
      portfolioPercent: period.portfolioPercent,
      ...benchmarks
    };
  })(returnsData);

  const orderedGraphData = R.sortBy(obj => {
    return R.indexOf(obj.code, xAxisOrder);
  })(graphData);

  const renderPercentLabels = ({x, y, width, value}) => {
    const radius = value > 0 ? 10 : -10;
    if (typeof value !== 'number') return null;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          className="body-medium-bold"
        >
          {formatPercent(value)}
        </text>
      </g>
    );
  };

  const renderLegendText = value => {
    return <StyledLegend className="body-medium-bold">{value}</StyledLegend>;
  };

  return (
    <ResponsiveContainer width={testingWidth} height={350}>
      <BarChart
        width={1240}
        height={350}
        data={orderedGraphData}
        margin={{
          top: 30,
          right: 50,
          left: 50,
          bottom: 5
        }}
        barCategoryGap="20%"
        barGap={10}
        maxBarSize={200}
      >
        <XAxis
          dataKey="displayName"
          axisLine={false}
          tickLine={false}
          tick={{
            fill: '#262626',
            fontSize: 13,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: '1px'
          }}
          tickMargin={15}
        />
        <YAxis hide domain={[0, 'auto']} />
        <Legend
          layout={
            Object.keys(benchmarkNamesMapped)?.length > 1 ||
            (benchmarkNamesMapped.benchmarkName0 &&
              benchmarkNamesMapped.benchmarkName0?.length > 80)
              ? 'vertical'
              : 'horizontal'
          } // make it vertical if the number of benchmark character excedes 80 characters
          iconType="square"
          iconSize={12}
          formatter={renderLegendText}
          wrapperStyle={{bottom: '-25px'}}
        />
        <Bar
          name="Portfolio Performance"
          dataKey="portfolioPercent"
          fill={COLORS['@secondary-blue']}
          isAnimationActive={false}
        >
          <LabelList dataKey="portfolioPercent" content={renderPercentLabels} position="top" />
        </Bar>
        {Object.keys(benchmarkNamesMapped)?.length > 0 ? (
          <Bar
            name={benchmarkNamesMapped.benchmarkName0}
            dataKey="benchmarkPercent0"
            fill={COLORS['@benchmark1-dark-grey']}
            isAnimationActive={false}
          >
            <LabelList dataKey="benchmarkPercent0" content={renderPercentLabels} position="top" />
          </Bar>
        ) : null}
        {Object.keys(benchmarkNamesMapped)?.length > 1 ? (
          <Bar
            name={benchmarkNamesMapped.benchmarkName1}
            dataKey="benchmarkPercent1"
            fill={COLORS['@benchmark2-light-grey']}
            isAnimationActive={false}
          >
            <LabelList dataKey="benchmarkPercent1" content={renderPercentLabels} position="top" />
          </Bar>
        ) : null}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarGraph.propTypes = {
  returnsData: PropTypes.array,
  testingWidth: PropTypes.number
};

export default BarGraph;
