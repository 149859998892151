import React, {useContext} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import LineGraph from '../../../components/LineGraph';
import {AppContext} from '../../../context/AppContext';
import {setRenderError} from '../../../utils/errorHelpers';

const StyledHeroGraph = styled.div`
  display: flex;
`;
const StyledGraphContainer = styled.div`
  width: 100%;
`;

const HeroGraph = ({report, testingInception}) => {
  const {dispatch} = useContext(AppContext);
  const page = 'portfolioPage';

  if (R.isNil(report && report.data))
    setRenderError(dispatch, `[${page}:HeroGraph] No hero graph report data`);

  return (
    <StyledHeroGraph>
      <StyledGraphContainer data-testid="portfolio-herograph">
        <LineGraph reportData={report.data} type="hero-graph" testingInception={testingInception} />
      </StyledGraphContainer>
    </StyledHeroGraph>
  );
};

HeroGraph.propTypes = {
  report: PropTypes.shape({
    data: PropTypes.array
  }),
  testingInception: PropTypes.number
};

export default HeroGraph;
