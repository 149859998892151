/* eslint-disable no-unused-vars */
import React, {useContext, useState, useEffect} from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import {
  GET_PORTFOLIO_PERFORMANCE_ASSET,
  GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO
} from '../../graphql/queries';
import HeroGraph from './HeroGraph';
import AssetClassContainer from './AssetClassContainer';
import {PageContainer} from '../../styledComponents';
import PageHeader from './PageHeader';
import CurrentAssetAllocation from './CurrentAssetAllocation';
import {AppContext} from '../../context/AppContext';
import AssetClassTotals from './AssetClassTotals';
import {checkForPeriodData, NA} from '../../utils/errorHelpers';
import ToggleMenu from './ToggleMenu';
import Attribution from './Attribution';
import HistoricalAssetAllocation from './HistoricalAssetAllocation';
import ErrorHandler from '../../components/ErrorHandler';
import DisclosureText from '../../components/DisclosureText';
import logger from '../../logger';
import {tapLog} from '../../utils'; /* eslint-disable-line */
import {setLoadingCountPortfolioPage} from '../../utils/utilHelpers';
import {formatPeriodDataForAssetClassGraphs} from '../../utils/formatPeriodDataForAssetClassGraphs';

const Portfolio = () => {
  const {state, dispatch} = useContext(AppContext);
  const {selectedPortfolioId, selectedAsOfDate, selectedClientId} = state;
  const {renderError} = state;
  const [displayHeaderAsColumn, setDisplayHeaderAsColumn] = useState(false);
  const [menuItem, setMenuItem] = useState('asset-allocation');

  const StyledHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: ${displayHeaderAsColumn ? 'column' : 'row'};
    margin-bottom: 45px;
  `;

  const StyledHeaderContainerLeft = styled.div`
    width: 100%;
    min-width: 500px;
  `;

  const StyledHeaderContainerRight = styled.div`
    max-width: ${displayHeaderAsColumn ? '100%' : '350px'};
    padding-top: ${displayHeaderAsColumn ? '30px' : '60px'};
  `;

  /**
   * NOTE: There was a conflict in the way the data is returned from the graphql query.
   * Query GET_CLIENT_NAMES has been replaced by GET_MY_CLIENTS_NAMES
   * Temporarily force a network query to avoid cache issues.
   */
  const {loading, error, data} = useQuery(GET_PORTFOLIO_PERFORMANCE_ASSET, {
    returnPartialData: true,
    pollingInterval: 0,
    errorPolicy: 'all', // Ensure we get all data, even if there's an error
    queryDeduplication: false,
    variables: {portfolioId: selectedPortfolioId, asOfDate: selectedAsOfDate}
  });

  // 2nd portfolio page query to grab the historical asset allocation data
  const historyAssetAllocationQuery = useQuery(GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO, {
    variables: {
      id: selectedClientId,
      portfolioId: selectedPortfolioId,
      asOfDate: selectedAsOfDate
    }
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountPortfolioPage(
        {key: 'PortfolioPage/index/GET_PORTFOLIO_PERFORMANCE_ASSET', value: 1},
        dispatch
      );
    } else if (!loading) {
      setLoadingCountPortfolioPage(
        {key: 'PortfolioPage/index/GET_PORTFOLIO_PERFORMANCE_ASSET', value: 0},
        dispatch
      );
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (historyAssetAllocationQuery.loading) {
      setLoadingCountPortfolioPage(
        {key: 'PortfolioPage/index/GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO', value: 1},
        dispatch
      );
    } else if (!historyAssetAllocationQuery.loading) {
      setLoadingCountPortfolioPage(
        {key: 'PortfolioPage/index/GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO', value: 0},
        dispatch
      );
    }
  }, [historyAssetAllocationQuery.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || historyAssetAllocationQuery.loading) {
    return null;
  }

  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('GET_PORTFOLIO_PERFORMANCE_ASSET QUERY ERROR:', error.message);
    if (error.message.includes('Cannot access portfolio')) {
      return null;
    }
  }

  if (historyAssetAllocationQuery.error) {
    /* eslint-disable-next-line no-console */
    console.log(
      'GET_HISTORICAL_ASSET_ALLOCATION_PORTFOLIO QUERY ERROR:',
      historyAssetAllocationQuery.error.message
    );
  }

  const page = 'portfolioPage';

  if (R.isNil(data?.data)) {
    logger.trace({
      method: 'portfolioPage',
      message: 'data?.data is null',
      selectedPortfolioId,
      selectedAsOfDate
    });
    return (
      <ErrorHandler loggedMessage={`[${page}] No graphQL data: GET_PORTFOLIO_PERFORMANCE_ASSET`} />
    );
  }
  if (R.isNil(data?.data?.periods)) {
    logger.trace({
      method: 'portfolioPage',
      message: 'data?.data?.periods is null',
      payload: data.data,
      selectedPortfolioId,
      selectedAsOfDate
    });
    return <ErrorHandler loggedMessage={`[${page}] No periods data`} />;
  }
  const periodsData = data.data?.periods;
  const assetClassByPeriodData = formatPeriodDataForAssetClassGraphs(periodsData);

  const historicalAssetAllocationData =
    historyAssetAllocationQuery.data?.data?.historicAllocation?.data;

  const findPeriod = checkForPeriodData(state.activePeriod, periodsData);
  if (findPeriod === -1) {
    logger.trace({
      method: 'portfolioPage',
      message: 'findPeriod is -1',
      payload: data.data.periods,
      selectedPortfolioId,
      selectedAsOfDate
    });
    return (
      <ErrorHandler
        loggedMessage={`[${page}] No data for selected period: ${state.activePeriod}`}
      />
    );
  }
  const assetClassData = periodsData[findPeriod];
  if (R.isNil(assetClassData?.assets) || R.isEmpty(assetClassData?.assets)) {
    logger.trace({
      method: 'portfolioPage',
      message: 'assetClassData?.assets is null or empty',
      selectedPortfolioId,
      selectedAsOfDate,
      payload: periodsData
    });
    return (
      <ErrorHandler loggedMessage={`[${page}] No assets data: period ${state.activePeriod}`} />
    );
  }
  const assetsInfo = assetClassData.assets;
  const assetClassGraphData = assetsInfo.map(assets =>
    R.pick(['assetClass', 'allocation'], assets)
  );

  if (R.isNil(assetClassData?.summary) || R.isEmpty(assetClassData?.summary)) {
    return (
      <ErrorHandler loggedMessage={`[${page}] No summary data: period ${state.activePeriod}`} />
    );
  }

  const isFYTDClient = checkForPeriodData('FYTD', periodsData);
  const performanceData = assetClassData.summary || {};
  const performanceSummaryGains = performanceData?.gains ?? NA;
  const performanceSummaryGainsPercent = performanceData?.gainsPercent ?? NA;
  const performanceSummaryTotalMonthlyGainsPercent = performanceData?.monthlyGainsPercent ?? NA;
  const performanceTotalMarketValue = performanceData?.totalMarketValue ?? NA;
  const performanceNetflows = performanceData?.netflows ?? NA;
  const performanceReportData = R.propOr({}, 'report')(performanceData);

  const summaryAACRS = R.compose(
    R.pick(['CYTD', 'FYTD', 'YRAACR1', 'YRAACR3', 'YRAACR5']),
    R.mergeAll,
    R.map(obj => {
      return {[obj.period]: obj.gainsPercent};
    }),
    R.map(period => {
      return {
        period: period.code,
        gainsPercent: period.summary.gainsPercent
      };
    })
  )(periodsData);
  logger.trace({
    method: 'summaryAACRS',
    summaryAACRS
  });
  const periodGainsPercentTotalPortfolio = R.ifElse(
    R.isNil,
    R.always(null),
    R.always(summaryAACRS)
  )(periodsData);
  logger.trace({
    method: 'periodGainsPercentTotalPortfolio',
    periodGainsPercentTotalPortfolio,
    merged: R.mergeAll(periodGainsPercentTotalPortfolio)
  });
  const summaryTotalPortfolioData = {
    key: 'totalPortfolio',
    assetClass: 'totalPortfolio',
    class: 'TOTAL PORTFOLIO',
    gains: performanceSummaryGains,
    gainsPercent: performanceSummaryGainsPercent,
    monthlyGainsPercent: performanceSummaryTotalMonthlyGainsPercent,
    marketValue: performanceTotalMarketValue,
    allocation: 100,
    report: performanceReportData,
    ...summaryAACRS
  };
  logger.trace({
    method: 'summaryTotalPortfolioData',
    summaryTotalPortfolioData
  });

  const periodGainsPercentByAssetClass = R.ifElse(
    R.isNil,
    R.always(null),
    R.always(
      R.compose(
        R.map(asset => {
          const gainsPercentByPeriod = R.compose(
            R.map(period => {
              const gainsPercent = R.compose(R.indexBy(R.prop('assetClass')))(period.assets);
              return gainsPercent[asset?.assetClass]?.gainsPercent ?? null;
            }),
            R.indexBy(R.prop('code'))
          )(periodsData);
          return gainsPercentByPeriod;
        }),
        R.indexBy(R.prop('assetClass'))
      )
    )(assetClassData.assets)
  )(assetClassData.assets);

  const marketValueData = R.compose(
    R.mergeAll,
    R.map(asset => {
      return {[asset.assetClass]: asset.marketValue};
    })
  )(assetClassData.assets);

  const renderPortfolioPageBody = () => {
    if (menuItem === 'asset-allocation') {
      return (
        <div
          data-testid="portfolio--current-asset-allocation"
          className="portfolio--current-asset-allocation"
        >
          <CurrentAssetAllocation marketValueData={marketValueData} />
          <AssetClassContainer
            assetClassData={assetClassData}
            assetClassAllocationData={periodGainsPercentByAssetClass}
            summaryTotalPortfolioData={summaryTotalPortfolioData}
            assetClassGraphData={assetClassGraphData}
            firstDateofPeriod={performanceReportData?.data[0]?.date}
            lastDateofPeriod={R.last(performanceReportData?.data)?.date}
            isFYTDClient={isFYTDClient}
            assetClassByPeriodData={assetClassByPeriodData}
          />
        </div>
      );
    }
    if (menuItem === 'attribution') {
      return (
        <div data-testid="portfolio--attribution" className="portfolio--attribution">
          <Attribution periodsData={periodsData} />
        </div>
      );
    }
    if (menuItem === 'historical-asset-allocation') {
      return (
        <div
          data-testid="portfolio--historical-asset-allocation"
          className="portfolio--historical-asset-allocation"
        >
          <HistoricalAssetAllocation
            historicalAssetAllocationData={historicalAssetAllocationData}
          />
        </div>
      );
    }
    return null;
  };

  if (renderError !== false) return <ErrorHandler loggedMessage={renderError} />;

  return (
    <PageContainer className="portfolioPage">
      <div className="portfolio-box-shadow">
        <StyledHeaderContainer className="side-padding-50">
          <StyledHeaderContainerLeft>
            <PageHeader
              performanceSummaryGains={performanceSummaryGains}
              performanceSummaryGainsPercent={performanceSummaryGainsPercent}
              performanceNetflows={performanceNetflows}
            />
            <HeroGraph report={performanceReportData} />
          </StyledHeaderContainerLeft>
          <StyledHeaderContainerRight>
            <AssetClassTotals
              assetClassData={assetClassData}
              displayHeaderAsColumn={displayHeaderAsColumn}
              setDisplayHeaderAsColumn={setDisplayHeaderAsColumn}
              totalMarketValueData={performanceTotalMarketValue}
            />
          </StyledHeaderContainerRight>
        </StyledHeaderContainer>
      </div>
      <div className="side-padding-50">
        <ToggleMenu setMenuItem={setMenuItem} menuItem={menuItem} />
        {renderPortfolioPageBody()}
        <DisclosureText page={page} />
      </div>
    </PageContainer>
  );
};

Portfolio.propTypes = {
  portfolio: PropTypes.shape({
    title: PropTypes.string
  })
};

export default Portfolio;
