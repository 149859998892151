import React from 'react';
import {AppstoreOutlined, BarsOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import COLORS from '../../../../theme/colors';

const StyledDisplayWidgets = styled.div`
  background-color: ${COLORS['@component-background']};
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto auto;
  & .display-widgets__grid__appstore {
    border-radius: 4px 0 0 4px;
    border: 1px solid
      ${props => (props.cardsOrList === 'cards' ? COLORS['@primary-color'] : COLORS['@neutrals-4'])};
    & svg {
      height: 23.71px;
      width: 23.71px;
      margin-right: 10px;
      color: ${props =>
        props.cardsOrList === 'cards' ? COLORS['@primary-color'] : COLORS['@neutrals-6']};
    }
    & span {
      color: ${props =>
        props.cardsOrList === 'cards' ? COLORS['@body-text'] : COLORS['@neutrals-6']};
    }
  }
  & .display-widgets__grid__bars {
    border-radius: 0 4px 4px 0;
    border: 1px solid
      ${props => (props.cardsOrList === 'list' ? COLORS['@primary-color'] : COLORS['@neutrals-4'])};
    & svg {
      height: 27.21px;
      width: 29.86px;
      margin-right: 10px;
      color: ${props =>
        props.cardsOrList === 'list' ? COLORS['@primary-color'] : COLORS['@neutrals-6']};
    }
    & span {
      color: ${props =>
        props.cardsOrList === 'list' ? COLORS['@body-text'] : COLORS['@neutrals-6']};
    }
  }
  & .display-widgets__grid__charts {
    border-radius: 0 4px 4px 0;
    border: 1px solid
      ${props =>
        props.cardsOrList === 'charts' ? COLORS['@primary-color'] : COLORS['@neutrals-4']};
    & svg {
      height: 27.21px;
      width: 29.86px;
      color: ${props =>
        props.cardsOrList === 'charts' ? COLORS['@primary-color'] : COLORS['@neutrals-6']};
    }
    & span {
      color: ${props =>
        props.cardsOrList === 'charts' ? COLORS['@body-text'] : COLORS['@neutrals-6']};
    }
  }
`;

const StyledClickContainer = styled.div`
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 40px;
  & span {
    text-transform: uppercase;
  }
`;

const DisplayWidgets = ({cardsOrList, setCardsOrList}) => {
  const selectDisplayWidget = (event, element) => {
    setCardsOrList(element);
  };

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div className="display-widgets">
      <StyledDisplayWidgets cardsOrList={cardsOrList} className="display-widgets">
        <StyledClickContainer
          tabIndex="0"
          role="button"
          onClick={event => selectDisplayWidget(event, 'cards')}
          className="display-widgets__grid display-widgets__grid__appstore"
        >
          <AppstoreOutlined />
          <span
            className={`display-widgets__grid__appstore--text label-small ${
              cardsOrList === 'cards' ? 'active' : ''
            }`}
          >
            Grid
          </span>
        </StyledClickContainer>
        <StyledClickContainer
          tabIndex="0"
          role="button"
          onClick={event => selectDisplayWidget(event, 'list')}
          className="display-widgets__list display-widgets__grid__bars"
        >
          <BarsOutlined />
          <span
            className={`display-widgets__grid__bars--text label-small ${
              cardsOrList === 'list' ? 'active' : ''
            }`}
          >
            List
          </span>
        </StyledClickContainer>
        {/* <StyledClickContainer
          tabIndex="0"
          role="button"
          onClick={event => selectDisplayWidget(event, 'charts')}
          className="display-widgets__list display-widgets__grid__charts"
        >
          <BarChartOutlined />
          <span
            className={`display-widgets__grid__bars--text label-small ${
              cardsOrList === 'charts' ? 'active' : ''
            }`}
          >
            Charts
          </span>
        </StyledClickContainer> */}
      </StyledDisplayWidgets>
    </div>
  );
};

DisplayWidgets.propTypes = {
  setCardsOrList: PropTypes.func.isRequired,
  cardsOrList: PropTypes.string
};

export default DisplayWidgets;
