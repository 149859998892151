import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Tooltip
} from 'recharts';

import * as COLORS from '../../theme/colors';
import {assetClassProperties} from '../../utils/assetClassProperties';
import {
  formatPercentWithoutDecimal,
  formatPercent,
  formatDollarsInMillionsWithDecimal
} from '../../utils';
import HistoricalAllocationTooltip from './HistoricalAllocationTooltip';
import CustomizedAxisTick from '../DistCCGraph/CustomRechartsComponents/CustomizedAxisTick';

const HistoricalAllocationBarGraph = ({graphData, displayLabels, testingWidth}) => {
  const renderTotalsLabels = props => {
    const {x, y, width, value, height, offset} = props;
    const sign = height >= 0 ? 2 : -2;
    const labelY = y > 40 ? 30 : y; // for cases where realAssets is a negative percent
    return (
      <g>
        <text
          x={x + width / 2}
          y={labelY - sign * offset - 5}
          fill={COLORS['@neutrals-7']}
          textAnchor="middle"
          dominantBaseline="middle"
          className="body-small-bold"
          style={{
            fontSize: 12,
            fontStyle: 'normal',
            letterSpacing: 1
          }}
        >
          {formatDollarsInMillionsWithDecimal(value)}
        </text>
      </g>
    );
  };

  const renderPercentLabels = props => {
    const {x, y, width, height, value} = props;

    if (!value || height < 10) return null;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="white"
          textAnchor="middle"
          dominantBaseline="middle"
          className="body-small-bold"
          style={{
            fontSize: 11,
            fontStyle: 'normal',
            letterSpacing: 1
          }}
        >
          {formatPercent(value)}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width={testingWidth} height={450}>
      <BarChart
        height={500}
        barCategoryGap="15%"
        data={graphData}
        margin={{
          top: 30
        }}
        maxBarSize={200}
        stackOffset="sign"
      >
        <CartesianGrid vertical={false} stroke={COLORS['neutrals-4']} fill={COLORS['neutrals-4']} />
        <YAxis
          dataKey="yAxis"
          tickFormatter={tick => {
            if (formatPercentWithoutDecimal(tick) === '-0%') {
              return '$0%';
            }
            return formatPercentWithoutDecimal(tick);
          }}
          domain={['dataMin + 100', 'auto']}
          axisLine={false}
          tickLine={false}
          tickCount={6}
          tick={{
            stroke: COLORS['@neutrals-7'],
            fontSize: 13,
            fontWeight: 400,
            letterSpacing: 1
          }}
          tickMargin={15}
        />
        <XAxis
          dataKey="displayName"
          tick={<CustomizedAxisTick />}
          interval={0}
          axisLine={false}
          tickLine={false}
          height={50}
          tickMargin={15}
          padding={{left: 10, right: 10}}
        />
        <Bar
          dataKey="cash"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('cash')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="cash" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="fixedIncome"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('fixedIncome')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="fixedIncome" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="balancedAccounts"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('balancedAccounts')?.colorCode}
        >
          {displayLabels ? (
            <LabelList dataKey="balancedAccounts" content={renderPercentLabels} />
          ) : null}
        </Bar>
        <Bar
          dataKey="equities"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('equities')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="equities" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="alternative"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('alternative')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="alternative" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="otherInvestments"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('otherInvestments')?.colorCode}
        >
          {displayLabels ? (
            <LabelList dataKey="otherInvestments" content={renderPercentLabels} />
          ) : null}
        </Bar>
        <Bar
          dataKey="hybrid"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('hybrid')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="hybrid" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="multiAsset"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('multiAsset')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="multiAsset" content={renderPercentLabels} /> : null}
        </Bar>
        <Bar
          dataKey="privateEquities"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('privateEquities')?.colorCode}
        >
          {displayLabels ? (
            <LabelList dataKey="privateEquities" content={renderPercentLabels} />
          ) : null}
        </Bar>
        <Bar
          dataKey="realAssets"
          stackId="a"
          isAnimationActive={false}
          fill={assetClassProperties('realAssets')?.colorCode}
        >
          {displayLabels ? <LabelList dataKey="realAssets" content={renderPercentLabels} /> : null}
          {displayLabels ? (
            <LabelList dataKey="allocationTotals" content={renderTotalsLabels} position="top" />
          ) : null}
        </Bar>
        <Tooltip
          cursor={false}
          content={payload => {
            return <HistoricalAllocationTooltip payload={payload} />;
          }}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

HistoricalAllocationBarGraph.propTypes = {
  graphData: PropTypes.array,
  displayLabels: PropTypes.bool,
  testingWidth: PropTypes.number
};

export default HistoricalAllocationBarGraph;
