import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Header from '../components/Header';
import Periods from '../components/Periods';
import {StyledHeader, StyledBody, StyledDivider} from '../styledComponents';
import EntityRibbon from '../components/EntityRibbon';
import {AppContext} from '../context/AppContext';
import {graphqlLoadingHandler} from '../utils';

const StyledPeriods = styled(Periods)`
  position: 'absolute';
  right: '20px';
`;

const DefaultLayout = props => {
  const {children} = props;
  const {state} = useContext(AppContext); /* eslint-disable-line */
  const portfolioPageLoaders = state.loadingCountPortfolioPage;
  const headerLoaders = state.loadingCountHeader;
  const assetPageLoaders = state.loadingCountAssetDetailsPage;
  const location = useLocation();
  const path = location?.pathname;
  let isLoading = true;

  switch (path) {
    case '/portfolioPage':
      isLoading =
        Object.values(portfolioPageLoaders).reduce((a, b) => a + b, 0) > 0 ||
        Object.values(headerLoaders).reduce((a, b) => a + b, 0) > 0;
      break;
    default:
      isLoading =
        assetPageLoaders[path] === 1 || Object.values(headerLoaders).reduce((a, b) => a + b, 0) > 0;
  }

  return (
    <>
      <StyledHeader>
        <div style={{position: 'relative'}}>
          <Header />
          <StyledDivider />
          <StyledPeriods />
          <EntityRibbon />
          {isLoading ? (
            <div className="global-loader-outer-div" data-testid="global-loader-default-layout">
              <div className="global-loader-inner-div">
                <section>{graphqlLoadingHandler('loading')}</section>
              </div>
            </div>
          ) : null}
        </div>
      </StyledHeader>
      <StyledBody>{children}</StyledBody>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.shape({})
};

export default DefaultLayout;
