import React, {useContext} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import styled from '@emotion/styled';

import {AppContext} from '../../context/AppContext';
import * as COLORS from '../../theme/colors';
import {assetClassPathNames} from '../../utils/assetClassProperties';
import {setRenderError} from '../../utils/errorHelpers';

const classPathnames = assetClassPathNames();

const StyledNav = styled.nav`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  float: right;
  padding-top: 5px;
`;

const StyledNavLink = styled(NavLink)`
  margin-left: 41px;
  flex-grow: 1;
  color: ${COLORS['@header-text']};
  &.active {
    padding-bottom: 5px;
    border-bottom: 3px solid ${COLORS['@primary-color']};
  }
  &:hover {
    color: ${COLORS['@header-text']};
  }
`;

const TopMenu = () => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const {dispatch} = useContext(AppContext);
  return (
    <>
      <StyledNav className="top-menu">
        <StyledNavLink
          activeClassName="active"
          className={`top-menu__home top-menu__cover-page ${
            currentPathname === '/coverPage' ? 'body-medium-bold' : 'body-medium'
          }`}
          onClick={() => setRenderError(dispatch, false)}
          to={{pathname: '/coverPage'}}
        >
          Home
        </StyledNavLink>
        <StyledNavLink
          activeClassName="active"
          className={`top-menu__portfolio-page ${
            currentPathname === '/portfolioPage' || classPathnames.includes(currentPathname)
              ? 'body-medium-bold active'
              : 'body-medium'
          }`}
          onClick={() => setRenderError(dispatch, false)}
          to="/portfolioPage"
        >
          Portfolio&nbsp;Performance
        </StyledNavLink>
        <StyledNavLink
          activeClassName="active"
          className={`top-menu__disclosures ${
            currentPathname === '/disclosurePage' ? 'body-medium-bold' : 'body-medium'
          }`}
          onClick={() => setRenderError(dispatch, false)}
          to={{pathname: '/disclosurePage'}}
        >
          General&nbsp;Disclosures
        </StyledNavLink>
      </StyledNav>
    </>
  );
};

export default TopMenu;
